/* eslint-disable */
/* This file is generated, do not modify manually */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  GraphQLJSON: {
    input: { [key: string]: string | number | boolean | object };
    output: { [key: string]: string | number | boolean | object };
  };
  IANATimezone: { input: string; output: string };
  Timezone: { input: string; output: string };
  Upload: { input: File; output: File };
};

export type AccidentCost = {
  __typename: 'AccidentCost';
  accidentCostValue: Scalars['Float']['output'];
  transportMode: Scalars['String']['output'];
};

export enum Action {
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
}

export type ActionMetadata = FlagCreatedMetadata | FlagUpdatedMetadata;

export type ActualTimeOverrides = {
  __typename: 'ActualTimeOverrides';
  actualTimeOfArrival: Maybe<DateTimeWithTimezone>;
  actualTimeOfArrivalSource: Maybe<Scalars['String']['output']>;
};

export type AddCredentialsToUserInput = {
  type: CredentialType;
  userId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AddOrUpdateConsentInput = {
  fromCompanyId: Scalars['String']['input'];
  toCompanyId: Scalars['String']['input'];
  tourStatusConsent?: InputMaybe<AddOrUpdateConsentState>;
};

export enum AddOrUpdateConsentState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type AddUserToCompanyInput = {
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoleInCompany>;
};

export type AddVehiclesToCompanyVehicleGroupInput = {
  companyId: Scalars['String']['input'];
  companyVehicleGroupId: Scalars['String']['input'];
  vehicleIds: Array<Scalars['String']['input']>;
};

export type Address = {
  __typename: 'Address';
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  full_address: Maybe<Scalars['String']['output']>;
  shortAreaCode: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  stopAddressId: Maybe<Scalars['String']['output']>;
  street_address: Maybe<Scalars['String']['output']>;
  zipcode: Maybe<Scalars['String']['output']>;
};

export type AllowedCompanyInviteSignup = {
  __typename: 'AllowedCompanyInviteSignup';
  platformId: Maybe<Scalars['String']['output']>;
  type: SignupType;
};

export type ApiEndpointUsageLog = {
  __typename: 'ApiEndpointUsageLog';
  apiImplementedAt: Maybe<Scalars['DateTime']['output']>;
  companyId: Scalars['String']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  counters: Array<EndpointUsageCounterByDate>;
  endpointId: Scalars['String']['output'];
  firstCallAt: Scalars['DateTime']['output'];
  integrationIdentifier: Scalars['String']['output'];
  lastCallAt: Scalars['DateTime']['output'];
};

export type ApplicationDeepLink = {
  __typename: 'ApplicationDeepLink';
  applicationName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AssignUserToTourInput = {
  assignedToUserId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export enum BackofficeRole {
  admin = 'admin',
  readonly = 'readonly',
}

export type BigQueryMessageEntry = {
  __typename: 'BigQueryMessageEntry';
  message: Scalars['String']['output'];
  message_type: Scalars['String']['output'];
  produced_at: Scalars['DateTime']['output'];
};

export type Bounds = {
  __typename: 'Bounds';
  ne: PointLocation;
  sw: PointLocation;
};

export type BreakCenterPosition = {
  __typename: 'BreakCenterPosition';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type BulkInvitationIdentifierValue = {
  __typename: 'BulkInvitationIdentifierValue';
  isDuplicateInExistingValue: Scalars['Boolean']['output'];
  isDuplicateInSameSet: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type BulkInvitationMetadata = {
  __typename: 'BulkInvitationMetadata';
  fileName: Maybe<Scalars['String']['output']>;
  fileSize: Maybe<Scalars['String']['output']>;
};

export type BulkInvitationRecipient = {
  __typename: 'BulkInvitationRecipient';
  email: Scalars['String']['output'];
  locale: Maybe<Scalars['String']['output']>;
};

export enum BulkInvitationSendSelectType {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
}

export type BulkInvitationSet = {
  __typename: 'BulkInvitationSet';
  bulkInvitationSetId: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdByUserId: Scalars['String']['output'];
  items: CountedBulkInvitationSetItemConnection;
  metadata: BulkInvitationMetadata;
  statistics: BulkInvitationSetStatistics;
  updatedAt: Scalars['DateTime']['output'];
  updatedByUserId: Scalars['String']['output'];
};

export type BulkInvitationSetItemsArgs = {
  filter?: InputMaybe<BulkInvitationSetItemsFilterInput>;
  sort?: InputMaybe<BulkInvitationSetItemsSortOrderInput>;
};

export type BulkInvitationSetStatisticsArgs = {
  filter?: InputMaybe<BulkInvitationSetStatisticsFilterInput>;
};

export type BulkInvitationSetConnection = {
  __typename: 'BulkInvitationSetConnection';
  edges: Maybe<Array<Maybe<BulkInvitationSetEdge>>>;
  pageInfo: PageInfo;
};

export type BulkInvitationSetEdge = {
  __typename: 'BulkInvitationSetEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<BulkInvitationSet>;
};

export type BulkInvitationSetItem = {
  __typename: 'BulkInvitationSetItem';
  bulkInvitationSetId: Scalars['String']['output'];
  bulkInvitationSetItemId: Scalars['String']['output'];
  companyInvitationId: Maybe<Scalars['String']['output']>;
  /** @deprecated Use companyReferenceName instead */
  companyName: Maybe<Scalars['String']['output']>;
  companyReferenceName: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByUserId: Scalars['String']['output'];
  identifierValues: Array<BulkInvitationIdentifierValue>;
  invitedCompanyId: Maybe<Scalars['String']['output']>;
  isDataValid: Scalars['Boolean']['output'];
  recipients: Array<BulkInvitationRecipient>;
  relationCompanyName: Maybe<Scalars['String']['output']>;
  status: BulkInvitationSetItemStatus;
  updatedAt: Scalars['DateTime']['output'];
  updatedByUserId: Scalars['String']['output'];
};

export type BulkInvitationSetItemConnection = {
  __typename: 'BulkInvitationSetItemConnection';
  edges: Maybe<Array<Maybe<BulkInvitationSetItemEdge>>>;
  pageInfo: PageInfo;
};

export type BulkInvitationSetItemEdge = {
  __typename: 'BulkInvitationSetItemEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<BulkInvitationSetItem>;
};

export enum BulkInvitationSetItemStatus {
  INVITED = 'INVITED',
  INVITE_WITHDRAWN = 'INVITE_WITHDRAWN',
  IN_NETWORK = 'IN_NETWORK',
  NOT_INVITED = 'NOT_INVITED',
}

export type BulkInvitationSetItemsFilterInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  isInvalid?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<BulkInvitationSetItemStatus>;
};

export type BulkInvitationSetItemsSortOrderInput = {
  direction: SortDirection;
  key: BulkInvitationSetsSortKey;
};

export type BulkInvitationSetStatistics = {
  __typename: 'BulkInvitationSetStatistics';
  itemCountByStatus: Array<ItemCountByStatus>;
};

export type BulkInvitationSetStatisticsFilterInput = {
  isDataValid?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum BulkInvitationSetsSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  STATUS = 'STATUS',
}

export type CalculationError = {
  __typename: 'CalculationError';
  code: EmissionsCalculationErrorCode;
  type: EmissionsCalculationErrorType;
};

export type Cargo = {
  __typename: 'Cargo';
  containerEmptyWeight: Scalars['Float']['output'];
  tonsPerFeu: Scalars['Float']['output'];
  tonsPerTeu: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
  volumeWeight: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
};

export type CarriageModeParameter = {
  __typename: 'CarriageModeParameter';
  aircraftType: Maybe<Scalars['String']['output']>;
  driveClass: Maybe<Scalars['String']['output']>;
  emissionClass: Maybe<Scalars['String']['output']>;
  emptyRunFactor: Maybe<Scalars['Int']['output']>;
  ferryRouting: Maybe<Scalars['String']['output']>;
  fuelType: Maybe<Scalars['String']['output']>;
  inlandShipType: Maybe<Scalars['String']['output']>;
  loadFactor: Maybe<Scalars['Float']['output']>;
  lorryClass: Maybe<Scalars['String']['output']>;
  mode: Scalars['String']['output'];
  seaShipType: Maybe<Scalars['String']['output']>;
};

export type CarriageParameters = {
  __typename: 'CarriageParameters';
  bioFuelShare: Scalars['Float']['output'];
  carriageModeParameters: Array<CarriageModeParameter>;
};

export enum CarrierConnectionRelationshipType {
  CARRIER = 'CARRIER',
  SUBCARRIER = 'SUBCARRIER',
}

export type CarrierReport = {
  __typename: 'CarrierReport';
  allocatedToursCount: Scalars['Int']['output'];
  carrier: Maybe<CompanyProfile>;
  toursCount: Scalars['Int']['output'];
  visibilityIndex: Maybe<Scalars['Float']['output']>;
};

export type CarrierReportConnection = {
  __typename: 'CarrierReportConnection';
  edges: Maybe<Array<Maybe<CarrierReportEdge>>>;
  pageInfo: PageInfo;
};

export type CarrierReportEdge = {
  __typename: 'CarrierReportEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CarrierReport>;
};

export type ChangeUserMetadataInCompanyInput = {
  companyId: Scalars['String']['input'];
  metadata?: InputMaybe<UserMetadataInCompanyInput>;
  userId: Scalars['String']['input'];
};

export type ChangeUserRoleInCompanyInput = {
  companyId: Scalars['String']['input'];
  role: UserRoleInCompany;
  userId: Scalars['String']['input'];
};

export type CircleGeofence = {
  __typename: 'CircleGeofence';
  origin: PointLocation;
  radius_meters: Scalars['Int']['output'];
};

export type CircleGeofenceInput = {
  origin: PointLocationInput;
  radius_meters: Scalars['Int']['input'];
};

export type CombinedEvent = StopEvent | TourEvent;

export type Company = {
  __typename: 'Company';
  address: Maybe<CompanyAddress>;
  apiEndpointUsageLogs: Array<ApiEndpointUsageLog>;
  arePlacesEnabled: Maybe<Scalars['Boolean']['output']>;
  assets: CompanyAssets;
  bookingLocations: CountedCompanyBookingLocation;
  bulkInvitationSet: Maybe<BulkInvitationSet>;
  bulkInvitationSetItem: Maybe<BulkInvitationSetItem>;
  bulkInvitationSets: Array<BulkInvitationSet>;
  canChangeTourStatusConsent: Scalars['Boolean']['output'];
  carrierToursCount: Scalars['Int']['output'];
  carriersConnection: CountedCarrierConnection;
  companyIdentifiers: Array<CompanyIdentifier>;
  companyInvitation: Maybe<CompanyInvite>;
  companyTourFiltersConnection: CountedCompanyTourFilterConnection;
  companyTransportGroupsConnection: CountedCompanyTransportGroupConnection;
  companyTransportNetworkFiltersConnection: CountedCompanyTransportNetworkFilterConnection;
  companyVehicleGroup: Maybe<CompanyVehicleGroup>;
  companyVehicleGroups: Maybe<Array<Maybe<CompanyVehicleGroup>>>;
  company_id: Scalars['String']['output'];
  company_name: Maybe<Scalars['String']['output']>;
  configuration: Maybe<CompanyConfiguration>;
  connectedCustomFields: Array<ConnectedCustomField>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customExceptionTypes: Array<Scalars['String']['output']>;
  customField: Maybe<ConnectedCustomField>;
  dataNetworkVisibilityReport: Maybe<DataNetworkVisibilityReport>;
  defaultVehicleDwellTimeThresholdMinutes: Maybe<Scalars['Int']['output']>;
  featureFlag: Scalars['Boolean']['output'];
  featureFlagAuditLogConnection: FeatureFlagAuditLogConnection;
  feature_flags: Maybe<Scalars['GraphQLJSON']['output']>;
  fieldDataSources: Maybe<CompanyFieldDataSources>;
  filterBasedTransportNotification: Maybe<FilterBasedTransportNotification>;
  filterBasedTransportNotifications: Array<FilterBasedTransportNotification>;
  fms_integrated_at: Maybe<Scalars['DateTime']['output']>;
  /**
   * Utility for internal processes that allows checking whether a feature flag
   * is defined for a company, i.e distinguish between false and a flag that is
   * not defined at all
   */
  hasFeatureFlag: Scalars['Boolean']['output'];
  hasHadAnyDocuments: Scalars['Boolean']['output'];
  hasHadAnyExceptions: Scalars['Boolean']['output'];
  hasHadAnyNotes: Scalars['Boolean']['output'];
  invitationHistoryLogs: Array<InvitationHistoryItem>;
  isPendingSignup: Maybe<Scalars['Boolean']['output']>;
  isPresentOnTransporeonPlatform: Maybe<Scalars['Boolean']['output']>;
  isTrimbleVisibilityCustomer: Scalars['Boolean']['output'];
  is_carrier: Maybe<Scalars['Boolean']['output']>;
  is_paying_customer: Maybe<Scalars['Boolean']['output']>;
  is_pilot: Maybe<Scalars['Boolean']['output']>;
  is_shipper: Maybe<Scalars['Boolean']['output']>;
  is_test: Maybe<Scalars['Boolean']['output']>;
  latestBulkInvitationSet: Maybe<BulkInvitationSet>;
  latestBulkInvitationSetId: Maybe<Scalars['String']['output']>;
  lightdashEmbeddings: LightdashEmbeddings;
  mainCarriersConnection: CountedMainCarrierConnection;
  me: Maybe<UserInCompany>;
  networkRelationStatistics: NetworkRelationStatistics;
  networkRelations: Maybe<CountedCompanyRelationConnection>;
  onboardingAction: Maybe<OnboardingAction>;
  orderItemCustomFieldIdentifiersConnection: CountedOrderItemCustomFieldConnection;
  orderItemIdentifiersConnection: CountedOrderItemConnection;
  place: Maybe<CompanyPlace>;
  placesConnection: CountedCompanyPlaceConnection;
  platforms: Array<CompanyPlatform>;
  providingVisibilityCompanies: Maybe<CountedProvidingVisibilityCompaniesConnection>;
  providingVisibilityCompany: Maybe<ProvidingVisibilityCompany>;
  receivingVisibilityCompanies: Maybe<CountedReceivingVisibilityCompaniesConnection>;
  receivingVisibilityCompany: Maybe<ReceivingVisibilityCompany>;
  relationIdentifiersConnection: Maybe<CompanyIdentifierConnection>;
  settings: CompanySettings;
  shipperToursCount: Scalars['Int']['output'];
  shippersConnection: CountedShipperConnection;
  shouldSeeUpcomingTransportsTab: Scalars['Boolean']['output'];
  signup: Maybe<CompanySignup>;
  signupAction: Maybe<SignupAction>;
  signupCompletedAt: Maybe<Scalars['DateTime']['output']>;
  signupVisibilitySources: Maybe<Array<CompanyVisibilitySource>>;
  signupVisibilitySourcesViaSubcarriers: Maybe<Array<CompanyVisibilitySource>>;
  sitesConnection: CountedSiteConnection;
  stopAddress: Maybe<CompanyStopAddress>;
  stopAddressesConnection: CountedCompanyStopAddressConnection;
  stopCompanyNameIdentifiersConnection: CountedStopCompanyNameConnection;
  stopStatesIdentifiersConnection: CountedCompanyStopStatesConnection;
  tags: Array<CompanyTag>;
  telematicsIntegration: Maybe<CompanyTelematicsIntegration>;
  telematicsIntegrations: Array<CompanyTelematicsIntegration>;
  totalVehiclesCount: Scalars['Int']['output'];
  tour: Maybe<Tour>;
  tourReportsByCarrier: CarrierReportConnection;
  tourReportsByShipper: ShipperReportConnection;
  tours: CountedTourConnection;
  tripsIdentifiersConnection: CountedTripsIdentifiersConnection;
  tripsOptionsConnection: CountedTripsOptionConnection;
  tripsTours: CountedTripsTourConnection;
  unifiedOnboardingEvents: Maybe<Array<UnifiedCompanyOnboardingEvent>>;
  unifiedOnboardingStatus: Maybe<UnifiedCompanyOnboardingStatus>;
  unifiedOnboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  unifiedSignupEvents: Maybe<Array<UnifiedCompanySignupEvent>>;
  unifiedSignupStatus: Maybe<UnifiedCompanySignupStatus>;
  user: Maybe<UserInCompanyProfile>;
  userGroupOnPlatform: Maybe<UserGroupOnPlatform>;
  userGroupsOnPlatform: UserGroupOnPlatformConnection;
  users: CountedUserInCompanyProfileConnection;
  vatNumbers: Array<CompanyVatNumber>;
  vehicle: Maybe<Vehicle>;
  vehicleGrantPartner: Maybe<VehicleGrantPartner>;
  vehicleGrantPartners: CountedVehicleGrantPartnerConnection;
  vehicles: Maybe<CompanyVehicles>;
  visibilitySources: Maybe<Array<CompanyVisibilitySource>>;
  visibilitySourcesViaSubcarriers: Maybe<Array<CompanyVisibilitySource>>;
  widgetPreview: Maybe<WidgetPreview>;
};

export type CompanyBookingLocationsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyBulkInvitationSetArgs = {
  bulkInvitationSetId: Scalars['String']['input'];
};

export type CompanyBulkInvitationSetItemArgs = {
  bulkInvitationSetId: Scalars['String']['input'];
  bulkInvitationSetItemId: Scalars['String']['input'];
};

export type CompanyCarriersConnectionArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  relationshipTypes: Array<CarrierConnectionRelationshipType>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyCompanyInvitationArgs = {
  invitationId: Scalars['String']['input'];
};

export type CompanyCompanyTourFiltersConnectionArgs = {
  filterType?: InputMaybe<FilterType>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyCompanyTransportGroupsConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyCompanyTransportNetworkFiltersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyCompanyVehicleGroupArgs = {
  companyVehicleGroupId: Scalars['String']['input'];
};

export type CompanyConnectedCustomFieldsArgs = {
  transportMode: TourFilteringTransportMode;
};

export type CompanyCustomFieldArgs = {
  fieldName: Scalars['String']['input'];
  transportMode: TourFilteringTransportMode;
};

export type CompanyDataNetworkVisibilityReportArgs = {
  dataNetworkFilter?: InputMaybe<DataNetworkFilterInput>;
  filter?: InputMaybe<DataNetworkReportFilterInput>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  includeSignupStatusInReport?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<DataNetworkReportSortOrderInput>;
  type: DataNetworkReportType;
};

export type CompanyFeatureFlagArgs = {
  flagName: Scalars['String']['input'];
};

export type CompanyFeatureFlagAuditLogConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyFilterBasedTransportNotificationArgs = {
  notificationId: Scalars['String']['input'];
};

export type CompanyHasFeatureFlagArgs = {
  flagName: Scalars['String']['input'];
};

export type CompanyInvitationHistoryLogsArgs = {
  acceptingCompanyId: Scalars['String']['input'];
};

export type CompanyMainCarriersConnectionArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyNetworkRelationsArgs = {
  filter?: InputMaybe<NetworkRelationFilter>;
  includeSignupStatusInReport?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<NetworkRelationsSortOrderInput>;
};

export type CompanyOrderItemCustomFieldIdentifiersConnectionArgs = {
  search?: InputMaybe<OrderItemCustomFieldSearch>;
};

export type CompanyOrderItemIdentifiersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyPlaceArgs = {
  place_id: Scalars['String']['input'];
};

export type CompanyPlacesConnectionArgs = {
  includeSixfoldGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<PlaceTextSearch>;
};

export type CompanyProvidingVisibilityCompaniesArgs = {
  filter: ProvidingVisibilityCompaniesFilterInput;
  sort?: InputMaybe<ProvidingVisibilityCompaniesSortOrderInput>;
};

export type CompanyProvidingVisibilityCompanyArgs = {
  companyId: Scalars['String']['input'];
};

export type CompanyReceivingVisibilityCompaniesArgs = {
  filter: ReceivingVisibilityCompaniesFilterInput;
  sort?: InputMaybe<ReceivingVisibilityCompaniesSortOrderInput>;
};

export type CompanyReceivingVisibilityCompanyArgs = {
  companyId: Scalars['String']['input'];
};

export type CompanyRelationIdentifiersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  carrierCompanyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  relationIdentifierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyShippersConnectionArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanySitesConnectionArgs = {
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyStopAddressArgs = {
  addressId: Scalars['String']['input'];
};

export type CompanyStopAddressesConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyStopCompanyNameIdentifiersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyStopStatesIdentifiersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyTelematicsIntegrationArgs = {
  telematicsIntegrationId: Scalars['String']['input'];
};

export type CompanyTourArgs = {
  tour_id: Scalars['Int']['input'];
};

export type CompanyTourReportsByCarrierArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTourReportsByShipperArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyToursArgs = {
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  delayTime?: InputMaybe<TimeRangeInput>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  hasNotes?: InputMaybe<Scalars['Boolean']['input']>;
  lastSignalTimestamp?: InputMaybe<LastSignalTimestampFilterInput>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  orderItemGroups?: InputMaybe<OrderItemGroupsInput>;
  orderItemNames?: InputMaybe<Array<Scalars['String']['input']>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  role: TourCompanyRole;
  shipmentType?: InputMaybe<Array<ShipmentTypeFilter>>;
  shipperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
  textSearch?: InputMaybe<TourTextSearch>;
  timeSpentAtStop?: InputMaybe<TimeRangeInput>;
  tourIds?: InputMaybe<Array<Scalars['String']['input']>>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  voyageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  warnings?: InputMaybe<Array<TourWarningType>>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyTripsIdentifiersConnectionArgs = {
  identifierValues?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  transportMode: TripsTransportMode;
  types: Array<TripsIdentifierType>;
};

export type CompanyTripsOptionsConnectionArgs = {
  subType: Scalars['String']['input'];
  textSearch?: InputMaybe<Scalars['String']['input']>;
  transportMode: TripsTransportMode;
  type?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyTripsToursArgs = {
  airWayBillNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  billOfLadingNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  bookingNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierNames?: InputMaybe<Array<Scalars['String']['input']>>;
  containerNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  demurrage?: InputMaybe<OceanDemurrageFilter>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  flightNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  impactDays?: InputMaybe<ImpactDaysFilter>;
  locations?: InputMaybe<Array<TransportLocation>>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  shipmentType?: InputMaybe<Array<ShipmentTypeFilter>>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  transportAlerts?: InputMaybe<TransportAlertsFilterInput>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  tripsSearch?: InputMaybe<TripsTextSearch>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  voyageIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyUserArgs = {
  userId: Scalars['String']['input'];
};

export type CompanyUserGroupOnPlatformArgs = {
  groupId: Scalars['String']['input'];
};

export type CompanyUserGroupsOnPlatformArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyUsersArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyVehicleArgs = {
  vehicleId: Scalars['String']['input'];
};

export type CompanyVehicleGrantPartnerArgs = {
  grantDirection: GrantDirection;
  partnerId: Scalars['String']['input'];
};

export type CompanyVehicleGrantPartnersArgs = {
  grantDirection: GrantDirection;
  partnerNameSearch?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyWidgetPreviewArgs = {
  filters?: InputMaybe<Array<WidgetFilterInput>>;
  filtersV2?: InputMaybe<Array<WidgetFilterInputV2>>;
  operator?: InputMaybe<OperatorType>;
  type: WidgetType;
};

export type CompanyAddress = {
  __typename: 'CompanyAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type CompanyAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type CompanyAsset = {
  __typename: 'CompanyAsset';
  url: Scalars['String']['output'];
};

export type CompanyAssets = {
  __typename: 'CompanyAssets';
  consigneeBackground: Maybe<CompanyAsset>;
  consigneeLogo: Maybe<CompanyAsset>;
};

export type CompanyBookingLocation = {
  __typename: 'CompanyBookingLocation';
  bookingLocationName: Scalars['String']['output'];
};

export type CompanyBookingLocationConnection = {
  __typename: 'CompanyBookingLocationConnection';
  edges: Maybe<Array<Maybe<CompanyBookingLocationEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyBookingLocationEdge = {
  __typename: 'CompanyBookingLocationEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyBookingLocation>;
};

export type CompanyBulkInvitationRecipient = {
  email: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyBulkInviteMetadata = {
  fileName: Scalars['String']['input'];
  fileSize: Scalars['String']['input'];
};

export type CompanyConfiguration = {
  __typename: 'CompanyConfiguration';
  configurations: Configuration;
};

export type CompanyConnection = {
  __typename: 'CompanyConnection';
  edges: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyCountByOnboardingStatus = {
  __typename: 'CompanyCountByOnboardingStatus';
  count: Scalars['Int']['output'];
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
};

export type CompanyCountBySignupStatus = {
  __typename: 'CompanyCountBySignupStatus';
  count: Scalars['Int']['output'];
  signupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
};

export enum CompanyDataSource {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type CompanyEdge = {
  __typename: 'CompanyEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Company>;
};

export type CompanyFieldDataSources = {
  __typename: 'CompanyFieldDataSources';
  companyName: Maybe<CompanyDataSource>;
  isCarrier: Maybe<CompanyDataSource>;
  isShipper: Maybe<CompanyDataSource>;
  isTest: Maybe<CompanyDataSource>;
};

export type CompanyIdAndVisibilityServices = {
  __typename: 'CompanyIdAndVisibilityServices';
  companyId: Scalars['String']['output'];
  visibilityServices: Maybe<Array<VisibilityService>>;
};

export type CompanyIdentifier = {
  __typename: 'CompanyIdentifier';
  companyId: Scalars['String']['output'];
  companyIdentifierId: Scalars['String']['output'];
  discriminatorCountry: Maybe<Scalars['String']['output']>;
  identifierType: CompanyIdentifierType;
  identifierValue: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type CompanyIdentifierConnection = {
  __typename: 'CompanyIdentifierConnection';
  edges: Maybe<Array<Maybe<CompanyIdentifierEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyIdentifierEdge = {
  __typename: 'CompanyIdentifierEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyIdentifier>;
};

export enum CompanyIdentifierType {
  MC_NUMBER = 'MC_NUMBER',
  MISCELLANEOUS = 'MISCELLANEOUS',
  SCAC = 'SCAC',
  TAX_IDENTIFICATION_NUMBER = 'TAX_IDENTIFICATION_NUMBER',
  USDOT_NUMBER = 'USDOT_NUMBER',
  VAT_NUMBER = 'VAT_NUMBER',
}

export type CompanyInvitationRecipient = {
  __typename: 'CompanyInvitationRecipient';
  email: Scalars['String']['output'];
  locale: Scalars['String']['output'];
};

export type CompanyInvite = {
  __typename: 'CompanyInvite';
  acceptedByCompany: Maybe<CompanyProfile>;
  companyInviteId: Scalars['String']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  deadlineAt: Scalars['DateTime']['output'];
  historyLog: Array<InvitationHistoryItem>;
  identifiers: Maybe<Array<CompanyIdentifier>>;
  invitationLink: Maybe<Scalars['String']['output']>;
  inviteMethod: Maybe<CompanyInviteMethod>;
  invitedAt: Scalars['DateTime']['output'];
  invitedByCompany: Maybe<CompanyProfile>;
  invitedByUser: Maybe<UserProfile>;
  invitedCompany: Maybe<CompanyProfile>;
  metadata: CompanyInviteMetadata;
  recipient: CompanyInviteRecipient;
  recipients: Array<CompanyInvitationRecipient>;
  status: CompanyInviteStatus;
  type: CompanyInviteType;
};

export type CompanyInviteMetadata = {
  __typename: 'CompanyInviteMetadata';
  locale: Scalars['String']['output'];
};

export enum CompanyInviteMethod {
  BULK = 'BULK',
  STANDALONE = 'STANDALONE',
}

export type CompanyInviteRecipient = {
  __typename: 'CompanyInviteRecipient';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum CompanyInviteStatus {
  COMPLETED = 'COMPLETED',
  IGNORED = 'IGNORED',
  PENDING = 'PENDING',
  WITHDRAWN = 'WITHDRAWN',
}

export enum CompanyInviteType {
  LINK_AND_EMAIL = 'LINK_AND_EMAIL',
  LINK_ONLY = 'LINK_ONLY',
}

export type CompanyOverview = {
  __typename: 'CompanyOverview';
  assignmentPercentage: Maybe<Scalars['Float']['output']>;
  assignmentPercentageChange: Maybe<Scalars['Float']['output']>;
  companyId: Scalars['String']['output'];
  tourCancelledCount: Scalars['Int']['output'];
  tourCompletedCount: Scalars['Int']['output'];
  tourTotalCount: Scalars['Int']['output'];
  tourUnassignedCount: Scalars['Int']['output'];
  tourUpcomingCount: Scalars['Int']['output'];
  tourWorkingCount: Scalars['Int']['output'];
  visibilityIndex: Maybe<Scalars['Float']['output']>;
  visibilityIndexChange: Maybe<Scalars['Float']['output']>;
};

export type CompanyPlace = {
  __typename: 'CompanyPlace';
  addresses: Array<CompanyPlaceAddress>;
  geofences: Array<PlaceGeofenceWithZone>;
  isSixfoldGenerated: Scalars['Boolean']['output'];
  location: PointLocation;
  name: Scalars['String']['output'];
  place_id: Scalars['String']['output'];
  possibleAddresses: CompanyStopAddressConnection;
  referenceId: Maybe<Scalars['String']['output']>;
  stopAddresses: Array<CompanyStopAddress>;
  tours: CountedPlaceTourConnection;
};

export type CompanyPlacePossibleAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyPlaceToursArgs = {
  arrivalTimeAfter?: InputMaybe<Scalars['DateTime']['input']>;
  arrivalTimeBefore?: InputMaybe<Scalars['DateTime']['input']>;
  status: SiteTourStatusFilter;
};

export type CompanyPlaceAddress = {
  __typename: 'CompanyPlaceAddress';
  addressId: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  fullAddress: Maybe<Scalars['String']['output']>;
  gate: Maybe<Scalars['String']['output']>;
  gateId: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type CompanyPlaceConnection = {
  __typename: 'CompanyPlaceConnection';
  edges: Maybe<Array<Maybe<CompanyPlaceEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyPlaceEdge = {
  __typename: 'CompanyPlaceEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyPlace>;
};

export type CompanyPlatform = {
  __typename: 'CompanyPlatform';
  company_id: Scalars['String']['output'];
  company_id_on_platform: Scalars['String']['output'];
  company_platform_id: Scalars['String']['output'];
  platform_id: Scalars['String']['output'];
  platform_name: Scalars['String']['output'];
};

export type CompanyPlatformInput = {
  company_id_on_platform: Scalars['String']['input'];
  platform_id: Scalars['String']['input'];
};

export type CompanyProfile = {
  __typename: 'CompanyProfile';
  company_id: Scalars['String']['output'];
  company_name: Maybe<Scalars['String']['output']>;
};

export type CompanyProfileConnection = {
  __typename: 'CompanyProfileConnection';
  edges: Maybe<Array<Maybe<CompanyProfileEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyProfileEdge = {
  __typename: 'CompanyProfileEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyProfile>;
};

export type CompanyRelationship = {
  __typename: 'CompanyRelationship';
  companyRelationshipId: Scalars['String']['output'];
  dataSharingConsent: Maybe<DataSharingConsent>;
  relationIdentifiers: Array<CompanyIdentifier>;
  relationshipType: CompanyRelationshipType;
  sourceCompany: Company;
  targetCompany: Company;
  unifiedOnboardingEvents: Maybe<Array<UnifiedCompanyRelationshipOnboardingEvent>>;
  unifiedOnboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  unifiedOnboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  unifiedSignupEvents: Maybe<Array<UnifiedCompanyRelationshipSignupEvent>>;
  unifiedSignupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
};

export type CompanyRelationshipConnection = {
  __typename: 'CompanyRelationshipConnection';
  edges: Maybe<Array<Maybe<CompanyRelationshipEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyRelationshipEdge = {
  __typename: 'CompanyRelationshipEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyRelationship>;
};

export enum CompanyRelationshipRole {
  SOURCE = 'SOURCE',
  TARGET = 'TARGET',
}

export enum CompanyRelationshipType {
  CARRIER = 'CARRIER',
  REFERRER = 'REFERRER',
  SUBCARRIER = 'SUBCARRIER',
}

export enum CompanyRole {
  CARRIER = 'CARRIER',
  SHIPPER = 'SHIPPER',
}

export type CompanySettings = {
  __typename: 'CompanySettings';
  allowAllocatingFromGlobalPool: Scalars['Boolean']['output'];
  shareOwnVehiclesInGlobalPool: Scalars['Boolean']['output'];
};

export type CompanySettingsInput = {
  allowAllocatingFromGlobalPool?: InputMaybe<Scalars['Boolean']['input']>;
  shareOwnVehiclesInGlobalPool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanySignup = {
  __typename: 'CompanySignup';
  companySignupId: Scalars['String']['output'];
  contactPerson: Maybe<CompanySignupContactPerson>;
  hasFms: Maybe<Scalars['Boolean']['output']>;
  isSubcontracting: Maybe<Scalars['Boolean']['output']>;
  status: CompanySignupStatus;
  surveyAnswers: Maybe<Array<CompanySignupSurveyAnswer>>;
  surveySubmittedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CompanySignupCompleted = {
  __typename: 'CompanySignupCompleted';
  company: Company;
  companySignupId: Scalars['String']['output'];
  status: CompanySignupStatus;
  user: User;
};

export type CompanySignupContactPerson = {
  __typename: 'CompanySignupContactPerson';
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
};

export enum CompanySignupStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export type CompanySignupSurveyAnswer = {
  __typename: 'CompanySignupSurveyAnswer';
  fieldName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CompanyStopAddress = {
  __typename: 'CompanyStopAddress';
  city: Maybe<Scalars['String']['output']>;
  closeByAddresses: CompanyStopAddressConnection;
  companyId: Scalars['String']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  invalidFields: Maybe<Array<CompanyStopAddressField>>;
  location: Maybe<PointLocation>;
  place: Maybe<CompanyPlace>;
  postalCode: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  stopAddressId: Scalars['String']['output'];
  streetAddress: Maybe<Scalars['String']['output']>;
};

export type CompanyStopAddressCloseByAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyStopAddressConnection = {
  __typename: 'CompanyStopAddressConnection';
  edges: Maybe<Array<Maybe<CompanyStopAddressEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyStopAddressEdge = {
  __typename: 'CompanyStopAddressEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyStopAddress>;
};

export enum CompanyStopAddressField {
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  POSTAL_CODE = 'POSTAL_CODE',
  STREET_ADDRESS = 'STREET_ADDRESS',
}

export type CompanyStopStates = {
  __typename: 'CompanyStopStates';
  country: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type CompanyStopStatesConnection = {
  __typename: 'CompanyStopStatesConnection';
  edges: Maybe<Array<Maybe<CompanyStopStatesEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyStopStatesEdge = {
  __typename: 'CompanyStopStatesEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyStopStates>;
};

export type CompanyTag = {
  __typename: 'CompanyTag';
  name: Scalars['String']['output'];
};

export type CompanyTagInput = {
  companyId: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};

export type CompanyTelematicsIntegration = {
  __typename: 'CompanyTelematicsIntegration';
  companyId: Scalars['String']['output'];
  connectionData: Maybe<Scalars['GraphQLJSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  diagnosticStatus: Maybe<TelematicsIntegrationDiagnosticStatus>;
  enabled: Scalars['Boolean']['output'];
  exclusiveVehicleCount: Scalars['Int']['output'];
  isAllowedToUpdateCredentials: Scalars['Boolean']['output'];
  lastSuccess: Maybe<Scalars['DateTime']['output']>;
  telematicsIntegrationId: Scalars['String']['output'];
  telematicsProvider: TelematicsProvider;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyTourFilter = {
  __typename: 'CompanyTourFilter';
  companyTourFilterId: Scalars['String']['output'];
  filter: Maybe<TourFilter>;
  filterV2: Maybe<Scalars['GraphQLJSON']['output']>;
  name: Scalars['String']['output'];
  sharedByUser: Maybe<UserProfile>;
  sharingToken: Maybe<Scalars['String']['output']>;
  tourCount: Scalars['Int']['output'];
  tours: Array<Tour>;
};

export type CompanyTourFilterToursArgs = {
  included_filter_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTourFilterConnection = {
  __typename: 'CompanyTourFilterConnection';
  edges: Maybe<Array<Maybe<CompanyTourFilterEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyTourFilterEdge = {
  __typename: 'CompanyTourFilterEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyTourFilter>;
};

export type CompanyTransportGroup = {
  __typename: 'CompanyTransportGroup';
  companyId: Scalars['String']['output'];
  transportGroupId: Scalars['String']['output'];
};

export type CompanyTransportGroupConnection = {
  __typename: 'CompanyTransportGroupConnection';
  edges: Maybe<Array<Maybe<CompanyTransportGroupEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyTransportGroupEdge = {
  __typename: 'CompanyTransportGroupEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyTransportGroup>;
};

export type CompanyTransportNetworkFilter = {
  __typename: 'CompanyTransportNetworkFilter';
  companyTransportNetworkFilterId: Scalars['String']['output'];
  dataNetworkFilter: Maybe<DataNetworkFilter>;
  filter: TransportNetworkFilter;
  filterV2: Maybe<Scalars['GraphQLJSON']['output']>;
  name: Scalars['String']['output'];
  visibilityType: CompanyTransportNetworkFilterVisibility;
};

export type CompanyTransportNetworkFilterConnection = {
  __typename: 'CompanyTransportNetworkFilterConnection';
  edges: Maybe<Array<Maybe<CompanyTransportNetworkFilterEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyTransportNetworkFilterEdge = {
  __typename: 'CompanyTransportNetworkFilterEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CompanyTransportNetworkFilter>;
};

export enum CompanyTransportNetworkFilterVisibility {
  COMPANY = 'COMPANY',
  PRIVATE = 'PRIVATE',
}

export type CompanyTypeInput = {
  is_carrier?: InputMaybe<Scalars['Boolean']['input']>;
  is_paying_customer?: InputMaybe<Scalars['Boolean']['input']>;
  is_pending_signup?: InputMaybe<Scalars['Boolean']['input']>;
  is_pilot?: InputMaybe<Scalars['Boolean']['input']>;
  is_shipper?: InputMaybe<Scalars['Boolean']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyVatNumber = {
  __typename: 'CompanyVatNumber';
  vatNumber: Scalars['String']['output'];
};

export type CompanyVatNumberChangeResult = {
  __typename: 'CompanyVatNumberChangeResult';
  success: Scalars['Boolean']['output'];
};

export type CompanyVatNumberInput = {
  discriminatorCountry?: InputMaybe<Scalars['String']['input']>;
  vatNumber: Scalars['String']['input'];
};

export type CompanyVehicleGroup = {
  __typename: 'CompanyVehicleGroup';
  companyVehicleGroupId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  vehiclesConnection: CountedVehicleInGroupConnection;
};

export type CompanyVehicleGroupVehiclesConnectionArgs = {
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  vehicleState?: InputMaybe<VehicleState>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
};

export type CompanyVehicles = {
  __typename: 'CompanyVehicles';
  all: Maybe<CountedVehicleConnection>;
  allocatable: Maybe<CountedVehicleConnection>;
  distinctDedicatedToPartnersVehiclesCount: Scalars['Int']['output'];
  distinctDedicatedToPartnersVehiclesCountByStatus: VehicleCountByStatus;
  grantedByPartners: Maybe<CountedVehicleConnection>;
  grantedToPartners: Maybe<CountedVehicleConnection>;
};

export type CompanyVehiclesAllArgs = {
  grantedByPartnersFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hasAllocationsFilter?: InputMaybe<HasAllocationsFilterInput>;
  integrationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  ownVehiclesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  partnerNameSearch?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<VehicleFilterSortOrderInput>;
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
  vehicleGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleState?: InputMaybe<VehicleState>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
};

export type CompanyVehiclesAllocatableArgs = {
  hasAllocationsFilter?: InputMaybe<HasAllocationsFilterInput>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  partnerNameSearch?: InputMaybe<Scalars['String']['input']>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type CompanyVehiclesGrantedByPartnersArgs = {
  grantedByPartnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  partnerNameSearch?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<VehicleFilterSortOrderInput>;
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type CompanyVehiclesGrantedToPartnersArgs = {
  grantedToPartnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  partnerNameSearch?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<VehiclePermissionsInput>;
  sort?: InputMaybe<VehicleFilterSortOrderInput>;
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type CompanyVisibilitySource = {
  __typename: 'CompanyVisibilitySource';
  status: CompanyVisibilitySourceStatus;
  type: TourVisibilitySourceType;
};

export enum CompanyVisibilitySourceStatus {
  ACTIVE = 'ACTIVE',
  IDENTIFIED = 'IDENTIFIED',
}

export enum ComparisonOperator {
  GTE = 'GTE',
  LTE = 'LTE',
  RANGE = 'RANGE',
}

export enum ComparisonOperatorExtended {
  ANY_TIME_RANGE = 'ANY_TIME_RANGE',
  GTE = 'GTE',
  LTE = 'LTE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  RANGE = 'RANGE',
}

export enum ComparisonTerm {
  DELAY_GREATER_THAN = 'DELAY_GREATER_THAN',
  TIME_TO_ARRIVAL_LESS_THAN = 'TIME_TO_ARRIVAL_LESS_THAN',
}

export enum ComparisonUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}

export type Configuration = {
  __typename: 'Configuration';
  customerPortal: Maybe<CustomerPortalConfiguration>;
  sharedStopView: Maybe<SharedStopViewConfiguration>;
  sharedTourView: Maybe<SharedTourViewConfiguration>;
};

export type ConfigurationUpdateInput = {
  customerPortal?: InputMaybe<CustomerPortalConfigurationValueInput>;
  sharedStopView?: InputMaybe<SharedStopViewConfigurationValueInput>;
  sharedTourView?: InputMaybe<SharedTourViewConfigurationValueInput>;
};

export type ConnectedCustomField = {
  __typename: 'ConnectedCustomField';
  fieldName: Scalars['String']['output'];
  valuesConnection: CountedCustomFieldValueConnection;
};

export type ConnectedCustomFieldValuesConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectionParameter = {
  __typename: 'ConnectionParameter';
  defaultValue: Maybe<Scalars['String']['output']>;
  enumFieldValues: Array<ConnectionParameterEnumValue>;
  fieldDisplayName: Maybe<Scalars['String']['output']>;
  fieldName: Scalars['String']['output'];
  fieldType: ConnectionParameterFieldType;
  isInternal: Scalars['Boolean']['output'];
  isOptional: Scalars['Boolean']['output'];
  isSensitive: Scalars['Boolean']['output'];
};

export type ConnectionParameterEnumValue = {
  __typename: 'ConnectionParameterEnumValue';
  display_name: Maybe<Scalars['String']['output']>;
  is_default: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export enum ConnectionParameterFieldType {
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
  JSON = 'JSON',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  URL = 'URL',
}

export enum ConsentSource {
  AUTO_INHERITED = 'AUTO_INHERITED',
  BACKOFFICE_USER = 'BACKOFFICE_USER',
  INHERITED = 'INHERITED',
  USER = 'USER',
}

export enum ConsentState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  REQUESTED = 'REQUESTED',
}

export type CopyDashboardInput = {
  companyId: Scalars['String']['input'];
  dashboardId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CountedBulkInvitationSetConnection = {
  __typename: 'CountedBulkInvitationSetConnection';
  bulkInvitationSets: BulkInvitationSetConnection;
  count: Scalars['Int']['output'];
};

export type CountedBulkInvitationSetConnectionBulkInvitationSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedBulkInvitationSetItemConnection = {
  __typename: 'CountedBulkInvitationSetItemConnection';
  count: Scalars['Int']['output'];
  items: BulkInvitationSetItemConnection;
  statistics: BulkInvitationSetStatistics;
};

export type CountedBulkInvitationSetItemConnectionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedBulkInvitationSetItemConnectionStatisticsArgs = {
  filter?: InputMaybe<BulkInvitationSetStatisticsFilterInput>;
};

export type CountedCarrierConnection = {
  __typename: 'CountedCarrierConnection';
  carriers: CompanyProfileConnection;
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
};

export type CountedCarrierConnectionCarriersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyBookingLocation = {
  __typename: 'CountedCompanyBookingLocation';
  bookingLocations: CompanyBookingLocationConnection;
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
};

export type CountedCompanyBookingLocationBookingLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyPlaceConnection = {
  __typename: 'CountedCompanyPlaceConnection';
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
  mapTilesBounds: Maybe<Bounds>;
  mapTilesTemplateURI: Maybe<Scalars['String']['output']>;
  places: CompanyPlaceConnection;
};

export type CountedCompanyPlaceConnectionPlacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyRelationConnection = {
  __typename: 'CountedCompanyRelationConnection';
  count: Scalars['Int']['output'];
  networkRelations: NetworkRelationConnection;
  reportDownloadURI: Scalars['String']['output'];
};

export type CountedCompanyRelationConnectionNetworkRelationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyStopAddressConnection = {
  __typename: 'CountedCompanyStopAddressConnection';
  addresses: CompanyStopAddressConnection;
  count: Scalars['Int']['output'];
  mapTilesBounds: Maybe<Bounds>;
  mapTilesTemplateURI: Maybe<Scalars['String']['output']>;
};

export type CountedCompanyStopAddressConnectionAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyStopStatesConnection = {
  __typename: 'CountedCompanyStopStatesConnection';
  hasAny: Scalars['Boolean']['output'];
  stopStatesIdentifiers: CompanyStopStatesConnection;
};

export type CountedCompanyStopStatesConnectionStopStatesIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyTourFilterConnection = {
  __typename: 'CountedCompanyTourFilterConnection';
  companyTourFilters: CompanyTourFilterConnection;
  count: Scalars['Int']['output'];
};

export type CountedCompanyTourFilterConnectionCompanyTourFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyTransportGroupConnection = {
  __typename: 'CountedCompanyTransportGroupConnection';
  companyTransportGroups: CompanyTransportGroupConnection;
  hasAny: Scalars['Boolean']['output'];
};

export type CountedCompanyTransportGroupConnectionCompanyTransportGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCompanyTransportNetworkFilterConnection = {
  __typename: 'CountedCompanyTransportNetworkFilterConnection';
  companyTransportNetworkFilters: CompanyTransportNetworkFilterConnection;
  count: Scalars['Int']['output'];
};

export type CountedCompanyTransportNetworkFilterConnectionCompanyTransportNetworkFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedCustomFieldValueConnection = {
  __typename: 'CountedCustomFieldValueConnection';
  count: Scalars['Int']['output'];
  values: CustomFieldValueConnection;
};

export type CountedCustomFieldValueConnectionValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedDashboardsConnection = {
  __typename: 'CountedDashboardsConnection';
  count: Scalars['Int']['output'];
  dashboards: DashboardConnection;
};

export type CountedDashboardsConnectionDashboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedDataNetworkVisibilityReportCompanyConnection = {
  __typename: 'CountedDataNetworkVisibilityReportCompanyConnection';
  companies: DataNetworkVisibilityReportCompanyConnection;
  count: Scalars['Int']['output'];
};

export type CountedDataNetworkVisibilityReportCompanyConnectionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedMainCarrierConnection = {
  __typename: 'CountedMainCarrierConnection';
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
  mainCarriers: CompanyProfileConnection;
};

export type CountedMainCarrierConnectionMainCarriersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedOrderItemConnection = {
  __typename: 'CountedOrderItemConnection';
  hasAny: Scalars['Boolean']['output'];
  orderItemIdentifiers: OrderItemIdentifierConnection;
};

export type CountedOrderItemConnectionOrderItemIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedOrderItemCustomFieldConnection = {
  __typename: 'CountedOrderItemCustomFieldConnection';
  hasAny: Scalars['Boolean']['output'];
  orderItemCustomFieldIdentifiers: OrderItemCustomFieldConnection;
};

export type CountedOrderItemCustomFieldConnectionOrderItemCustomFieldIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedPlaceTourConnection = {
  __typename: 'CountedPlaceTourConnection';
  count: Scalars['Int']['output'];
  tours: TourConnection;
};

export type CountedPlaceTourConnectionToursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedProvidingVisibilityCompaniesConnection = {
  __typename: 'CountedProvidingVisibilityCompaniesConnection';
  companies: ProvidingVisibilityCompanyConnection;
  count: Scalars['Int']['output'];
};

export type CountedProvidingVisibilityCompaniesConnectionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedReceivingVisibilityCompaniesConnection = {
  __typename: 'CountedReceivingVisibilityCompaniesConnection';
  companies: ReceivingVisibilityCompanyConnection;
  count: Scalars['Int']['output'];
};

export type CountedReceivingVisibilityCompaniesConnectionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedShipperConnection = {
  __typename: 'CountedShipperConnection';
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
  shippers: CompanyProfileConnection;
};

export type CountedShipperConnectionShippersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedSiteConnection = {
  __typename: 'CountedSiteConnection';
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
  sites: SiteConnection;
};

export type CountedSiteConnectionSitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedStopCompanyNameConnection = {
  __typename: 'CountedStopCompanyNameConnection';
  hasAny: Scalars['Boolean']['output'];
  stopCompanyNameIdentifiers: StopCompanyNameConnection;
};

export type CountedStopCompanyNameConnectionStopCompanyNameIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedTelematicsIntegrationConnection = {
  __typename: 'CountedTelematicsIntegrationConnection';
  count: Scalars['Int']['output'];
  integrations: TelematicsIntegrationConnection;
};

export type CountedTelematicsIntegrationConnectionIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedTelematicsProviderConnection = {
  __typename: 'CountedTelematicsProviderConnection';
  count: Scalars['Int']['output'];
  providers: TelematicsProviderConnection;
};

export type CountedTelematicsProviderConnectionProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedTourConnection = {
  __typename: 'CountedTourConnection';
  count: Scalars['Int']['output'];
  mapTilesBounds: Maybe<Bounds>;
  mapTilesTemplateURI: Maybe<Scalars['String']['output']>;
  reportDownloadURI: Maybe<Scalars['String']['output']>;
  tours: TourConnection;
};

export type CountedTourConnectionReportDownloadUriArgs = {
  timezone?: InputMaybe<Scalars['IANATimezone']['input']>;
};

export type CountedTourConnectionToursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedTripsIdentifiersConnection = {
  __typename: 'CountedTripsIdentifiersConnection';
  hasAny: Scalars['Boolean']['output'];
  tourIdentifiers: TripsTourIdentifierConnection;
};

export type CountedTripsIdentifiersConnectionTourIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedTripsOptionConnection = {
  __typename: 'CountedTripsOptionConnection';
  count: Scalars['Int']['output'];
  options: Maybe<TripsOptionConnection>;
};

export type CountedTripsOptionConnectionOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  subType?: InputMaybe<Scalars['String']['input']>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  transportMode?: InputMaybe<TripsTransportMode>;
  type?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CountedTripsTourConnection = {
  __typename: 'CountedTripsTourConnection';
  count: Scalars['Int']['output'];
  reportDownloadURI: Maybe<Scalars['String']['output']>;
  tours: TourConnection;
};

export type CountedTripsTourConnectionReportDownloadUriArgs = {
  timezone?: InputMaybe<Scalars['IANATimezone']['input']>;
};

export type CountedTripsTourConnectionToursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedUserFilterConnection = {
  __typename: 'CountedUserFilterConnection';
  count: Scalars['Int']['output'];
  userFilters: UserFilterConnection;
};

export type CountedUserFilterConnectionUserFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedUserInCompanyProfileConnection = {
  __typename: 'CountedUserInCompanyProfileConnection';
  count: Scalars['Int']['output'];
  hasAny: Scalars['Boolean']['output'];
  users: UserInCompanyProfileConnection;
};

export type CountedUserInCompanyProfileConnectionUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedVehicleConnection = {
  __typename: 'CountedVehicleConnection';
  boundingBox: Maybe<Array<PointLocation>>;
  count: Scalars['Int']['output'];
  mapTilesTemplateURI: Maybe<Scalars['String']['output']>;
  vehicles: VehicleConnection;
  vehiclesReportDownloadURI: Maybe<Scalars['String']['output']>;
};

export type CountedVehicleConnectionMapTilesTemplateUriArgs = {
  dwellTime?: InputMaybe<Scalars['Int']['input']>;
  returnTimeBasedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  returnVehicleState?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountedVehicleConnectionVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedVehicleGrantPartnerConnection = {
  __typename: 'CountedVehicleGrantPartnerConnection';
  count: Scalars['Int']['output'];
  partners: VehicleGrantPartnerConnection;
};

export type CountedVehicleGrantPartnerConnectionPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedVehicleInGroupConnection = {
  __typename: 'CountedVehicleInGroupConnection';
  count: Scalars['Int']['output'];
  vehicles: Maybe<VehicleConnection>;
};

export type CountedVehicleInGroupConnectionVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CountedWidgetsConnection = {
  __typename: 'CountedWidgetsConnection';
  count: Scalars['Int']['output'];
  widgets: WidgetConnection;
};

export type CountedWidgetsConnectionWidgetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['IANATimezone']['input']>;
};

export type CreateBulkInvitationSetInput = {
  invitations: Array<CreateBulkInvitationSetItemInput>;
  metadata: CompanyBulkInviteMetadata;
};

export type CreateBulkInvitationSetItemInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  identifierValues?: InputMaybe<Array<Scalars['String']['input']>>;
  recipients: Array<CompanyBulkInvitationRecipient>;
};

export type CreateBulkInvitationSetResult = {
  __typename: 'CreateBulkInvitationSetResult';
  bulkInvitationSetId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateCompanyIdentifierInput = {
  companyId: Scalars['String']['input'];
  discriminatorCountry?: InputMaybe<Scalars['String']['input']>;
  identifierType: CompanyIdentifierType;
  identifierValue: Scalars['String']['input'];
};

export type CreateCompanyIdentifierResult = {
  __typename: 'CreateCompanyIdentifierResult';
  success: Scalars['Boolean']['output'];
};

export type CreateCompanyInput = {
  company_name: Scalars['String']['input'];
  feature_flags?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  is_carrier?: InputMaybe<Scalars['Boolean']['input']>;
  is_paying_customer?: InputMaybe<Scalars['Boolean']['input']>;
  is_pending_signup?: InputMaybe<Scalars['Boolean']['input']>;
  is_pilot?: InputMaybe<Scalars['Boolean']['input']>;
  is_shipper?: InputMaybe<Scalars['Boolean']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCompanyInvitationEmailInput = {
  externalInvitation: ExternalCompanyInvitationEmailInput;
};

export type CreateCompanyInvitationEmailResult = {
  __typename: 'CreateCompanyInvitationEmailResult';
  companyInviteId: Maybe<Scalars['String']['output']>;
  invalidEmails: Array<Scalars['String']['output']>;
  invalidIdentifiers: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateCompanyInvitationLinkInput = {
  externalInvitation: ExternalCompanyInvitationLinkInput;
};

export type CreateCompanyInvitationLinkResult = {
  __typename: 'CreateCompanyInvitationLinkResult';
  companyInviteId: Maybe<Scalars['String']['output']>;
  invalidIdentifiers: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateCompanyInvitationsResult = {
  __typename: 'CreateCompanyInvitationsResult';
  invalidEmails: Array<Scalars['String']['output']>;
  invalidIdentifiers: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateCompanyPlaceInput = {
  companyId: Scalars['String']['input'];
  geodata?: InputMaybe<Array<PlaceGeodataInput>>;
  location: PointLocationInput;
  name: Scalars['String']['input'];
  referenceId?: InputMaybe<Scalars['String']['input']>;
  stopAddressIds: Array<Scalars['String']['input']>;
};

export type CreateCompanyRelationshipInput = {
  relationshipType: CompanyRelationshipType;
  sourceCompanyId: Scalars['String']['input'];
  targetCompanyId: Scalars['String']['input'];
};

export type CreateCompanyTourFilterInput = {
  companyId: Scalars['String']['input'];
  companyRole?: InputMaybe<CompanyRole>;
  filter?: InputMaybe<TourFilterInput>;
  filterType?: InputMaybe<FilterType>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCompanyTransportNetworkFilterInput = {
  companyId: Scalars['String']['input'];
  dataNetworkFilter?: InputMaybe<DataNetworkFilterInput>;
  filter?: InputMaybe<DataNetworkReportFilterInput>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  name: Scalars['String']['input'];
  visibilityType?: InputMaybe<CompanyTransportNetworkFilterVisibility>;
};

export type CreateCompanyVehicleGroupInput = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateDashboardInput = {
  companyId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateExampleDashboardInput = {
  companyId: Scalars['String']['input'];
  translatedName: Scalars['String']['input'];
  translatedWidgets: Array<ExampleWidgetInput>;
};

export type CreateNoteInput = {
  companyId: Scalars['String']['input'];
  deliveryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  noteText: Scalars['String']['input'];
  noteVisibility: NoteVisibility;
  stopId?: InputMaybe<Scalars['String']['input']>;
  tourId: Scalars['String']['input'];
};

export type CreateOrUpdateFilterBasedTransportNotificationInput = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  notificationTrigger?: InputMaybe<NotificationTriggerInput>;
  recipients?: InputMaybe<Array<EmailRecipientInput>>;
  recipientsV2?: InputMaybe<Array<RecipientInput>>;
  transportFilter?: InputMaybe<TourFilterInput>;
  transportFilterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
};

export type CreateOrUpdateRelationIdentifiersInput = {
  companyId: Scalars['String']['input'];
  companyInviteId?: InputMaybe<Scalars['String']['input']>;
  identifierValues?: InputMaybe<Array<IdentifierValueInput>>;
  ownerCompanyId: Scalars['String']['input'];
};

export type CreateOrUpdateRelationIdentifiersResult = {
  __typename: 'CreateOrUpdateRelationIdentifiersResult';
  invalidIdentifiers: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateOrUpdateTransportNotificationInput = {
  companyId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  notificationTrigger?: InputMaybe<NotificationTriggerInput>;
  recipients?: InputMaybe<Array<EmailRecipientInput>>;
  recipientsV2?: InputMaybe<Array<RecipientInput>>;
  tourId: Scalars['String']['input'];
};

export type CreatePlatformSupportTicketInput = {
  businessImpact: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  sourceUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  tourId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTelematicsIntegrationInput = {
  company_id: Scalars['String']['input'];
  company_integration_id: Scalars['String']['input'];
  connection_data: Scalars['GraphQLJSON']['input'];
  enabled: Scalars['Boolean']['input'];
  telematics_provider_id: Scalars['String']['input'];
};

export type CreateUserFilterInput = {
  companyId: Scalars['String']['input'];
  companyRole?: InputMaybe<CompanyRole>;
  filter?: InputMaybe<TourFilterInput>;
  filterType?: InputMaybe<FilterType>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  name: Scalars['String']['input'];
};

export type CreateVehicleGrantsInput = {
  grantedByCompany: Scalars['String']['input'];
  grantedToCompany: Scalars['String']['input'];
};

export type CreateVehicleInput = {
  licensePlate: Scalars['String']['input'];
  remoteId: Scalars['String']['input'];
};

export type CreateVehicleMobileAppTrackerInput = {
  alias: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type CreateVehiclesInput = {
  telematicsIntegrationId: Scalars['String']['input'];
  vehicles: Array<CreateVehicleInput>;
};

export type CreateVisibilitySolutionsTicketInput = {
  companyId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  sourceUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateWidgetInput = {
  dashboardId: Scalars['String']['input'];
  filters?: InputMaybe<Array<WidgetFilterInput>>;
  filtersV2?: InputMaybe<Array<WidgetFilterInputV2>>;
  name: Scalars['String']['input'];
  operator?: InputMaybe<OperatorType>;
  type: WidgetType;
};

export enum CredentialType {
  API_KEY = 'API_KEY',
  EXT_SHIPMENT_STATUS_API_KEY = 'EXT_SHIPMENT_STATUS_API_KEY',
}

export type Credentials = {
  __typename: 'Credentials';
  addedAt: Scalars['DateTime']['output'];
  addedByUserId: Scalars['String']['output'];
  type: CredentialType;
  userId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomField = {
  __typename: 'CustomField';
  fieldName: Scalars['String']['output'];
  values: Array<CustomFieldValue>;
};

export type CustomFieldValue = {
  __typename: 'CustomFieldValue';
  value: Scalars['String']['output'];
};

export type CustomFieldValueConnection = {
  __typename: 'CustomFieldValueConnection';
  edges: Maybe<Array<Maybe<CustomFieldValueEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldValueEdge = {
  __typename: 'CustomFieldValueEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CustomFieldValue>;
};

export type CustomerPortalConfiguration = {
  __typename: 'CustomerPortalConfiguration';
  detailViewTransportIdentifiers: Array<TransportIdentifier>;
  primaryTransportIdentifier: TransportIdentifier;
};

export type CustomerPortalConfigurationInput = {
  detailViewTransportIdentifiers: Array<TransportIdentifier>;
  primaryTransportIdentifier: TransportIdentifier;
};

export type CustomerPortalConfigurationValueInput = {
  value?: InputMaybe<CustomerPortalConfigurationInput>;
};

export type Dashboard = {
  __typename: 'Dashboard';
  canUserEdit: Scalars['Boolean']['output'];
  dashboardId: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  isExample: Scalars['Boolean']['output'];
  isOwnedByMe: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  owner: UserProfile;
  sharedWithUsers: Maybe<Array<UserProfile>>;
  sharingType: DashboardSharingType;
  widget: Maybe<Widget>;
  widgets: CountedWidgetsConnection;
};

export type DashboardWidgetArgs = {
  timezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  widgetId: Scalars['String']['input'];
};

export type DashboardConnection = {
  __typename: 'DashboardConnection';
  edges: Maybe<Array<Maybe<DashboardEdge>>>;
  pageInfo: PageInfo;
};

export type DashboardEdge = {
  __typename: 'DashboardEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Dashboard>;
};

export enum DashboardQueryType {
  CREATED_BY_ME = 'CREATED_BY_ME',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
}

export enum DashboardSharingType {
  ONLY_ME = 'ONLY_ME',
  WITH_COMPANY = 'WITH_COMPANY',
  WITH_SPECIFIC_USERS = 'WITH_SPECIFIC_USERS',
}

export type DataNetworkFilter = {
  __typename: 'DataNetworkFilter';
  onboardingStatus: Maybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  relationIdentifierIds: Maybe<Array<Scalars['String']['output']>>;
};

export type DataNetworkFilterInput = {
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  relationIdentifierIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DataNetworkReportFilterInput = {
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  relationIdentifierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  shipperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeEnd: Scalars['DateTime']['input'];
  startTimeStart: Scalars['DateTime']['input'];
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
};

export enum DataNetworkReportSortKey {
  ASSIGNMENT_PERCENTAGE = 'ASSIGNMENT_PERCENTAGE',
  ASSIGNMENT_PERCENTAGE_CHANGE = 'ASSIGNMENT_PERCENTAGE_CHANGE',
  CANCELLED_TOURS_COUNT = 'CANCELLED_TOURS_COUNT',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPLETED_TOURS_COUNT = 'COMPLETED_TOURS_COUNT',
  DATE_ADDED = 'DATE_ADDED',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  ONBOARDING_STATUS_TRANSITIONED_AT = 'ONBOARDING_STATUS_TRANSITIONED_AT',
  TOTAL_TOURS_COUNT = 'TOTAL_TOURS_COUNT',
  TOUR_STATUS_CONSENT = 'TOUR_STATUS_CONSENT',
  UNASSIGNED_TOURS_COUNT = 'UNASSIGNED_TOURS_COUNT',
  UPCOMING_TOURS_COUNT = 'UPCOMING_TOURS_COUNT',
  VISIBILITY_INDEX = 'VISIBILITY_INDEX',
  VISIBILITY_INDEX_CHANGE = 'VISIBILITY_INDEX_CHANGE',
  WORKING_TOURS_COUNT = 'WORKING_TOURS_COUNT',
}

export type DataNetworkReportSortOrderInput = {
  direction: SortDirection;
  key: DataNetworkReportSortKey;
};

export enum DataNetworkReportType {
  PROVIDING_VISIBILITY = 'PROVIDING_VISIBILITY',
  RECEIVING_VISIBILITY = 'RECEIVING_VISIBILITY',
}

export type DataNetworkVisibilityReport = {
  __typename: 'DataNetworkVisibilityReport';
  companies: CountedDataNetworkVisibilityReportCompanyConnection;
  companyOverview: CompanyOverview;
  reportDownloadURI: Scalars['String']['output'];
  toursCountByStatus: ToursCountByStatus;
};

export type DataNetworkVisibilityReportToursCountByStatusArgs = {
  status: TourStatusFilter;
};

export type DataNetworkVisibilityReportCompany = {
  __typename: 'DataNetworkVisibilityReportCompany';
  assignmentPercentage: Maybe<Scalars['Float']['output']>;
  assignmentPercentageChange: Maybe<Scalars['Float']['output']>;
  companyId: Scalars['String']['output'];
  companyIsCarrier: Scalars['Boolean']['output'];
  companyIsShipper: Scalars['Boolean']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  latestPendingInvite: Maybe<CompanyInvite>;
  mainVisibilityIssueType: Maybe<VisibilityIssueType>;
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  onboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  platforms: Array<CompanyPlatform>;
  relationIdentifiers: Array<CompanyIdentifier>;
  tourCancelledCount: Scalars['Int']['output'];
  tourCompletedCount: Scalars['Int']['output'];
  tourTotalCount: Scalars['Int']['output'];
  tourUnassignedCount: Scalars['Int']['output'];
  tourUpcomingCount: Scalars['Int']['output'];
  tourWorkingCount: Scalars['Int']['output'];
  visibilityIndex: Maybe<Scalars['Float']['output']>;
  visibilityIndexChange: Maybe<Scalars['Float']['output']>;
};

export type DataNetworkVisibilityReportCompanyConnection = {
  __typename: 'DataNetworkVisibilityReportCompanyConnection';
  edges: Maybe<Array<Maybe<DataNetworkVisibilityReportCompanyEdge>>>;
  pageInfo: PageInfo;
};

export type DataNetworkVisibilityReportCompanyEdge = {
  __typename: 'DataNetworkVisibilityReportCompanyEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<DataNetworkVisibilityReportCompany>;
};

export type DataSharingConsent = {
  __typename: 'DataSharingConsent';
  history: Maybe<Array<DataSharingConsentHistory>>;
  tourStatusScope: ConsentState;
};

export type DataSharingConsentHistory = {
  __typename: 'DataSharingConsentHistory';
  consentSource: ConsentSource;
  tourStatusScope: ConsentState;
  updatedAt: Scalars['DateTime']['output'];
  updatedByUser: Maybe<UserProfile>;
};

export type DateTimeWithTimezone = {
  __typename: 'DateTimeWithTimezone';
  dateTime: Scalars['DateTime']['output'];
  timezone: Scalars['Timezone']['output'];
};

export enum DelayComparisonOperator {
  GTE = 'GTE',
  LTE = 'LTE',
}

export type DelayTimeRange = {
  comparison: DelayComparisonOperator;
  delayUnit: DelayTimerangeUnit;
  startInSeconds: Scalars['Int']['input'];
};

export type DelayTimeRangeOutput = {
  __typename: 'DelayTimeRangeOutput';
  comparison: DelayComparisonOperator;
  delayUnit: DelayTimerangeUnit;
  startInSeconds: Scalars['Int']['output'];
};

export enum DelayTimerangeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export type DeleteBulkInvitationSetInput = {
  bulkInvitationSetId: Scalars['String']['input'];
};

export type DeleteBulkInvitationSetResult = {
  __typename: 'DeleteBulkInvitationSetResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteCompanyIdentifierInput = {
  companyIdentifierId: Scalars['String']['input'];
};

export type DeleteCompanyIdentifierResult = {
  __typename: 'DeleteCompanyIdentifierResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteCompanyPlatformInput = {
  platform_id: Scalars['String']['input'];
};

export type DeleteCompanyRelationshipInput = {
  companyRelationshipId: Scalars['String']['input'];
};

export type DeleteCompanyVehicleGroupInput = {
  companyId: Scalars['String']['input'];
  companyVehicleGroupId: Scalars['String']['input'];
};

export type DeleteFilterBasedTransportNotificationInput = {
  notificationId: Scalars['String']['input'];
};

export type DeleteTransportNotificationInput = {
  notificationId: Scalars['String']['input'];
};

export type DeleteVehicleInput = {
  vehicle_id: Scalars['String']['input'];
};

export type DeleteVehicleTrackerInput = {
  trackerId: Scalars['String']['input'];
};

export type DeleteVehiclesInput = {
  companyId: Scalars['String']['input'];
  vehicleIds: Array<Scalars['String']['input']>;
};

export type DeleteWidgetInput = {
  dashboardId: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};

export type DeliveryStop = {
  __typename: 'DeliveryStop';
  stopId: Scalars['String']['output'];
};

export enum DemurrageType {
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
}

export type Document = {
  __typename: 'Document';
  associatedDeliveries: Array<TourDelivery>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  documentId: Scalars['String']['output'];
  documentType: DocumentType;
  documentUrl: Scalars['String']['output'];
  stopId: Maybe<Scalars['String']['output']>;
};

export enum DocumentType {
  BOL = 'BOL',
  Check = 'Check',
  DOTInspection = 'DOTInspection',
  DeliveryTicketReceiptOrder = 'DeliveryTicketReceiptOrder',
  Directions = 'Directions',
  FineTicket = 'FineTicket',
  FreightBill = 'FreightBill',
  FreightDamageDocumentation = 'FreightDamageDocumentation',
  FuelReceipt = 'FuelReceipt',
  Insurance = 'Insurance',
  Invoice = 'Invoice',
  Log = 'Log',
  LumperReceipt = 'LumperReceipt',
  Match = 'Match',
  ODPermit = 'ODPermit',
  Other = 'Other',
  POD = 'POD',
  PackingSlip = 'PackingSlip',
  ProofOfPerformance = 'ProofOfPerformance',
  RateConfirmation = 'RateConfirmation',
  ScaleReceipt = 'ScaleReceipt',
  SignatureCapture = 'SignatureCapture',
  TripSheet = 'TripSheet',
  TruckTrailerDamageDocumentation = 'TruckTrailerDamageDocumentation',
  WeightScaleCertificate = 'WeightScaleCertificate',
}

export type DummyMobileTourAllocationInput = {
  companyId: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
  vehicleTrackerId: Scalars['String']['input'];
};

export enum EtaType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  ML_MODEL = 'ML_MODEL',
  NOT_UPDATED = 'NOT_UPDATED',
  TRIMBLE_INTERNAL = 'TRIMBLE_INTERNAL',
}

export type EmailRecipient = {
  __typename: 'EmailRecipient';
  email: Scalars['String']['output'];
  locale: Scalars['String']['output'];
};

export type EmailRecipientInput = {
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export enum EmailType {
  GLOBAL_ALLOCATION_TOUR_FORWARDED = 'GLOBAL_ALLOCATION_TOUR_FORWARDED',
  TOUR_FORWARDED = 'TOUR_FORWARDED',
}

export type EmissionCost = {
  __typename: 'EmissionCost';
  name: Scalars['String']['output'];
  tankToWheel: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  wellToTank: Scalars['Float']['output'];
};

export enum EmissionsCalculationErrorCode {
  ECOTRANSIT_IATA_CODE_NOT_FOUND = 'ECOTRANSIT_IATA_CODE_NOT_FOUND',
  ECOTRANSIT_INDEX_OUT_OF_BOUNDS = 'ECOTRANSIT_INDEX_OUT_OF_BOUNDS',
  ECOTRANSIT_POSTAL_CODE_AND_COUNTRY_NOT_FOUND = 'ECOTRANSIT_POSTAL_CODE_AND_COUNTRY_NOT_FOUND',
  ECOTRANSIT_ROUTING_ERROR = 'ECOTRANSIT_ROUTING_ERROR',
  ECOTRANSIT_TOO_MANY_LEGS_RETURNED = 'ECOTRANSIT_TOO_MANY_LEGS_RETURNED',
  ECOTRANSIT_UIC_COUNTRY_AND_CODE_NOT_FOUND = 'ECOTRANSIT_UIC_COUNTRY_AND_CODE_NOT_FOUND',
  ECOTRANSIT_UN_LOCODE_NOT_FOUND = 'ECOTRANSIT_UN_LOCODE_NOT_FOUND',
  TOUR_DELIVERIES_MISSING_WEIGHT = 'TOUR_DELIVERIES_MISSING_WEIGHT',
  TOUR_DELIVERIES_NOT_ENOUGH_VALID = 'TOUR_DELIVERIES_NOT_ENOUGH_VALID',
  TOUR_DISTANCE_CALCULATION_FAILED = 'TOUR_DISTANCE_CALCULATION_FAILED',
  TOUR_HISTORY_POLYLINES_TOO_MANY_POINTS = 'TOUR_HISTORY_POLYLINES_TOO_MANY_POINTS',
  TOUR_HISTORY_POLYLINES_ZERO = 'TOUR_HISTORY_POLYLINES_ZERO',
  TOUR_INVALID_MAX_DEPARTURE = 'TOUR_INVALID_MAX_DEPARTURE',
  TOUR_INVALID_MIN_ARRIVAL = 'TOUR_INVALID_MIN_ARRIVAL',
  TOUR_MORE_THAN_ONE_VEHICLE = 'TOUR_MORE_THAN_ONE_VEHICLE',
  TOUR_STATUS_EVENT_TELEMETRY_MISMATCH = 'TOUR_STATUS_EVENT_TELEMETRY_MISMATCH',
  TOUR_STATUS_NOT_COMPLETED = 'TOUR_STATUS_NOT_COMPLETED',
  TOUR_STATUS_POSSIBLE_WRONG_TRUCK = 'TOUR_STATUS_POSSIBLE_WRONG_TRUCK',
  TOUR_STOPS_MISSING_COORDINATES = 'TOUR_STOPS_MISSING_COORDINATES',
  TOUR_STOPS_NOT_ENOUGH_VALID = 'TOUR_STOPS_NOT_ENOUGH_VALID',
  TOUR_STOPS_NOT_ENOUGH_VALID_UNIQUE = 'TOUR_STOPS_NOT_ENOUGH_VALID_UNIQUE',
  TOUR_STOPS_SOME_IGNORED = 'TOUR_STOPS_SOME_IGNORED',
  TOUR_VEHICLE_MISSING = 'TOUR_VEHICLE_MISSING',
  TOUR_WEIGHT_OUT_OF_ALLOWED_RANGE = 'TOUR_WEIGHT_OUT_OF_ALLOWED_RANGE',
}

export enum EmissionsCalculationErrorType {
  DISTANCE = 'DISTANCE',
  FUEL = 'FUEL',
  PLANNED = 'PLANNED',
  PRECALC = 'PRECALC',
  TELEMETRY = 'TELEMETRY',
}

export type EndpointUsageCounterByDate = {
  __typename: 'EndpointUsageCounterByDate';
  logDate: Scalars['DateTime']['output'];
  noOfCalls: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
};

export type EtaComparison = {
  __typename: 'EtaComparison';
  comparisonTerm: ComparisonTerm;
  comparisonUnit: ComparisonUnit;
  comparisonValueInSeconds: Scalars['Int']['output'];
  remainingTimeInSeconds: Maybe<Scalars['Int']['output']>;
  remainingTimeUnit: Maybe<ComparisonUnit>;
  stopId: Maybe<Scalars['String']['output']>;
};

export type EtaComparisonInput = {
  comparisonTerm: ComparisonTerm;
  comparisonUnit: ComparisonUnit;
  comparisonValueInSeconds: Scalars['Int']['input'];
  remainingTimeInSeconds?: InputMaybe<Scalars['Int']['input']>;
  remainingTimeUnit?: InputMaybe<ComparisonUnit>;
  stopId?: InputMaybe<Scalars['String']['input']>;
};

export type ExampleWidgetInput = {
  exampleWidgetType: ExampleWidgetType;
  translatedName: Scalars['String']['input'];
};

export enum ExampleWidgetType {
  DELAYED_TOWARDS_DELIVERY = 'DELAYED_TOWARDS_DELIVERY',
  ON_TIME_TOWARDS_DELIVERY = 'ON_TIME_TOWARDS_DELIVERY',
  ON_TIME_TOWARDS_PICKUP = 'ON_TIME_TOWARDS_PICKUP',
}

export type ExceptionFile = {
  __typename: 'ExceptionFile';
  description: Maybe<Scalars['String']['output']>;
  documentUrl: Scalars['String']['output'];
  platformDocumentId: Scalars['String']['output'];
};

export enum ExceptionType {
  AdditionalCarry = 'AdditionalCarry',
  DeliveryIssueCallCustomer = 'DeliveryIssueCallCustomer',
  DeliveryIssueNeedTechnician = 'DeliveryIssueNeedTechnician',
  Elevator = 'Elevator',
  Ferry = 'Ferry',
  JoybirdCallCustomer = 'JoybirdCallCustomer',
  JoybirdNeedTechnician = 'JoybirdNeedTechnician',
  JoybirdRefused = 'JoybirdRefused',
  Misc = 'Misc',
  NoDeliveryCustomerCancelled = 'NoDeliveryCustomerCancelled',
  NoDeliveryMissedDeliveryWindow = 'NoDeliveryMissedDeliveryWindow',
  NoDeliveryNotAtHome = 'NoDeliveryNotAtHome',
  NoDeliveryOther = 'NoDeliveryOther',
  RefusalCustomerPreference = 'RefusalCustomerPreference',
  RefusalDamage = 'RefusalDamage',
  RefusalDamageCover = 'RefusalDamageCover',
  RefusalDamageFrame = 'RefusalDamageFrame',
}

export type ExposeSixfoldGeneratedPlaceInput = {
  companyId: Scalars['String']['input'];
  placeId: Scalars['String']['input'];
};

export enum ExtendedComparisonOperator {
  ANY_TIME_RANGE = 'ANY_TIME_RANGE',
  GTE = 'GTE',
  LTE = 'LTE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  RANGE = 'RANGE',
}

export type ExternalCarrier = {
  __typename: 'ExternalCarrier';
  companyID: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
};

export type ExternalCompanyInvitation = {
  companyName: Scalars['String']['input'];
  identifierValues?: InputMaybe<Array<Scalars['String']['input']>>;
  recipients: Array<ExternalCompanyInvitationRecipient>;
};

export type ExternalCompanyInvitationEmailInput = {
  companyName: Scalars['String']['input'];
  identifierValues?: InputMaybe<Array<Scalars['String']['input']>>;
  recipients: Array<ExternalCompanyInvitationRecipient>;
};

export type ExternalCompanyInvitationLinkInput = {
  companyName: Scalars['String']['input'];
  identifierValues?: InputMaybe<Array<Scalars['String']['input']>>;
  locale: Scalars['String']['input'];
};

export type ExternalCompanyInvitationRecipient = {
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
};

export type ExternalCosts = {
  __typename: 'ExternalCosts';
  accidentCosts: Array<AccidentCost>;
  currency: Scalars['String']['output'];
  emissionCosts: Array<EmissionCost>;
  noiseCosts: Array<NoiseCost>;
  totalExternalCosts: Scalars['Float']['output'];
};

export enum ExternalEtaSource {
  CARRIER_API = 'CARRIER_API',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
  TTC_MOBILE = 'TTC_MOBILE',
}

export enum ExternalEventSource {
  CARRIER_API = 'CARRIER_API',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_API_TOUR_EVENTS = 'PLATFORM_API_TOUR_EVENTS',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export type ExternalTourDeliveryEvent = {
  __typename: 'ExternalTourDeliveryEvent';
  createdAt: Scalars['DateTime']['output'];
  delivery: Maybe<TourDelivery>;
  deliveryId: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  eventSource: ExternalEventSource;
  externalEventId: Maybe<Scalars['String']['output']>;
  externalEventQualifier: Scalars['String']['output'];
  externalEventSource: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  qualifier: ExternalTourDeliveryEventQualifier;
  sentByCompanyId: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export enum ExternalTourDeliveryEventQualifier {
  HEADING_TOWARDS_LOADING = 'HEADING_TOWARDS_LOADING',
  HEADING_TOWARDS_UNLOADING = 'HEADING_TOWARDS_UNLOADING',
  LOADING_ARRIVED = 'LOADING_ARRIVED',
  LOADING_COMPLETED = 'LOADING_COMPLETED',
  LOADING_DEPARTED = 'LOADING_DEPARTED',
  LOADING_STARTED = 'LOADING_STARTED',
  OTHER = 'OTHER',
  UNLOADING_ARRIVED = 'UNLOADING_ARRIVED',
  UNLOADING_COMPLETED = 'UNLOADING_COMPLETED',
  UNLOADING_DEPARTED = 'UNLOADING_DEPARTED',
  UNLOADING_STARTED = 'UNLOADING_STARTED',
}

export type ExternalTourEvent = {
  __typename: 'ExternalTourEvent';
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
  eventSource: ExternalEventSource;
  externalEventId: Maybe<Scalars['String']['output']>;
  externalEventQualifier: Scalars['String']['output'];
  externalEventSource: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  licensePlateNumber: Maybe<Scalars['String']['output']>;
  locationName: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  qualifier: ExternalTourEventQualifier;
  remoteId: Maybe<Scalars['String']['output']>;
  sentByCompanyId: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  vehicleType: Maybe<VehicleType>;
};

export enum ExternalTourEventQualifier {
  ARRIVED = 'ARRIVED',
  COMPLETED = 'COMPLETED',
  DEPARTED = 'DEPARTED',
  HEADING_TOWARDS = 'HEADING_TOWARDS',
  LOADED = 'LOADED',
  OTHER = 'OTHER',
  TRACKING_FINISHED = 'TRACKING_FINISHED',
  TRACKING_STARTED = 'TRACKING_STARTED',
  UNLOADED = 'UNLOADED',
}

export type ExternalTourStopEvent = {
  __typename: 'ExternalTourStopEvent';
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
  eventSource: ExternalEventSource;
  externalEventId: Maybe<Scalars['String']['output']>;
  externalEventQualifier: Scalars['String']['output'];
  externalEventSource: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  qualifier: ExternalTourStopEventQualifier;
  sentByCompanyId: Maybe<Scalars['String']['output']>;
  stopId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export enum ExternalTourStopEventQualifier {
  ARRIVED = 'ARRIVED',
  DEPARTED = 'DEPARTED',
  HEADING_TOWARDS = 'HEADING_TOWARDS',
  LOADING_COMPLETED = 'LOADING_COMPLETED',
  LOADING_STARTED = 'LOADING_STARTED',
  OTHER = 'OTHER',
}

export type FmsVehicleTracker = {
  __typename: 'FMSVehicleTracker';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  remoteId: Scalars['String']['output'];
  telematicsIntegration: Maybe<CompanyTelematicsIntegration>;
  trackerData: Maybe<Scalars['GraphQLJSON']['output']>;
  trackerType: VehicleTrackerType;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vehicleId: Scalars['String']['output'];
  vehicleTrackerId: Scalars['String']['output'];
};

export type FeatureFlagAuditLog = {
  __typename: 'FeatureFlagAuditLog';
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  featureFlagAuditLogId: Scalars['String']['output'];
  featureFlagId: Maybe<Scalars['String']['output']>;
  metadata: Array<Maybe<ActionMetadata>>;
  user: Maybe<UserProfile>;
};

export type FeatureFlagAuditLogConnection = {
  __typename: 'FeatureFlagAuditLogConnection';
  edges: Maybe<Array<Maybe<FeatureFlagAuditLogEdge>>>;
  pageInfo: PageInfo;
};

export type FeatureFlagAuditLogEdge = {
  __typename: 'FeatureFlagAuditLogEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<FeatureFlagAuditLog>;
};

export type FeatureFlagMetadata = {
  __typename: 'FeatureFlagMetadata';
  description: Scalars['String']['output'];
  display_name: Scalars['String']['output'];
  domains: Array<Maybe<Scalars['String']['output']>>;
  feature_flag_id: Scalars['String']['output'];
  usage: Maybe<Scalars['String']['output']>;
};

export type FeatureFlags = {
  __typename: 'FeatureFlags';
  feature_flags: Array<Maybe<Scalars['String']['output']>>;
};

export type FilterBasedTransportNotification = {
  __typename: 'FilterBasedTransportNotification';
  createdAt: Scalars['DateTime']['output'];
  createdByUserId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationId: Scalars['String']['output'];
  notificationTrigger: Maybe<NotificationTrigger>;
  recipients: Array<EmailRecipient>;
  recipientsV2: Array<Recipient>;
  transportFilter: Maybe<TourFilter>;
  transportFilterV2: Maybe<Scalars['GraphQLJSON']['output']>;
};

export enum FilterRoadStopType {
  loading = 'loading',
  other = 'other',
  unloading = 'unloading',
  waypoint = 'waypoint',
}

export enum FilterType {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
  ROAD = 'ROAD',
}

export type FlagCreatedMetadata = {
  __typename: 'FlagCreatedMetadata';
  action: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type FlagUpdatedMetadata = {
  __typename: 'FlagUpdatedMetadata';
  action: Scalars['String']['output'];
  newValue: Scalars['Boolean']['output'];
  oldValue: Maybe<Scalars['Boolean']['output']>;
};

export type ForwardTourInput = {
  forwardByCompanyId: Scalars['String']['input'];
  forwardToCompanyId: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export type FromPlatformData = {
  companyIdOnPlatform: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  userIdOnPlatform: Scalars['String']['input'];
};

export type FuelBasedEmissions = {
  __typename: 'FuelBasedEmissions';
  accumulatedFuelConsumptionLiters: Maybe<Scalars['Float']['output']>;
  co2TankToWheelTonnes: Maybe<Scalars['Float']['output']>;
  co2WellToTankTonnes: Maybe<Scalars['Float']['output']>;
  co2WellToWheelTonnes: Maybe<Scalars['Float']['output']>;
  emissionsIntensityGramPerTonneKms: Maybe<Scalars['Float']['output']>;
  fuelType: Maybe<Scalars['String']['output']>;
  transportActivityTonneKms: Maybe<Scalars['Float']['output']>;
};

export type GeoPosition = {
  __typename: 'GeoPosition';
  geocodingDirection: Maybe<Scalars['String']['output']>;
  geocoding_type: Maybe<GeocodingType>;
  lat: Maybe<Scalars['Float']['output']>;
  lng: Maybe<Scalars['Float']['output']>;
  matchLevel: Maybe<GeoPositionMatchLevel>;
  provider: Maybe<Scalars['String']['output']>;
};

export enum GeoPositionMatchLevel {
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  EXTPOSTCODE = 'EXTPOSTCODE',
  HOUSE_NUMBER = 'HOUSE_NUMBER',
  STREET = 'STREET',
}

export enum GeocodingType {
  AUTOMATICALLY_GEOCODED = 'AUTOMATICALLY_GEOCODED',
  CARRIER = 'CARRIER',
  MANUAL = 'MANUAL',
  SHIPPER = 'SHIPPER',
}

export type Geofence = GeofenceCircle | GeofencePolygon;

export type GeofenceCircle = {
  __typename: 'GeofenceCircle';
  origin: Position;
  radiusInMeters: Scalars['Float']['output'];
  type: GeofenceType;
};

export type GeofencePolygon = {
  __typename: 'GeofencePolygon';
  points: Array<Position>;
  type: GeofenceType;
};

export enum GeofenceType {
  CIRCLE = 'CIRCLE',
  POLYGON = 'POLYGON',
}

export type GeofenceZone = {
  __typename: 'GeofenceZone';
  geofence: Geofence;
  zone: GeofenceZoneType;
};

export enum GeofenceZoneType {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}

export enum GrantDirection {
  GRANTED_BY_PARTNERS = 'GRANTED_BY_PARTNERS',
  GRANTED_TO_PARTNERS = 'GRANTED_TO_PARTNERS',
}

export type HandlingUnit = {
  __typename: 'HandlingUnit';
  handlingUnitDescription: Scalars['String']['output'];
  handlingUnitId: Scalars['String']['output'];
  handlingUnitInstruction: Scalars['String']['output'];
  handlingUnitName: Scalars['String']['output'];
  handlingUnitType: HandlingUnitType;
};

export enum HandlingUnitType {
  BALE = 'BALE',
  BOX = 'BOX',
  CASE = 'CASE',
  CONTAINER = 'CONTAINER',
  CRATE = 'CRATE',
  DRUM = 'DRUM',
  OTHER = 'OTHER',
  PALLET = 'PALLET',
  REEL = 'REEL',
}

export type HasAllocationsFilterInput = {
  fromTime: Scalars['DateTime']['input'];
  toTime: Scalars['DateTime']['input'];
};

export enum HasRoleRoles {
  admin = 'admin',
  readonly = 'readonly',
  service = 'service',
}

export type HazmatType = {
  __typename: 'HazmatType';
  category: HazmatTypeCategory;
  class: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export enum HazmatTypeCategory {
  CORROSIVE = 'CORROSIVE',
  EXPLOSIVES = 'EXPLOSIVES',
  FLAMMABLE_LIQUIDS = 'FLAMMABLE_LIQUIDS',
  FLAMMABLE_SOLIDS = 'FLAMMABLE_SOLIDS',
  GASES = 'GASES',
  MISCELLANEOUS = 'MISCELLANEOUS',
  OXIDIZING_ORGANIC = 'OXIDIZING_ORGANIC',
  RADIOACTIVE = 'RADIOACTIVE',
  TOXIC_INFECTIOUS = 'TOXIC_INFECTIOUS',
}

export enum HistorySource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
  TTC_MOBILE = 'TTC_MOBILE',
}

export enum HistoryType {
  TOUR = 'TOUR',
  VEHICLE = 'VEHICLE',
}

export type IdentifierValueInput = {
  identifierId?: InputMaybe<Scalars['String']['input']>;
  identifierValue: Scalars['String']['input'];
};

export enum ImpactDaysComparison {
  GTE = 'GTE',
  LTE = 'LTE',
}

export type ImpactDaysFilter = {
  comparison: ImpactDaysComparison;
  startInSeconds: Scalars['Int']['input'];
};

export type ImpactDaysOutput = {
  __typename: 'ImpactDaysOutput';
  comparison: ImpactDaysComparison;
  startInSeconds: Scalars['Int']['output'];
};

export type IntegrationSyncResult = {
  __typename: 'IntegrationSyncResult';
  distinctVehicleNullIslandCount: Maybe<Scalars['Int']['output']>;
  distinctVehicleStatusFutureCount: Maybe<Scalars['Int']['output']>;
  distinctVehicleTemperatureFutureCount: Maybe<Scalars['Int']['output']>;
  endTimeAt: Scalars['DateTime']['output'];
  errorDescription: Maybe<Scalars['String']['output']>;
  errorType: Maybe<IntegrationSyncResultErrorType>;
  latestStatusTimestamp: Maybe<Scalars['DateTime']['output']>;
  latestTemperatureTimestamp: Maybe<Scalars['DateTime']['output']>;
  resultType: Maybe<IntegrationSyncResultResultType>;
  startTimeAt: Scalars['DateTime']['output'];
  statusCount: Maybe<Scalars['Int']['output']>;
  temperatureCount: Maybe<Scalars['Int']['output']>;
  vehicleAmbiguityCount: Maybe<Scalars['Int']['output']>;
  vehicleCount: Maybe<Scalars['Int']['output']>;
};

export enum IntegrationSyncResultErrorType {
  GLOBAL_TIMEOUT = 'GLOBAL_TIMEOUT',
  RATE_LIMIT = 'RATE_LIMIT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN = 'UNKNOWN',
}

export enum IntegrationSyncResultResultType {
  HISTORICAL_RESULT = 'HISTORICAL_RESULT',
  LATEST_RESULT = 'LATEST_RESULT',
}

export type InvitationHistoryItem = {
  __typename: 'InvitationHistoryItem';
  inviteSentByLabel: Maybe<Scalars['String']['output']>;
  performedByEmail: Maybe<Scalars['String']['output']>;
  recipients: Maybe<Array<InvitationHistoryItemRecipient>>;
  time: Scalars['String']['output'];
  type: Maybe<InvitationHistoryLogItemType>;
};

export type InvitationHistoryItemRecipient = {
  __typename: 'InvitationHistoryItemRecipient';
  email: Scalars['String']['output'];
  locale: Scalars['String']['output'];
};

export enum InvitationHistoryLogItemType {
  ACCEPTED = 'ACCEPTED',
  EMAIL_SENT = 'EMAIL_SENT',
  LINK_CREATED = 'LINK_CREATED',
  WITHDRAWN = 'WITHDRAWN',
}

export type ItemCountByStatus = {
  __typename: 'ItemCountByStatus';
  count: Scalars['Int']['output'];
  status: BulkInvitationSetItemStatus;
};

export enum LstfTimeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type LastSignalTimestampFilter = {
  __typename: 'LastSignalTimestampFilter';
  comparison: ComparisonOperatorExtended;
  endInSeconds: Maybe<Scalars['Int']['output']>;
  startInSeconds: Maybe<Scalars['Int']['output']>;
  unit: Maybe<LstfTimeUnit>;
};

export type LastSignalTimestampFilterInput = {
  comparison: ComparisonOperatorExtended;
  endInSeconds?: InputMaybe<Scalars['Int']['input']>;
  startInSeconds?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<LstfTimeUnit>;
};

export type LatestVehicleTemperature = {
  __typename: 'LatestVehicleTemperature';
  reeferId: Scalars['String']['output'];
  sensorReadings: Array<VehicleTemperatureSensorReading>;
  timestamp: Scalars['DateTime']['output'];
  visibilitySource: VehicleTemperatureVisibilitySource;
};

export type Leg = {
  __typename: 'Leg';
  carriageParameters: CarriageParameters;
  distanceKM: Maybe<Scalars['Float']['output']>;
  emissions: Array<LegEmission>;
  endLocation: GeoPosition;
  externalCosts: Maybe<ExternalCosts>;
  routePolyline: Maybe<Scalars['String']['output']>;
  startLocation: GeoPosition;
  transportMode: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LegEmission = {
  __typename: 'LegEmission';
  emissionMode: Scalars['String']['output'];
  tankToWheel: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  wellToTank: Scalars['Float']['output'];
};

export type LicensePlateLookupInput = {
  companyId: Scalars['String']['input'];
  licensePlates: Array<Scalars['String']['input']>;
};

export type LicensePlateLookupResult = {
  __typename: 'LicensePlateLookupResult';
  licensePlate: Scalars['String']['output'];
  matches: Array<LicensePlateLookupVehicle>;
};

export type LicensePlateLookupResultSet = {
  __typename: 'LicensePlateLookupResultSet';
  result: Array<LicensePlateLookupResult>;
};

export type LicensePlateLookupVehicle = {
  __typename: 'LicensePlateLookupVehicle';
  createdAt: Scalars['DateTime']['output'];
  lastStatusTimestamp: Maybe<Scalars['DateTime']['output']>;
  licensePlate: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicleId: Scalars['String']['output'];
};

export type LightdashEmbeddings = {
  __typename: 'LightdashEmbeddings';
  allocationSourceAndSuccessUrl: Scalars['String']['output'];
};

export enum LinearUnit {
  FEET = 'FEET',
  INCHES = 'INCHES',
  KILOMETERS = 'KILOMETERS',
  METERS = 'METERS',
  MILES = 'MILES',
}

export enum LoadType {
  BULK = 'BULK',
  COURIER_EXPRESS_PARCEL = 'COURIER_EXPRESS_PARCEL',
  CUSTOMER_PICKUP = 'CUSTOMER_PICKUP',
  FULL_CONTAINER_LOAD = 'FULL_CONTAINER_LOAD',
  FULL_TRUCKLOAD = 'FULL_TRUCKLOAD',
  LESS_THAN_CONTAINER_LOAD = 'LESS_THAN_CONTAINER_LOAD',
  LESS_THAN_TRUCKLOAD = 'LESS_THAN_TRUCKLOAD',
  LESS_THAN_TRUCKLOAD_GROUPAGE = 'LESS_THAN_TRUCKLOAD_GROUPAGE',
}

export type LocationIdentifier = {
  __typename: 'LocationIdentifier';
  type: LocationIdentifierType;
  value: Scalars['String']['output'];
};

export enum LocationIdentifierType {
  UN_LOCODE = 'UN_LOCODE',
}

export type LocationMilestone = {
  delayTime?: InputMaybe<MilestoneDelay>;
  milestoneQualifier: Scalars['String']['input'];
  timelineEvents?: InputMaybe<Array<LocationMilestoneTimelineEvent>>;
};

export type LocationMilestoneOutput = {
  __typename: 'LocationMilestoneOutput';
  delayTime: Maybe<MilestoneDelayOutput>;
  milestoneQualifier: Scalars['String']['output'];
  timelineEvents: Maybe<Array<LocationMilestoneTimelineEventOutput>>;
};

export type LocationMilestoneTimelineEvent = {
  criteria: Scalars['String']['input'];
  timerange?: InputMaybe<TimeRangeSugarDate>;
};

export type LocationMilestoneTimelineEventOutput = {
  __typename: 'LocationMilestoneTimelineEventOutput';
  criteria: Scalars['String']['output'];
  timerange: Maybe<TimeRangeSugarDateOutput>;
};

export enum LocationType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  DESTINATION = 'DESTINATION',
  EMPTY_CONTAINER_GATE_IN = 'EMPTY_CONTAINER_GATE_IN',
  EMPTY_CONTAINER_GATE_OUT = 'EMPTY_CONTAINER_GATE_OUT',
  EMPTY_POD = 'EMPTY_POD',
  EMPTY_POL = 'EMPTY_POL',
  GATEWAY = 'GATEWAY',
  IN_TRANSIT_DESTINATION = 'IN_TRANSIT_DESTINATION',
  IN_TRANSIT_ORIGIN = 'IN_TRANSIT_ORIGIN',
  LAYOVER = 'LAYOVER',
  LOADING = 'LOADING',
  ORIGIN = 'ORIGIN',
  OTHER = 'OTHER',
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
  TRANSSHIPMENT = 'TRANSSHIPMENT',
  TRANSSHIPMENT_PORT = 'TRANSSHIPMENT_PORT',
  UNLOADING = 'UNLOADING',
}

export type LocationsAndMilestones = {
  __typename: 'LocationsAndMilestones';
  locations: Array<MilestoneLocation>;
  vehicles: Array<VehicleData>;
};

export type LoginUser = {
  __typename: 'LoginUser';
  companies: Array<Company>;
  email: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  user_id: Scalars['String']['output'];
};

export enum MainMode {
  MODE_AIR = 'MODE_AIR',
  MODE_INLAND_WATER = 'MODE_INLAND_WATER',
  MODE_INTERMODAL = 'MODE_INTERMODAL',
  MODE_RAIL = 'MODE_RAIL',
  MODE_ROAD = 'MODE_ROAD',
  MODE_SEA = 'MODE_SEA',
}

export type Milestone = {
  __typename: 'Milestone';
  actualTimeOfArrival: Maybe<DateTimeWithTimezone>;
  actualTimeOfArrivalOverrides: Maybe<ActualTimeOverrides>;
  actualTimeOfArrivalSource: Maybe<Scalars['String']['output']>;
  estimatedTimeOfArrival: Maybe<DateTimeWithTimezone>;
  estimatedTimeOfArrivalSource: Maybe<Scalars['String']['output']>;
  milestone: Scalars['String']['output'];
  packageCount: Maybe<Scalars['String']['output']>;
  qualifier: Maybe<MilestoneQualifier>;
  scheduledTimeOfArrival: Maybe<DateTimeWithTimezone>;
  scheduledTimeOfArrivalSource: Maybe<Scalars['String']['output']>;
  vehicleId: Maybe<Scalars['String']['output']>;
};

export type MilestoneDelay = {
  anyTimerangeCriteria?: InputMaybe<Scalars['Boolean']['input']>;
  criteria: Scalars['String']['input'];
  timerange?: InputMaybe<DelayTimeRange>;
};

export type MilestoneDelayOutput = {
  __typename: 'MilestoneDelayOutput';
  anyTimerangeCriteria: Maybe<Scalars['Boolean']['output']>;
  criteria: Scalars['String']['output'];
  timerange: Maybe<DelayTimeRangeOutput>;
};

export type MilestoneLocation = {
  __typename: 'MilestoneLocation';
  country: Maybe<Scalars['String']['output']>;
  iataCode: Maybe<Scalars['String']['output']>;
  locationCode: Maybe<Scalars['String']['output']>;
  locationTransportMode: Maybe<MilestoneTransportModeType>;
  milestones: Array<Milestone>;
  name: Scalars['String']['output'];
  position: Maybe<Position>;
  type: Maybe<LocationType>;
  voyageId: Maybe<Scalars['String']['output']>;
};

export enum MilestoneQualifier {
  ARRIVAL = 'ARRIVAL',
  ARRIVED = 'ARRIVED',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  COLLECTED = 'COLLECTED',
  CONTAINER_DISCHARGED = 'CONTAINER_DISCHARGED',
  CONTAINER_LOADED = 'CONTAINER_LOADED',
  DELIVERED = 'DELIVERED',
  DEPARTED = 'DEPARTED',
  DEPARTURE = 'DEPARTURE',
  DOCUMENTS_DELIVERED = 'DOCUMENTS_DELIVERED',
  EMPTY_CONTAINER_GATE_IN = 'EMPTY_CONTAINER_GATE_IN',
  EMPTY_CONTAINER_GATE_OUT = 'EMPTY_CONTAINER_GATE_OUT',
  EMPTY_CONTAINER_PICKUP = 'EMPTY_CONTAINER_PICKUP',
  EMPTY_RETURN = 'EMPTY_RETURN',
  FLIGHT_ARRIVED = 'FLIGHT_ARRIVED',
  FLIGHT_DEPARTED = 'FLIGHT_DEPARTED',
  FULL_CONTAINER_GATE_IN = 'FULL_CONTAINER_GATE_IN',
  FULL_CONTAINER_GATE_OUT = 'FULL_CONTAINER_GATE_OUT',
  GATE_IN = 'GATE_IN',
  GATE_IN_EMPTY = 'GATE_IN_EMPTY',
  GATE_OUT = 'GATE_OUT',
  GATE_OUT_EMPTY = 'GATE_OUT_EMPTY',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  MANIFESTED = 'MANIFESTED',
  OTHER = 'OTHER',
  PICKUP = 'PICKUP',
  RECEIVED_FROM_FLIGHT = 'RECEIVED_FROM_FLIGHT',
  RECEIVED_FROM_SHIPPER = 'RECEIVED_FROM_SHIPPER',
  UNLOADED = 'UNLOADED',
  UNLOADING = 'UNLOADING',
  VESSEL_ARRIVED = 'VESSEL_ARRIVED',
  VESSEL_DEPARTED = 'VESSEL_DEPARTED',
}

export enum MilestoneTransportModeType {
  AIR = 'AIR',
  BARGE = 'BARGE',
  FEEDER = 'FEEDER',
  INLANDWATERWAY = 'INLANDWATERWAY',
  RAIL = 'RAIL',
  ROAD = 'ROAD',
  SEA = 'SEA',
  UNKNOWN = 'UNKNOWN',
}

export type MobileVehicleTracker = {
  __typename: 'MobileVehicleTracker';
  alias: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  remoteId: Maybe<Scalars['String']['output']>;
  trackerData: Maybe<Scalars['GraphQLJSON']['output']>;
  trackerType: VehicleTrackerType;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vehicleId: Scalars['String']['output'];
  vehicleTrackerId: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  abortTour: Maybe<Tour>;
  addCompanyTag: Scalars['Boolean']['output'];
  addCredentialsToUser: Maybe<Credentials>;
  addOrUpdateDataSharingConsent: Maybe<Scalars['Boolean']['output']>;
  addTourTag: Scalars['Boolean']['output'];
  addUserToCompany: Maybe<UserInCompanyProfile>;
  addVehiclesToCompanyVehicleGroup: Maybe<Scalars['Boolean']['output']>;
  allocateDummyMobileToTour: Maybe<Tour>;
  allocateVehiclesToTour: Maybe<Tour>;
  assignUserToTour: Maybe<TourAssignment>;
  changeTourVehicleAllocations: Maybe<Tour>;
  changeUserMetadataInCompany: Maybe<UserInCompanyProfile>;
  changeUserRoleInCompany: Maybe<UserInCompanyProfile>;
  completeCompanySignup: Maybe<CompanySignupCompleted>;
  copyDashboard: Maybe<Dashboard>;
  createAllVehicleGrants: Maybe<Scalars['Boolean']['output']>;
  createBulkInvitationSet: CreateBulkInvitationSetResult;
  createCompany: Maybe<Company>;
  createCompanyIdentifier: Maybe<CreateCompanyIdentifierResult>;
  createCompanyInvitationEmail: Maybe<CreateCompanyInvitationEmailResult>;
  createCompanyInvitationLink: Maybe<CreateCompanyInvitationLinkResult>;
  createCompanyPlace: Maybe<CompanyPlace>;
  createCompanyPlatform: Maybe<CompanyPlatform>;
  createCompanyRelationship: Maybe<CompanyRelationship>;
  createCompanyTourFilter: Maybe<CompanyTourFilter>;
  createCompanyTourFilterSharingToken: Maybe<Scalars['String']['output']>;
  createCompanyTransportNetworkFilter: Maybe<CompanyTransportNetworkFilter>;
  createCompanyVehicleGroup: Maybe<CompanyVehicleGroup>;
  createDashboard: Maybe<Dashboard>;
  createExampleDashboard: Maybe<Dashboard>;
  createNote: Maybe<Note>;
  createOrUpdateFilterBasedTransportNotification: Scalars['String']['output'];
  createOrUpdateRelationIdentifiers: Maybe<CreateOrUpdateRelationIdentifiersResult>;
  createOrUpdateTransportNotification: Scalars['String']['output'];
  createPlatformSupportTicket: Maybe<Scalars['Boolean']['output']>;
  createTelematicsIntegration: Maybe<TelematicsIntegration>;
  createTour: Maybe<Tour>;
  createTourStopSharingToken: Maybe<TourStop>;
  createUser: Maybe<User>;
  createUserFilter: Maybe<UserFilter>;
  createVehicleMobileAppTracker: MobileVehicleTracker;
  createVehicles: Scalars['Boolean']['output'];
  createVisibilitySolutionsTicket: Maybe<Scalars['Boolean']['output']>;
  createWidget: Maybe<Widget>;
  deleteBulkInvitationSet: DeleteBulkInvitationSetResult;
  deleteCompanyIdentifier: Maybe<DeleteCompanyIdentifierResult>;
  deleteCompanyPlace: Maybe<Scalars['Boolean']['output']>;
  deleteCompanyPlatform: Scalars['Boolean']['output'];
  deleteCompanyRelationship: Maybe<Scalars['Boolean']['output']>;
  deleteCompanyTourFilter: Maybe<Scalars['Boolean']['output']>;
  deleteCompanyTourFilterSharingToken: Maybe<Scalars['Boolean']['output']>;
  deleteCompanyTransportNetworkFilter: Maybe<Scalars['Boolean']['output']>;
  deleteCompanyVehicleGroup: Maybe<Scalars['Boolean']['output']>;
  deleteDashboard: Maybe<Scalars['Boolean']['output']>;
  deleteFilterBasedTransportNotification: Scalars['Boolean']['output'];
  deleteTelematicsIntegration: Scalars['Boolean']['output'];
  deleteTelematicsIntegrationSyncState: Scalars['Boolean']['output'];
  deleteTour: Maybe<Scalars['Boolean']['output']>;
  deleteTourStopSharingToken: Maybe<TourStop>;
  deleteTransportNotification: Scalars['Boolean']['output'];
  deleteUser: Maybe<Scalars['Boolean']['output']>;
  deleteUserAssignment: Maybe<Scalars['Boolean']['output']>;
  deleteUserLoginToken: Scalars['Boolean']['output'];
  deleteVehicle: Scalars['Boolean']['output'];
  deleteVehicleTracker: Scalars['Boolean']['output'];
  deleteVehicles: Scalars['Boolean']['output'];
  deleteWidget: Maybe<Scalars['Boolean']['output']>;
  error: Maybe<Scalars['Boolean']['output']>;
  exposeSixfoldGeneratedPlace: Maybe<CompanyPlace>;
  forwardTour: Maybe<Tour>;
  ping: Maybe<Scalars['String']['output']>;
  pingUserUiActivity: Scalars['Boolean']['output'];
  recalculateTour: Maybe<Tour>;
  removeCompanyTag: Scalars['Boolean']['output'];
  removeCredentialsFromUser: Maybe<Scalars['Boolean']['output']>;
  removeTourTag: Scalars['Boolean']['output'];
  removeUserFilter: Maybe<Scalars['Boolean']['output']>;
  removeUserFromCompany: Maybe<Scalars['Boolean']['output']>;
  removeVehiclesFromCompanyVehicleGroup: Maybe<Scalars['Boolean']['output']>;
  resendCompanyInvitation: Maybe<CreateCompanyInvitationsResult>;
  revokeAllVehicleGrants: Maybe<Scalars['Boolean']['output']>;
  saveCustomerFeedback: Maybe<Scalars['Boolean']['output']>;
  sendBulkInvitationSet: Maybe<SendBulkInvitationSetResult>;
  setCompanyVATNumbers: Maybe<CompanyVatNumberChangeResult>;
  setVehicleGrantVisibility: Maybe<Scalars['Boolean']['output']>;
  signupPageOpened: Maybe<Scalars['Boolean']['output']>;
  signupPlatformCompany: Maybe<CompanySignupCompleted>;
  submitUnsupportedTelematicsProviderDetails: Scalars['Boolean']['output'];
  trackTokenView: Scalars['Boolean']['output'];
  unforwardTour: Maybe<Tour>;
  updateBulkInvitationSet: UpdateBulkInvitationSetResult;
  updateCompany: Maybe<Company>;
  updateCompanyConfiguration: Maybe<Company>;
  /**
   * Optimised version of updateCompany for flipping a single feature flag
   * useful for internal processes that want to control feature flags
   * If value is null, removes the feature flag (which is nearly always equivalent to setting it to false)
   * Returns true if the flag has been updated or error otherwise
   */
  updateCompanyFeatureFlag: Maybe<Scalars['Boolean']['output']>;
  updateCompanyPlace: Maybe<CompanyPlace>;
  updateCompanyRelationshipOnboardingStatus: Maybe<Scalars['Boolean']['output']>;
  updateCompanyRelationshipSignupStatus: Maybe<Scalars['Boolean']['output']>;
  updateCompanySettings: Maybe<Company>;
  updateCompanyVehicleGroup: Maybe<CompanyVehicleGroup>;
  updateDashboard: Maybe<Dashboard>;
  updateInviteCompanyName: Maybe<Scalars['Boolean']['output']>;
  updateTelematicsIntegration: Maybe<TelematicsIntegration>;
  updateTelematicsIntegrationConnectionParameters: Maybe<CompanyTelematicsIntegration>;
  updateTelematicsProvider: Maybe<TelematicsProvider>;
  updateTour: Maybe<Tour>;
  updateUnifiedCompanyOnboardingStatus: Maybe<Scalars['Boolean']['output']>;
  updateUnifiedCompanySignupStatus: Maybe<Scalars['Boolean']['output']>;
  updateUser: Maybe<User>;
  updateUserInCompanyConfiguration: Maybe<UserInCompany>;
  updateUserNotificationConfig: Maybe<UpdateUserNotificationConfigResponse>;
  updateViewerProfile: Maybe<User>;
  updateWidget: Maybe<Widget>;
  validateTelematicsIntegration: Maybe<TelematicsValidation>;
  validateTelematicsIntegrationConnectionParametersUpdate: Maybe<TelematicsValidation>;
  withdrawCompanyInvitation: WithdrawCompanyInvitationResult;
};

export type MutationAbortTourArgs = {
  tour_id: Scalars['Int']['input'];
};

export type MutationAddCompanyTagArgs = {
  input: CompanyTagInput;
};

export type MutationAddCredentialsToUserArgs = {
  input: AddCredentialsToUserInput;
};

export type MutationAddOrUpdateDataSharingConsentArgs = {
  input: AddOrUpdateConsentInput;
};

export type MutationAddTourTagArgs = {
  tag_name: Scalars['String']['input'];
  tour_id: Scalars['String']['input'];
};

export type MutationAddUserToCompanyArgs = {
  input: AddUserToCompanyInput;
};

export type MutationAddVehiclesToCompanyVehicleGroupArgs = {
  input: AddVehiclesToCompanyVehicleGroupInput;
};

export type MutationAllocateDummyMobileToTourArgs = {
  input: DummyMobileTourAllocationInput;
};

export type MutationAllocateVehiclesToTourArgs = {
  input: TourAllocationInput;
};

export type MutationAssignUserToTourArgs = {
  input: AssignUserToTourInput;
};

export type MutationChangeTourVehicleAllocationsArgs = {
  input: TourVehicleAllocationsInput;
};

export type MutationChangeUserMetadataInCompanyArgs = {
  input: ChangeUserMetadataInCompanyInput;
};

export type MutationChangeUserRoleInCompanyArgs = {
  input: ChangeUserRoleInCompanyInput;
};

export type MutationCopyDashboardArgs = {
  input?: InputMaybe<CopyDashboardInput>;
};

export type MutationCreateAllVehicleGrantsArgs = {
  input: CreateVehicleGrantsInput;
};

export type MutationCreateBulkInvitationSetArgs = {
  companyId: Scalars['String']['input'];
  input: CreateBulkInvitationSetInput;
};

export type MutationCreateCompanyArgs = {
  input?: InputMaybe<CreateCompanyInput>;
};

export type MutationCreateCompanyIdentifierArgs = {
  input: CreateCompanyIdentifierInput;
};

export type MutationCreateCompanyInvitationEmailArgs = {
  companyId: Scalars['String']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  input: CreateCompanyInvitationEmailInput;
};

export type MutationCreateCompanyInvitationLinkArgs = {
  companyId: Scalars['String']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  input: CreateCompanyInvitationLinkInput;
};

export type MutationCreateCompanyPlaceArgs = {
  input: CreateCompanyPlaceInput;
};

export type MutationCreateCompanyPlatformArgs = {
  company_id: Scalars['String']['input'];
  input?: InputMaybe<CompanyPlatformInput>;
};

export type MutationCreateCompanyRelationshipArgs = {
  input: CreateCompanyRelationshipInput;
};

export type MutationCreateCompanyTourFilterArgs = {
  input: CreateCompanyTourFilterInput;
};

export type MutationCreateCompanyTourFilterSharingTokenArgs = {
  companyTourFilterId: Scalars['String']['input'];
};

export type MutationCreateCompanyTransportNetworkFilterArgs = {
  input: CreateCompanyTransportNetworkFilterInput;
};

export type MutationCreateCompanyVehicleGroupArgs = {
  input: CreateCompanyVehicleGroupInput;
};

export type MutationCreateDashboardArgs = {
  input?: InputMaybe<CreateDashboardInput>;
};

export type MutationCreateExampleDashboardArgs = {
  input?: InputMaybe<CreateExampleDashboardInput>;
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateOrUpdateFilterBasedTransportNotificationArgs = {
  input: CreateOrUpdateFilterBasedTransportNotificationInput;
};

export type MutationCreateOrUpdateRelationIdentifiersArgs = {
  input: CreateOrUpdateRelationIdentifiersInput;
};

export type MutationCreateOrUpdateTransportNotificationArgs = {
  input: CreateOrUpdateTransportNotificationInput;
};

export type MutationCreatePlatformSupportTicketArgs = {
  input: CreatePlatformSupportTicketInput;
};

export type MutationCreateTelematicsIntegrationArgs = {
  input: CreateTelematicsIntegrationInput;
};

export type MutationCreateTourArgs = {
  input: TourCreationInput;
};

export type MutationCreateTourStopSharingTokenArgs = {
  company_id: Scalars['String']['input'];
  stop_id: Scalars['String']['input'];
  tour_id: Scalars['String']['input'];
};

export type MutationCreateUserArgs = {
  auth_service: Scalars['String']['input'];
  input?: InputMaybe<UserAttributesInput>;
};

export type MutationCreateUserFilterArgs = {
  input: CreateUserFilterInput;
};

export type MutationCreateVehicleMobileAppTrackerArgs = {
  input: CreateVehicleMobileAppTrackerInput;
};

export type MutationCreateVehiclesArgs = {
  input: CreateVehiclesInput;
};

export type MutationCreateVisibilitySolutionsTicketArgs = {
  input: CreateVisibilitySolutionsTicketInput;
};

export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};

export type MutationDeleteBulkInvitationSetArgs = {
  companyId: Scalars['String']['input'];
  input: DeleteBulkInvitationSetInput;
};

export type MutationDeleteCompanyIdentifierArgs = {
  input: DeleteCompanyIdentifierInput;
};

export type MutationDeleteCompanyPlaceArgs = {
  placeId: Scalars['String']['input'];
};

export type MutationDeleteCompanyPlatformArgs = {
  company_id: Scalars['String']['input'];
  input?: InputMaybe<DeleteCompanyPlatformInput>;
};

export type MutationDeleteCompanyRelationshipArgs = {
  input: DeleteCompanyRelationshipInput;
};

export type MutationDeleteCompanyTourFilterArgs = {
  companyTourFilterId: Scalars['String']['input'];
};

export type MutationDeleteCompanyTourFilterSharingTokenArgs = {
  companyTourFilterId: Scalars['String']['input'];
};

export type MutationDeleteCompanyTransportNetworkFilterArgs = {
  companyTransportNetworkFilterId: Scalars['String']['input'];
};

export type MutationDeleteCompanyVehicleGroupArgs = {
  input: DeleteCompanyVehicleGroupInput;
};

export type MutationDeleteDashboardArgs = {
  dashboardId: Scalars['String']['input'];
};

export type MutationDeleteFilterBasedTransportNotificationArgs = {
  input: DeleteFilterBasedTransportNotificationInput;
};

export type MutationDeleteTelematicsIntegrationArgs = {
  telematics_integration_id: Scalars['String']['input'];
};

export type MutationDeleteTelematicsIntegrationSyncStateArgs = {
  telematics_integration_id: Scalars['String']['input'];
};

export type MutationDeleteTourArgs = {
  input: TourDeletionInput;
};

export type MutationDeleteTourStopSharingTokenArgs = {
  company_id: Scalars['String']['input'];
  stop_id: Scalars['String']['input'];
  tour_id: Scalars['String']['input'];
};

export type MutationDeleteTransportNotificationArgs = {
  input: DeleteTransportNotificationInput;
};

export type MutationDeleteUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationDeleteUserAssignmentArgs = {
  companyId: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export type MutationDeleteVehicleArgs = {
  input: DeleteVehicleInput;
};

export type MutationDeleteVehicleTrackerArgs = {
  input: DeleteVehicleTrackerInput;
};

export type MutationDeleteVehiclesArgs = {
  input: DeleteVehiclesInput;
};

export type MutationDeleteWidgetArgs = {
  input: DeleteWidgetInput;
};

export type MutationErrorArgs = {
  message: Scalars['String']['input'];
};

export type MutationExposeSixfoldGeneratedPlaceArgs = {
  input: ExposeSixfoldGeneratedPlaceInput;
};

export type MutationForwardTourArgs = {
  input: ForwardTourInput;
};

export type MutationPingArgs = {
  input: Scalars['String']['input'];
};

export type MutationRecalculateTourArgs = {
  tour_id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyTagArgs = {
  input: CompanyTagInput;
};

export type MutationRemoveCredentialsFromUserArgs = {
  input: RemoveCredentialsFromUserInput;
};

export type MutationRemoveTourTagArgs = {
  tag_name: Scalars['String']['input'];
  tour_id: Scalars['String']['input'];
};

export type MutationRemoveUserFilterArgs = {
  userFilterId: Scalars['String']['input'];
};

export type MutationRemoveUserFromCompanyArgs = {
  input: RemoveUserFromCompanyInput;
};

export type MutationRemoveVehiclesFromCompanyVehicleGroupArgs = {
  input: RemoveVehiclesFromCompanyVehicleGroupInput;
};

export type MutationResendCompanyInvitationArgs = {
  companyId: Scalars['String']['input'];
  input?: InputMaybe<ResendCompanyInvitationInput>;
};

export type MutationRevokeAllVehicleGrantsArgs = {
  input: RevokeVehicleGrantsInput;
};

export type MutationSaveCustomerFeedbackArgs = {
  input: SaveCustomerFeedbackInput;
};

export type MutationSendBulkInvitationSetArgs = {
  companyId: Scalars['String']['input'];
  input?: InputMaybe<SendBulkInvitationSetInput>;
};

export type MutationSetCompanyVatNumbersArgs = {
  company_id: Scalars['String']['input'];
  input?: InputMaybe<Array<InputMaybe<CompanyVatNumberInput>>>;
  retainMissingCountryCodes: Scalars['Boolean']['input'];
};

export type MutationSetVehicleGrantVisibilityArgs = {
  input: VehicleGrantVisibilityInput;
};

export type MutationSignupPageOpenedArgs = {
  input: SignupPageOpenedInput;
};

export type MutationSignupPlatformCompanyArgs = {
  input?: InputMaybe<SignupPlatformCompanyInput>;
};

export type MutationSubmitUnsupportedTelematicsProviderDetailsArgs = {
  input: SubmitUnsupportedTelematicsProviderDetailsInput;
};

export type MutationUnforwardTourArgs = {
  input: UnforwardTourInput;
};

export type MutationUpdateBulkInvitationSetArgs = {
  companyId: Scalars['String']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  input: UpdateBulkInvitationSetInput;
};

export type MutationUpdateCompanyArgs = {
  company_id: Scalars['String']['input'];
  input?: InputMaybe<UpdateCompanyInput>;
};

export type MutationUpdateCompanyConfigurationArgs = {
  input: UpdateCompanyConfigurationInput;
};

export type MutationUpdateCompanyFeatureFlagArgs = {
  companyId: Scalars['String']['input'];
  featureFlag: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateCompanyPlaceArgs = {
  input: UpdateCompanyPlaceInput;
};

export type MutationUpdateCompanyRelationshipOnboardingStatusArgs = {
  input: UpdateCompanyRelationshipOnboardingStatusInput;
};

export type MutationUpdateCompanyRelationshipSignupStatusArgs = {
  input: UpdateCompanyRelationshipSignupStatusInput;
};

export type MutationUpdateCompanySettingsArgs = {
  input: UpdateCompanySettingsInput;
};

export type MutationUpdateCompanyVehicleGroupArgs = {
  input: UpdateCompanyVehicleGroupInput;
};

export type MutationUpdateDashboardArgs = {
  input?: InputMaybe<UpdateDashboardInput>;
};

export type MutationUpdateInviteCompanyNameArgs = {
  input: UpdateInviteCompanyNameInput;
};

export type MutationUpdateTelematicsIntegrationArgs = {
  input: UpdateTelematicsIntegrationInput;
};

export type MutationUpdateTelematicsIntegrationConnectionParametersArgs = {
  input: UpdateTelematicsIntegrationConnectionParametersInput;
};

export type MutationUpdateTelematicsProviderArgs = {
  input: UpdateTelematicsProviderInput;
};

export type MutationUpdateTourArgs = {
  input: TourUpdateInput;
};

export type MutationUpdateUnifiedCompanyOnboardingStatusArgs = {
  companyId: Scalars['String']['input'];
  input: UnifiedCompanyOnboardingStatusUpdateInput;
};

export type MutationUpdateUnifiedCompanySignupStatusArgs = {
  companyId: Scalars['String']['input'];
  input: UnifiedCompanySignupStatusUpdateInput;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserAttributesInput>;
  user_id: Scalars['String']['input'];
};

export type MutationUpdateUserInCompanyConfigurationArgs = {
  input: UpdateUserInCompanyConfigurationInput;
};

export type MutationUpdateUserNotificationConfigArgs = {
  input: UpdateUserNotificationConfigInput;
};

export type MutationUpdateViewerProfileArgs = {
  input: UpdateViewerProfileInput;
};

export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};

export type MutationValidateTelematicsIntegrationArgs = {
  input: ValidateTelematicsIntegrationInput;
};

export type MutationValidateTelematicsIntegrationConnectionParametersUpdateArgs = {
  input: ValidateTelematicsIntegrationConnectionParametersUpdateInput;
};

export type MutationWithdrawCompanyInvitationArgs = {
  companyId: Scalars['String']['input'];
  input: WithdrawCompanyInvitationInput;
};

export enum NetworkInvitationType {
  LINK_AND_EMAIL = 'LINK_AND_EMAIL',
  LINK_ONLY = 'LINK_ONLY',
}

export type NetworkRelation = {
  __typename: 'NetworkRelation';
  companyIdentifiers: Array<CompanyIdentifier>;
  companyName: Scalars['String']['output'];
  companyReferenceName: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  invitation: Maybe<CompanyInvite>;
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  relationCompanyName: Scalars['String']['output'];
  relationId: Scalars['String']['output'];
  relationIdentifiers: Array<CompanyIdentifier>;
  relationType: NetworkRelationType;
  signupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
  tourStatusConsent: NetworkRelationConsentState;
  type: NetworkRelationType;
};

export type NetworkRelationConnection = {
  __typename: 'NetworkRelationConnection';
  edges: Maybe<Array<Maybe<NetworkRelationEdge>>>;
  pageInfo: PageInfo;
};

export enum NetworkRelationConsentState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
}

export type NetworkRelationEdge = {
  __typename: 'NetworkRelationEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<NetworkRelation>;
};

export type NetworkRelationFilter = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  signupStatus?: InputMaybe<Array<UnifiedCompanyRelationshipSignupStatus>>;
  tourStatusConsent?: InputMaybe<Array<InputMaybe<NetworkRelationConsentState>>>;
};

export type NetworkRelationStatistics = {
  __typename: 'NetworkRelationStatistics';
  companyCountsByOnboardingStatus: Array<CompanyCountByOnboardingStatus>;
  companyCountsByOnboardingStatusAndDate: Array<CompanyCountByOnboardingStatus>;
  companyCountsBySignupStatus: Array<CompanyCountBySignupStatus>;
  companyCountsBySignupStatusAndDate: Array<CompanyCountBySignupStatus>;
};

export type NetworkRelationStatisticsCompanyCountsByOnboardingStatusAndDateArgs = {
  date: Scalars['String']['input'];
};

export type NetworkRelationStatisticsCompanyCountsBySignupStatusAndDateArgs = {
  date: Scalars['String']['input'];
};

export enum NetworkRelationType {
  EXISTING = 'EXISTING',
  INVITATION = 'INVITATION',
}

export enum NetworkRelationsSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_NAME_BY_DISTANCE = 'COMPANY_NAME_BY_DISTANCE',
  COMPANY_REFERENCE_NAME = 'COMPANY_REFERENCE_NAME',
  CONSENT_STATUS = 'CONSENT_STATUS',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  RELATION_COMPANY_NAME = 'RELATION_COMPANY_NAME',
  SIGNUP_STATUS = 'SIGNUP_STATUS',
}

export type NetworkRelationsSortOrderInput = {
  direction: SortDirection;
  key: NetworkRelationsSortKey;
};

export type NoiseCost = {
  __typename: 'NoiseCost';
  noiseCostValue: Scalars['Float']['output'];
  transportMode: Scalars['String']['output'];
};

export type Note = {
  __typename: 'Note';
  associatedDeliveries: Array<TourDelivery>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: NoteCreatedBy;
  noteId: Scalars['String']['output'];
  noteOrigin: NoteOrigin;
  noteSentToDriver: Scalars['Boolean']['output'];
  noteText: Scalars['String']['output'];
  noteVisibility: NoteVisibility;
  stopId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum NoteCreatedBy {
  CARRIER = 'CARRIER',
  DRIVER = 'DRIVER',
  SHIPPER = 'SHIPPER',
}

export enum NoteOrigin {
  EXTERNAL = 'EXTERNAL',
  VH_UI = 'VH_UI',
}

export enum NoteVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type NotificationTrigger = {
  __typename: 'NotificationTrigger';
  etaComparison: Maybe<Array<EtaComparison>>;
  failureReasons: Maybe<Array<TourFailureReasonType>>;
  warnings: Maybe<Array<TourWarningType>>;
};

export type NotificationTriggerInput = {
  etaComparison?: InputMaybe<Array<EtaComparisonInput>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  warnings?: InputMaybe<Array<TourWarningType>>;
};

export type NullableUpload = {
  upload?: InputMaybe<Scalars['Upload']['input']>;
};

export type OceanCostDetails = {
  __typename: 'OceanCostDetails';
  calculation: Maybe<Scalars['String']['output']>;
  costPerDay: Maybe<Scalars['Float']['output']>;
  daysBracket: Maybe<Scalars['String']['output']>;
};

export type OceanDemurrage = {
  __typename: 'OceanDemurrage';
  costs: Maybe<Array<OceanDemurrageCost>>;
  currency: Scalars['String']['output'];
  minRemainingFreeDays: Maybe<Scalars['Int']['output']>;
  overallState: Maybe<Scalars['String']['output']>;
  totalCost: Maybe<Scalars['Float']['output']>;
};

export type OceanDemurrageCost = {
  __typename: 'OceanDemurrageCost';
  actualCost: Maybe<Scalars['Float']['output']>;
  comments: Maybe<Scalars['String']['output']>;
  contractReference: Maybe<Scalars['String']['output']>;
  contractType: Maybe<Scalars['String']['output']>;
  costDetails: Array<OceanCostDetails>;
  currency: Scalars['String']['output'];
  endDate: Maybe<Scalars['String']['output']>;
  freeTimeDays: Maybe<Scalars['Int']['output']>;
  freeTimeType: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  overdueDays: Maybe<Scalars['Int']['output']>;
  remainingFreeDays: Maybe<Scalars['Int']['output']>;
  runningCost: Maybe<Scalars['Float']['output']>;
  startDate: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  timeElapsedDays: Maybe<Scalars['Int']['output']>;
};

export type OceanDemurrageFilter = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  remainingFreeDays?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<DemurrageType>>;
};

export type OceanDemurrageFilterOutput = {
  __typename: 'OceanDemurrageFilterOutput';
  cost: Maybe<Scalars['Float']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  names: Maybe<Array<Scalars['String']['output']>>;
  remainingFreeDays: Maybe<Scalars['Int']['output']>;
  states: Maybe<Array<Scalars['String']['output']>>;
  types: Maybe<Array<DemurrageType>>;
};

export type OnboardingAction = {
  __typename: 'OnboardingAction';
  type: OnboardingActionKey;
};

export enum OnboardingActionKey {
  ALLOCATE_TRANSPORTS = 'ALLOCATE_TRANSPORTS',
  CONNECT_VEHICLES = 'CONNECT_VEHICLES',
  INVALID_INTEGRATION = 'INVALID_INTEGRATION',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS',
}

export enum OperatorType {
  DIVISION = 'DIVISION',
}

export type OptionsArgument = {
  subType: Scalars['String']['input'];
  textSearch?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderItem = {
  __typename: 'OrderItem';
  handlingUnits: Maybe<Array<HandlingUnit>>;
  hazmatType: Maybe<Array<HazmatType>>;
  isHazardous: Maybe<Scalars['Boolean']['output']>;
  itemHeight: Maybe<Scalars['Float']['output']>;
  itemLength: Maybe<Scalars['Float']['output']>;
  itemWidth: Maybe<Scalars['Float']['output']>;
  linearUnit: Maybe<LinearUnit>;
  orderItemCustomFields: Maybe<Array<OrderItemCustomField>>;
  orderItemGroups: Maybe<Array<OrderItemGroup>>;
  orderItemId: Scalars['String']['output'];
  orderItemName: Maybe<Scalars['String']['output']>;
  pieceCount: Maybe<Scalars['Int']['output']>;
  pieceDescription: Maybe<Scalars['String']['output']>;
  pieceUnit: Maybe<PieceUnit>;
  volume: Maybe<Scalars['Float']['output']>;
  volumeUnit: Maybe<VolumeUnit>;
  weightAmount: Maybe<Scalars['Float']['output']>;
  weightUnit: Maybe<WeightUnit>;
};

export type OrderItemCustomField = {
  __typename: 'OrderItemCustomField';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrderItemCustomFieldConnection = {
  __typename: 'OrderItemCustomFieldConnection';
  edges: Maybe<Array<Maybe<OrderItemCustomFieldEdge>>>;
  pageInfo: PageInfo;
};

export type OrderItemCustomFieldEdge = {
  __typename: 'OrderItemCustomFieldEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<OrderItemCustomField>;
};

export type OrderItemCustomFieldInput = {
  productBrandValues?: InputMaybe<Array<Scalars['String']['input']>>;
  productLineValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderItemCustomFieldOutput = {
  __typename: 'OrderItemCustomFieldOutput';
  productBrandValues: Maybe<Array<Scalars['String']['output']>>;
  productLineValues: Maybe<Array<Scalars['String']['output']>>;
};

export type OrderItemCustomFieldSearch = {
  textSearch?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrderItemCustomFieldSearchType>;
};

export enum OrderItemCustomFieldSearchType {
  NAME = 'NAME',
  VALUE = 'VALUE',
}

export type OrderItemGroup = {
  __typename: 'OrderItemGroup';
  orderItemGroupDescription: Scalars['String']['output'];
  orderItemGroupId: Scalars['String']['output'];
  orderItemGroupName: Scalars['String']['output'];
};

export type OrderItemGroupOutput = {
  __typename: 'OrderItemGroupOutput';
  orderItemGroupDescriptions: Maybe<Array<Scalars['String']['output']>>;
  orderItemGroupNames: Maybe<Array<Scalars['String']['output']>>;
};

export type OrderItemGroupsInput = {
  orderItemGroupDescriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  orderItemGroupNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderItemIdentifier = {
  __typename: 'OrderItemIdentifier';
  orderItemId: Scalars['String']['output'];
  orderItemName: Scalars['String']['output'];
};

export type OrderItemIdentifierConnection = {
  __typename: 'OrderItemIdentifierConnection';
  edges: Maybe<Array<Maybe<OrderItemIdentifierEdge>>>;
  pageInfo: PageInfo;
};

export type OrderItemIdentifierEdge = {
  __typename: 'OrderItemIdentifierEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<OrderItemIdentifier>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Maybe<Scalars['String']['output']>;
};

export enum PieceUnit {
  BOX = 'BOX',
  EACH = 'EACH',
  GALLON = 'GALLON',
  LITER = 'LITER',
  OUNCES = 'OUNCES',
  PALLET = 'PALLET',
  UNITS = 'UNITS',
}

export type PlaceGeodataInput = {
  geofences: Array<PlaceGeofenceInput>;
  location: PointLocationInput;
};

export type PlaceGeofence = CircleGeofence | PolygonGeofence;

export type PlaceGeofenceInput = {
  geofence: PlaceGeofenceUnionInput;
  zone: PlaceGeofenceZoneType;
};

export type PlaceGeofenceUnionInput = {
  circle?: InputMaybe<CircleGeofenceInput>;
  polygon?: InputMaybe<PolygonGeofenceInput>;
};

export type PlaceGeofenceWithZone = {
  __typename: 'PlaceGeofenceWithZone';
  geofence: PlaceGeofence;
  zone: PlaceGeofenceZoneType;
};

export enum PlaceGeofenceZoneType {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}

export type PlaceTextSearch = {
  fields: Array<PlaceTextSearchField>;
  query: Scalars['String']['input'];
};

export enum PlaceTextSearchField {
  ADDRESSES = 'ADDRESSES',
  NAME = 'NAME',
  REFERENCE_ID = 'REFERENCE_ID',
}

export type Platform = {
  __typename: 'Platform';
  platform_branches: Array<Scalars['String']['output']>;
  platform_id: Scalars['String']['output'];
  platform_name: Scalars['String']['output'];
};

export type PlatformCallbackMessage = {
  __typename: 'PlatformCallbackMessage';
  createdAt: Scalars['DateTime']['output'];
  payload: Scalars['GraphQLJSON']['output'];
  platform: Maybe<Platform>;
  subjectId: Scalars['String']['output'];
  subjectType: Scalars['String']['output'];
};

export type PlatformCompanyInvite = {
  __typename: 'PlatformCompanyInvite';
  inviteStatus: Maybe<CompanyInviteStatus>;
  invitedByCompany: CompanyProfile;
  invitedByUser: UserProfile;
  signupReferenceId: Maybe<Scalars['String']['output']>;
  signupUrls: SignupUrls;
};

export type PlatformConnection = {
  __typename: 'PlatformConnection';
  edges: Maybe<Array<Maybe<PlatformEdge>>>;
  pageInfo: PageInfo;
};

export type PlatformEdge = {
  __typename: 'PlatformEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Platform>;
};

export type PlatformProfile = {
  __typename: 'PlatformProfile';
  platform_id: Scalars['String']['output'];
  platform_name: Scalars['String']['output'];
};

export type PointLocation = {
  __typename: 'PointLocation';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type PointLocationInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type PolygonGeofence = {
  __typename: 'PolygonGeofence';
  points: Array<PointLocation>;
};

export type PolygonGeofenceInput = {
  points: Array<PointLocationInput>;
};

export type Polyline = {
  __typename: 'Polyline';
  points: Scalars['String']['output'];
};

export type Position = {
  __typename: 'Position';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type ProvidingVisibilityCompaniesFilterInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  tourStatusConsent?: InputMaybe<Array<ConsentState>>;
};

export enum ProvidingVisibilityCompaniesSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  CONSENT_STATUS = 'CONSENT_STATUS',
}

export type ProvidingVisibilityCompaniesSortOrderInput = {
  direction: SortDirection;
  key: ProvidingVisibilityCompaniesSortKey;
};

export type ProvidingVisibilityCompany = {
  __typename: 'ProvidingVisibilityCompany';
  companyId: Scalars['String']['output'];
  companyIdentifiers: Array<CompanyIdentifier>;
  companyName: Maybe<Scalars['String']['output']>;
  dateAdded: Scalars['DateTime']['output'];
  relationIdentifiers: Array<CompanyIdentifier>;
  tourStatusConsent: ConsentState;
  transporeonId: Maybe<Scalars['String']['output']>;
  vatNumbers: Array<CompanyVatNumber>;
};

export type ProvidingVisibilityCompanyConnection = {
  __typename: 'ProvidingVisibilityCompanyConnection';
  edges: Maybe<Array<Maybe<ProvidingVisibilityCompanyEdge>>>;
  pageInfo: PageInfo;
};

export type ProvidingVisibilityCompanyEdge = {
  __typename: 'ProvidingVisibilityCompanyEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<ProvidingVisibilityCompany>;
};

export type Query = {
  __typename: 'Query';
  acceptedTransporeonUserAgreement: Maybe<Scalars['Boolean']['output']>;
  apiEndpointUsageLogs: Array<ApiEndpointUsageLog>;
  availableCompanyTags: Array<CompanyTag>;
  availableTourTags: Array<TourTag>;
  backofficeScopes: Array<Scalars['String']['output']>;
  bigQueryMessages: Array<BigQueryMessageEntry>;
  companies: Maybe<CompanyConnection>;
  company: Maybe<Company>;
  companyInvitations: Array<CompanyInvite>;
  companyPlatform: Maybe<CompanyPlatform>;
  companyRelationships: Maybe<CompanyRelationshipConnection>;
  featureFlagMetadata: Array<FeatureFlagMetadata>;
  featureFlags: Maybe<FeatureFlags>;
  intercomTokenUrl: Scalars['String']['output'];
  licensePlateLookup: LicensePlateLookupResultSet;
  platforms: Maybe<PlatformConnection>;
  sensitiveConnectionData: Maybe<Scalars['GraphQLJSON']['output']>;
  sensitiveData: Maybe<SensitiveData>;
  status: Maybe<Scalars['String']['output']>;
  telematicsIntegration: Maybe<TelematicsIntegration>;
  telematicsIntegrations: Maybe<CountedTelematicsIntegrationConnection>;
  telematicsProvider: Maybe<TelematicsProvider>;
  /** @deprecated Use telematicsProvidersV2 instead */
  telematicsProviders: TelematicsProviderConnection;
  telematicsProvidersV2: CountedTelematicsProviderConnection;
  token: Maybe<TokenResources>;
  tour: Maybe<Tour>;
  tours: CountedTourConnection;
  user: Maybe<User>;
  userNotificationConfig: Maybe<UserNotificationConfig>;
  users: Maybe<UserConnection>;
  vehicle: Maybe<Vehicle>;
  vehicleTrackerByTelematicsIntegrationAndVehicleDetails: Maybe<VehicleTrackerSearchResult>;
  vehicles: Maybe<VehicleConnection>;
  viewer: Maybe<User>;
};

export type QueryAcceptedTransporeonUserAgreementArgs = {
  companyId: Scalars['String']['input'];
};

export type QueryApiEndpointUsageLogsArgs = {
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBigQueryMessagesArgs = {
  from: Scalars['DateTime']['input'];
  keys: Array<Scalars['String']['input']>;
  messageTypes: Array<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
  topics: Array<Scalars['String']['input']>;
};

export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onboardingStatus?: InputMaybe<UnifiedCompanyOnboardingStatus>;
  onboardingStuckReason?: InputMaybe<UnifiedCompanyOnboardingStuckReason>;
  signupStatus?: InputMaybe<UnifiedCompanySignupStatus>;
  tagName?: InputMaybe<Scalars['String']['input']>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CompanyTypeInput>;
};

export type QueryCompanyArgs = {
  company_id: Scalars['String']['input'];
};

export type QueryCompanyInvitationsArgs = {
  company_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyPlatformArgs = {
  company_id: Scalars['String']['input'];
  platform_id: Scalars['String']['input'];
};

export type QueryCompanyRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  relationshipType?: InputMaybe<CompanyRelationshipType>;
  sourceCompanyId?: InputMaybe<Scalars['String']['input']>;
  targetCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFeatureFlagMetadataArgs = {
  applies_to: Scalars['String']['input'];
};

export type QueryFeatureFlagsArgs = {
  applies_to: Scalars['String']['input'];
};

export type QueryIntercomTokenUrlArgs = {
  baseUrl: Scalars['String']['input'];
};

export type QueryLicensePlateLookupArgs = {
  licensePlateInput: LicensePlateLookupInput;
};

export type QueryPlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySensitiveConnectionDataArgs = {
  captcha_token: Scalars['String']['input'];
  telematics_integration_id: Scalars['String']['input'];
};

export type QuerySensitiveDataArgs = {
  captcha_token: Scalars['String']['input'];
  telematics_integration_id: Scalars['String']['input'];
};

export type QueryTelematicsIntegrationArgs = {
  telematics_integration_id: Scalars['String']['input'];
};

export type QueryTelematicsIntegrationsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<TelematicsIntegrationsSortInput>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTelematicsProviderArgs = {
  telematics_provider_id: Scalars['String']['input'];
};

export type QueryTelematicsProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasIntegrationInstructionsURL?: InputMaybe<Scalars['Boolean']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  isExternalConfigurationNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobalAllocationForbidden?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegrationImplemented?: InputMaybe<Scalars['Boolean']['input']>;
  isInternalUseOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isStandardTelematicsPushProvider?: InputMaybe<Scalars['Boolean']['input']>;
  isVehicleManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TelematicsProvidersSortInput>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTelematicsProvidersV2Args = {
  hasIntegrationInstructionsURL?: InputMaybe<Scalars['Boolean']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  isExternalConfigurationNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobalAllocationForbidden?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegrationImplemented?: InputMaybe<Scalars['Boolean']['input']>;
  isInternalUseOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isStandardTelematicsPushProvider?: InputMaybe<Scalars['Boolean']['input']>;
  isVehicleManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<TelematicsProvidersSortInput>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTourArgs = {
  tour_id: Scalars['Int']['input'];
};

export type QueryToursArgs = {
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  delayTime?: InputMaybe<TimeRangeInput>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  hasNotes?: InputMaybe<Scalars['Boolean']['input']>;
  lastSignalTimestamp?: InputMaybe<LastSignalTimestampFilterInput>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  orderItemGroups?: InputMaybe<OrderItemGroupsInput>;
  orderItemNames?: InputMaybe<Array<Scalars['String']['input']>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  platformBranchId?: InputMaybe<Scalars['String']['input']>;
  platformId?: InputMaybe<Scalars['String']['input']>;
  shipmentType?: InputMaybe<Array<ShipmentTypeFilter>>;
  shipperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
  textSearch?: InputMaybe<TourTextSearch>;
  timeSpentAtStop?: InputMaybe<TimeRangeInput>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  voyageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  warnings?: InputMaybe<Array<TourWarningType>>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryUserArgs = {
  user_id: Scalars['String']['input'];
};

export type QueryUserNotificationConfigArgs = {
  company_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryVehicleArgs = {
  vehicle_id: Scalars['String']['input'];
};

export type QueryVehicleTrackerByTelematicsIntegrationAndVehicleDetailsArgs = {
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  remoteId?: InputMaybe<Scalars['String']['input']>;
  telematicsIntegrationId: Scalars['String']['input'];
};

export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type RawExternalEvents = {
  __typename: 'RawExternalEvents';
  tourDemurrageDetentionLogs: Array<TourDemurrageDetentionLog>;
  tourExternalTracking: Array<TourExternalTracking>;
  tourExternalTrackingLogs: Array<TourExternalTrackingLog>;
  tourExternalTrackingRequests: Array<TourExternalTrackingRequest>;
};

export type ReceivingVisibilityCompaniesFilterInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  signupStatus?: InputMaybe<Array<UnifiedCompanyRelationshipSignupStatus>>;
  tourStatusConsent?: InputMaybe<Array<InputMaybe<ConsentState>>>;
};

export enum ReceivingVisibilityCompaniesSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  CONSENT_STATUS = 'CONSENT_STATUS',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  SIGNUP_STATUS = 'SIGNUP_STATUS',
}

export type ReceivingVisibilityCompaniesSortOrderInput = {
  direction: SortDirection;
  key: ReceivingVisibilityCompaniesSortKey;
};

export type ReceivingVisibilityCompany = {
  __typename: 'ReceivingVisibilityCompany';
  companyId: Scalars['String']['output'];
  companyIdentifiers: Array<CompanyIdentifier>;
  companyInvite: Maybe<CompanyInvite>;
  companyName: Maybe<Scalars['String']['output']>;
  dateAdded: Scalars['DateTime']['output'];
  latestPendingInvite: Maybe<CompanyInvite>;
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  onboardingStatusTransitionedAt: Maybe<Scalars['DateTime']['output']>;
  onboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  relationIdentifiers: Array<CompanyIdentifier>;
  signupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
  signupStatusTransitionedAt: Maybe<Scalars['DateTime']['output']>;
  tourStatusConsent: ConsentState;
  transporeonId: Maybe<Scalars['String']['output']>;
  vatNumbers: Array<CompanyVatNumber>;
};

export type ReceivingVisibilityCompanyConnection = {
  __typename: 'ReceivingVisibilityCompanyConnection';
  edges: Maybe<Array<Maybe<ReceivingVisibilityCompanyEdge>>>;
  pageInfo: PageInfo;
};

export type ReceivingVisibilityCompanyEdge = {
  __typename: 'ReceivingVisibilityCompanyEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<ReceivingVisibilityCompany>;
};

export type Recipient = EmailRecipient | SmsRecipient | StopContactRecipient;

export type RecipientInput = {
  email?: InputMaybe<EmailRecipientInput>;
  sms?: InputMaybe<SmsRecipientInput>;
  stopContact?: InputMaybe<StopContactRecipientInput>;
};

export type RelatedTransportForListView = {
  __typename: 'RelatedTransportForListView';
  shipper_transport_number: Maybe<Scalars['String']['output']>;
  tour_id: Scalars['Int']['output'];
};

export type RemoveCredentialsFromUserInput = {
  type: CredentialType;
  userId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type RemoveUserFromCompanyInput = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type RemoveVehiclesFromCompanyVehicleGroupInput = {
  companyId: Scalars['String']['input'];
  companyVehicleGroupId: Scalars['String']['input'];
  vehicleIds: Array<Scalars['String']['input']>;
};

export type ResendCompanyInvitationInput = {
  externalInvitation: ExternalCompanyInvitation;
};

export type RestrictedVehicleStatus = {
  __typename: 'RestrictedVehicleStatus';
  heading: Maybe<Scalars['Float']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  source: VehicleSignalSource;
  timeBasedStatus: TimeBasedStatus;
  timestamp: Maybe<Scalars['DateTime']['output']>;
  vehicle_id: Scalars['String']['output'];
};

export type RevokeVehicleGrantsInput = {
  revokedByCompany: Scalars['String']['input'];
  revokedFromCompany: Scalars['String']['input'];
};

export type Route = {
  __typename: 'Route';
  legs: Array<RouteLeg>;
  provider: Scalars['String']['output'];
};

export type RouteLeg = {
  __typename: 'RouteLeg';
  polyline: Polyline;
};

export type SaveCustomerFeedbackInput = {
  companyId: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
};

export type SendBulkInvitationSetInput = {
  bulkInvitationSetId: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  includeInviterInCC?: InputMaybe<Scalars['Boolean']['input']>;
  selectInvitationType: BulkInvitationSendSelectType;
  selectedInvitationItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SendBulkInvitationSetResult = {
  __typename: 'SendBulkInvitationSetResult';
  success: Scalars['Boolean']['output'];
};

export type SensitiveData = {
  __typename: 'SensitiveData';
  connectionData: Maybe<Scalars['GraphQLJSON']['output']>;
  syncStateData: Maybe<Scalars['String']['output']>;
};

export type SharedCompanyInvite = {
  __typename: 'SharedCompanyInvite';
  allowedSignups: Array<AllowedCompanyInviteSignup>;
  companyInviteId: Scalars['String']['output'];
  invitedByCompany: Maybe<CompanyProfile>;
  invitedByUser: Maybe<UserProfile>;
  invitedCompany: Maybe<CompanyProfile>;
};

export type SharedCompanyTourFilter = {
  __typename: 'SharedCompanyTourFilter';
  name: Scalars['String']['output'];
  owner: Maybe<CompanyProfile>;
  tour: Maybe<Tour>;
  tours: CountedTourConnection;
};

export type SharedCompanyTourFilterTourArgs = {
  tourId: Scalars['String']['input'];
};

export type SharedCompanyTourFilterToursArgs = {
  sortOrder?: InputMaybe<TourSortOrder>;
  textSearch?: InputMaybe<TourTextSearch>;
};

export type SharedStopViewConfiguration = {
  __typename: 'SharedStopViewConfiguration';
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedStopViewConfigurationInput = {
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedStopViewConfigurationValueInput = {
  value?: InputMaybe<SharedStopViewConfigurationInput>;
};

export type SharedTourDelivery = {
  __typename: 'SharedTourDelivery';
  delivery: Maybe<TourDelivery>;
  tourStop: Maybe<SharedTourStop>;
};

export type SharedTourStop = {
  __typename: 'SharedTourStop';
  latestSignalTimestamp: Maybe<Scalars['DateTime']['output']>;
  shipper: Maybe<CompanyProfile>;
  stop: TourStop;
  tourStatus: Maybe<Scalars['String']['output']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']['output']>;
  vehicleStatus: Maybe<VehicleStatus>;
};

export type SharedTourViewConfiguration = {
  __typename: 'SharedTourViewConfiguration';
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedTourViewConfigurationInput = {
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedTourViewConfigurationValueInput = {
  value?: InputMaybe<SharedTourViewConfigurationInput>;
};

export type SharedUserLogin = {
  __typename: 'SharedUserLogin';
  user: LoginUser;
};

export type SharingToken = {
  __typename: 'SharingToken';
  createdBy: Maybe<UserProfile>;
  token: Scalars['String']['output'];
};

export enum ShipmentType {
  FCL = 'FCL',
  LCL = 'LCL',
}

export enum ShipmentTypeFilter {
  ALL = 'ALL',
  FCL = 'FCL',
  LCL = 'LCL',
}

export type ShipperReport = {
  __typename: 'ShipperReport';
  allocatedToursCount: Scalars['Int']['output'];
  shipper: Maybe<CompanyProfile>;
  toursCount: Scalars['Int']['output'];
  visibilityIndex: Maybe<Scalars['Float']['output']>;
};

export type ShipperReportConnection = {
  __typename: 'ShipperReportConnection';
  edges: Maybe<Array<Maybe<ShipperReportEdge>>>;
  pageInfo: PageInfo;
};

export type ShipperReportEdge = {
  __typename: 'ShipperReportEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<ShipperReport>;
};

export enum SignupAction {
  CONNECT_VEHICLES = 'CONNECT_VEHICLES',
  INVALID_INTEGRATION = 'INVALID_INTEGRATION',
}

export type SignupPageOpenedCompanyInput = {
  companyId: Scalars['String']['input'];
};

export type SignupPageOpenedInput = {
  company?: InputMaybe<SignupPageOpenedCompanyInput>;
  platformCompany?: InputMaybe<SignupPageOpenedPlatformCompanyInput>;
};

export type SignupPageOpenedPlatformCompanyInput = {
  companyIdOnPlatform: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type SignupPlatformCompanyInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  fromPlatform: FromPlatformData;
  lastName: Scalars['String']['input'];
  referralToken?: InputMaybe<Scalars['String']['input']>;
};

export enum SignupType {
  EMAIL = 'EMAIL',
  TRANSPOREON_PLATFORM = 'TRANSPOREON_PLATFORM',
}

export type SignupUrls = {
  __typename: 'SignupUrls';
  newRegistrationUrl: Scalars['String']['output'];
  updateRegistrationUrl: Scalars['String']['output'];
};

export type SingleTourStopEstimate = {
  __typename: 'SingleTourStopEstimate';
  estimated_arrival: Maybe<Scalars['DateTime']['output']>;
  estimated_arrival_accuracy: Maybe<Scalars['String']['output']>;
  estimated_arrival_delay_in_seconds: Maybe<Scalars['Int']['output']>;
  estimated_arrival_distance_in_meters: Maybe<Scalars['Int']['output']>;
  estimated_arrival_regulated: Maybe<Scalars['DateTime']['output']>;
  estimated_arrival_status: Maybe<Scalars['String']['output']>;
  estimated_arrival_unregulated: Maybe<Scalars['DateTime']['output']>;
  etaType: Maybe<EtaType>;
  externalEtaSource: Maybe<ExternalEtaSource>;
  stop_id: Maybe<Scalars['String']['output']>;
};

export type Site = {
  __typename: 'Site';
  name: Scalars['String']['output'];
  siteIdOnPlatform: Scalars['String']['output'];
  site_id: Scalars['String']['output'];
};

export type SiteConnection = {
  __typename: 'SiteConnection';
  edges: Maybe<Array<Maybe<SiteEdge>>>;
  pageInfo: PageInfo;
};

export type SiteEdge = {
  __typename: 'SiteEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Site>;
};

export type SiteProfile = {
  __typename: 'SiteProfile';
  name: Scalars['String']['output'];
  site_id: Scalars['String']['output'];
};

export enum SiteTourStatusFilter {
  AT_STOP = 'AT_STOP',
  EN_ROUTE = 'EN_ROUTE',
}

export type SmsRecipient = {
  __typename: 'SmsRecipient';
  locale: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type SmsRecipientInput = {
  locale: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export type StopAllocation = {
  __typename: 'StopAllocation';
  startTrackingAt: Maybe<Scalars['DateTime']['output']>;
  stopId: Scalars['String']['output'];
  vehicleId: Maybe<Scalars['String']['output']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']['output']>;
  vehicleTrackerTypes: Maybe<Array<VehicleTrackerType>>;
};

export type StopAllocationInput = {
  startTrackingAt?: InputMaybe<Scalars['DateTime']['input']>;
  stopId: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type StopCompanyName = {
  __typename: 'StopCompanyName';
  value: Scalars['String']['output'];
};

export type StopCompanyNameConnection = {
  __typename: 'StopCompanyNameConnection';
  edges: Maybe<Array<Maybe<StopCompanyNameEdge>>>;
  pageInfo: PageInfo;
};

export type StopCompanyNameEdge = {
  __typename: 'StopCompanyNameEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<StopCompanyName>;
};

export type StopContactRecipient = {
  __typename: 'StopContactRecipient';
  emailStopContact: Maybe<Scalars['Boolean']['output']>;
  smsStopContact: Maybe<Scalars['Boolean']['output']>;
};

export type StopContactRecipientInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StopEvent = {
  __typename: 'StopEvent';
  created_at: Scalars['DateTime']['output'];
  event_id: Scalars['Int']['output'];
  event_index: Scalars['Int']['output'];
  event_name: StopEventName;
  event_time: Maybe<Scalars['DateTime']['output']>;
  metadata: Maybe<StopEventMetadata>;
  stop_id: Scalars['Int']['output'];
  tour_id: Scalars['Int']['output'];
  vehicle_id: Maybe<Scalars['String']['output']>;
  vehicle_lat: Maybe<Scalars['Float']['output']>;
  vehicle_license_plate_number: Maybe<Scalars['String']['output']>;
  vehicle_lng: Maybe<Scalars['Float']['output']>;
  visibilitySource: Maybe<TourVisibilitySource>;
};

export type StopEventMetadata = {
  __typename: 'StopEventMetadata';
  algorithm: Maybe<Scalars['String']['output']>;
  parameters: Maybe<Scalars['GraphQLJSON']['output']>;
};

export enum StopEventName {
  APPROACH = 'APPROACH',
  /** @deprecated No longer supported */
  APPROACHING = 'APPROACHING',
  APPROACH_UNKNOWN = 'APPROACH_UNKNOWN',
  DELETE = 'DELETE',
  DEPART = 'DEPART',
  /** @deprecated No longer supported */
  DEPARTED = 'DEPARTED',
  DEPART_UNKNOWN = 'DEPART_UNKNOWN',
  NAVIGATE = 'NAVIGATE',
  SKIP = 'SKIP',
  TRACTOR_SWITCH = 'TRACTOR_SWITCH',
  TRACTOR_SWITCH_WAITING = 'TRACTOR_SWITCH_WAITING',
  VISIBILITY_ENDED = 'VISIBILITY_ENDED',
}

export type StopFilterInput = {
  bookingLocationNames?: InputMaybe<Array<Scalars['String']['input']>>;
  companyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<StopStatesInput>>;
  stopTypes?: InputMaybe<Array<FilterRoadStopType>>;
};

export type StopFilterOutput = {
  __typename: 'StopFilterOutput';
  bookingLocationNames: Maybe<Array<Scalars['String']['output']>>;
  companyNames: Maybe<Array<Scalars['String']['output']>>;
  countries: Maybe<Array<Scalars['String']['output']>>;
  placeIds: Maybe<Array<Scalars['String']['output']>>;
  states: Maybe<Array<CompanyStopStates>>;
  stopTypes: Maybe<Array<FilterRoadStopType>>;
};

export type StopLocationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type StopStatesInput = {
  country: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type StopTimeslotInput = {
  begin: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  timezone: Scalars['IANATimezone']['input'];
};

export enum StopType {
  loading = 'loading',
  unloading = 'unloading',
}

export type SubmitUnsupportedTelematicsProviderDetailsInput = {
  companyId: Scalars['String']['input'];
  telematicsContactPersonEmail: Scalars['String']['input'];
  telematicsContactPersonFirstName: Scalars['String']['input'];
  telematicsContactPersonLastName: Scalars['String']['input'];
  telematicsContactPersonPhone?: InputMaybe<Scalars['String']['input']>;
  telematicsProviderHomepage: Scalars['String']['input'];
  telematicsProviderName: Scalars['String']['input'];
};

export type TelematicsIntegration = {
  __typename: 'TelematicsIntegration';
  company: CompanyProfile;
  company_id: Scalars['String']['output'];
  company_integration_id: Scalars['String']['output'];
  connection_data: Maybe<Scalars['GraphQLJSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<UserProfile>;
  diagnosticComment: Maybe<Scalars['String']['output']>;
  diagnosticStatus: Maybe<TelematicsIntegrationDiagnosticStatus>;
  enabled: Scalars['Boolean']['output'];
  generatedToken: Maybe<Scalars['String']['output']>;
  integrationApiDebugUrl: Scalars['String']['output'];
  integrationSyncHistory: Maybe<Array<IntegrationSyncResult>>;
  isAllowedToUpdateCredentials: Scalars['Boolean']['output'];
  isDeletable: Scalars['Boolean']['output'];
  isImmutable: Scalars['Boolean']['output'];
  telematicsProvider: TelematicsProvider;
  telematics_integration_id: Scalars['String']['output'];
  telematics_provider_id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Maybe<UserProfile>;
};

export type TelematicsIntegrationConnection = {
  __typename: 'TelematicsIntegrationConnection';
  edges: Maybe<Array<Maybe<TelematicsIntegrationEdge>>>;
  pageInfo: PageInfo;
};

export enum TelematicsIntegrationDiagnosticStatus {
  INTERMITTENT = 'INTERMITTENT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVESTIGATING = 'INVESTIGATING',
  PROVIDER_ERROR = 'PROVIDER_ERROR',
}

export type TelematicsIntegrationEdge = {
  __typename: 'TelematicsIntegrationEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<TelematicsIntegration>;
};

export type TelematicsIntegrationsSortInput = {
  direction: SortDirection;
  key: TelematicsIntegrationsSortKey;
};

export enum TelematicsIntegrationsSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  CREATED_AT = 'CREATED_AT',
  INTEGRATION_ID = 'INTEGRATION_ID',
  PROVIDER_ID = 'PROVIDER_ID',
  UPDATED_AT = 'UPDATED_AT',
}

export type TelematicsProvider = {
  __typename: 'TelematicsProvider';
  activeTrucksCount: Scalars['Int']['output'];
  connectionParameters: Array<ConnectionParameter>;
  contact_info: Maybe<TelematicsProviderContactInfo>;
  integrationInstructionsURL: Maybe<Scalars['String']['output']>;
  isDeprecated: Maybe<Scalars['Boolean']['output']>;
  isExternalConfigurationNeeded: Scalars['Boolean']['output'];
  isGlobalAllocationForbidden: Maybe<Scalars['Boolean']['output']>;
  isIntegrationImplemented: Scalars['Boolean']['output'];
  isInternalUseOnly: Scalars['Boolean']['output'];
  isStandardTelematicsPushProvider: Maybe<Scalars['Boolean']['output']>;
  isVehicleManagementEnabled: Maybe<Scalars['Boolean']['output']>;
  telematicsIntegrations: CountedTelematicsIntegrationConnection;
  telematicsProviderId: Scalars['String']['output'];
  telematicsProviderName: Scalars['String']['output'];
  totalTrucksCount: Scalars['Int']['output'];
};

export type TelematicsProviderConnectionParametersArgs = {
  includeInternal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TelematicsProviderTelematicsIntegrationsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<TelematicsIntegrationsSortInput>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type TelematicsProviderConnection = {
  __typename: 'TelematicsProviderConnection';
  edges: Maybe<Array<Maybe<TelematicsProviderEdge>>>;
  pageInfo: PageInfo;
};

export type TelematicsProviderContactInfo = {
  __typename: 'TelematicsProviderContactInfo';
  comment: Maybe<Scalars['String']['output']>;
  email_address: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['String']['output']>;
};

export type TelematicsProviderContactInfoInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  email_address?: InputMaybe<Scalars['String']['input']>;
};

export type TelematicsProviderEdge = {
  __typename: 'TelematicsProviderEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<TelematicsProvider>;
};

export type TelematicsProvidersSortInput = {
  direction: SortDirection;
  key: TelematicsProvidersSortKey;
};

export enum TelematicsProvidersSortKey {
  ACTIVE_INTEGRATIONS_COUNT = 'ACTIVE_INTEGRATIONS_COUNT',
  ACTIVE_TRUCKS_COUNT = 'ACTIVE_TRUCKS_COUNT',
  INTEGRATIONS_COUNT = 'INTEGRATIONS_COUNT',
  PROVIDER_NAME = 'PROVIDER_NAME',
  TRUCKS_COUNT = 'TRUCKS_COUNT',
}

export type TelematicsValidation = {
  __typename: 'TelematicsValidation';
  areValidCredentials: Scalars['Boolean']['output'];
};

export type TemperatureSensor = {
  __typename: 'TemperatureSensor';
  name: Scalars['String']['output'];
  readings: Array<TemperatureSensorReading>;
};

export type TemperatureSensorReading = {
  __typename: 'TemperatureSensorReading';
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export enum TimeBasedStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type TimeRange = {
  __typename: 'TimeRange';
  comparison: ComparisonOperator;
  endInSeconds: Maybe<Scalars['Int']['output']>;
  startInSeconds: Scalars['Int']['output'];
  unit: TimeUnit;
};

export type TimeRangeInput = {
  comparison: ComparisonOperator;
  endInSeconds?: InputMaybe<Scalars['Int']['input']>;
  startInSeconds: Scalars['Int']['input'];
  unit?: InputMaybe<TimeUnit>;
};

export type TimeRangeSugarDate = {
  comparison: ExtendedComparisonOperator;
  endSugarDate?: InputMaybe<Scalars['String']['input']>;
  startSugarDate?: InputMaybe<Scalars['String']['input']>;
};

export type TimeRangeSugarDateOutput = {
  __typename: 'TimeRangeSugarDateOutput';
  comparison: ExtendedComparisonOperator;
  endSugarDate: Maybe<Scalars['String']['output']>;
  startSugarDate: Maybe<Scalars['String']['output']>;
};

export enum TimeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type Timeslot = {
  __typename: 'Timeslot';
  begin: Maybe<Scalars['DateTime']['output']>;
  end: Maybe<Scalars['DateTime']['output']>;
  timezone: Maybe<Scalars['IANATimezone']['output']>;
};

export type TokenBrandingAsset = {
  __typename: 'TokenBrandingAsset';
  url: Scalars['String']['output'];
};

export type TokenBrandingAssets = {
  __typename: 'TokenBrandingAssets';
  background: Maybe<TokenBrandingAsset>;
  logo: Maybe<TokenBrandingAsset>;
};

export enum TokenResourceType {
  COMPANY_INVITE = 'COMPANY_INVITE',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_RELATION = 'COMPANY_RELATION',
  COMPANY_SIGNUP = 'COMPANY_SIGNUP',
  COMPANY_TOUR_FILTER = 'COMPANY_TOUR_FILTER',
  INTEGRATION_SYNC = 'INTEGRATION_SYNC',
  PLATFORM_COMPANY_INVITE = 'PLATFORM_COMPANY_INVITE',
  TOUR = 'TOUR',
  TOUR_DELIVERY = 'TOUR_DELIVERY',
  TOUR_STOP = 'TOUR_STOP',
  TOUR_STOP_AUTO_GENERATED = 'TOUR_STOP_AUTO_GENERATED',
  USER_LOGIN = 'USER_LOGIN',
}

export type TokenResources = {
  __typename: 'TokenResources';
  branding: Maybe<TokenBrandingAssets>;
  companyInvite: Maybe<SharedCompanyInvite>;
  companyProfile: Maybe<CompanyProfile>;
  companyRelation: Maybe<CompanyProfile>;
  companyTourFilter: Maybe<SharedCompanyTourFilter>;
  platformCompanyInvite: Maybe<PlatformCompanyInvite>;
  resourceType: TokenResourceType;
  tour: Maybe<Tour>;
  tourDelivery: Maybe<SharedTourDelivery>;
  tourStop: Maybe<SharedTourStop>;
  userLogin: Maybe<SharedUserLogin>;
};

export type Tour = {
  __typename: 'Tour';
  abort_time: Maybe<Scalars['DateTime']['output']>;
  aborted_by_user: Maybe<UserProfile>;
  activeVehicleAllocation: Maybe<TourVehicleAllocation>;
  airWayBill: Maybe<Scalars['String']['output']>;
  allocatableVehicles: VehicleProfileConnection;
  allocationType: Maybe<TourVehicleAllocationType>;
  applicationDeepLinks: Maybe<Array<ApplicationDeepLink>>;
  assignedUser: Maybe<UserProfile>;
  billOfLading: Maybe<Scalars['String']['output']>;
  bill_of_lading: Maybe<Scalars['String']['output']>;
  bookingNumber: Maybe<Scalars['String']['output']>;
  canAbortTour: Scalars['Boolean']['output'];
  canAllocateToTrimbleMobileApp: Scalars['Boolean']['output'];
  canBeModified: Scalars['Boolean']['output'];
  carrier: Maybe<CompanyProfile>;
  carrierCode: Maybe<Scalars['String']['output']>;
  carrierName: Maybe<Scalars['String']['output']>;
  carrierPackageCount: Maybe<Scalars['Float']['output']>;
  carrierPackageWeight: Maybe<Scalars['Float']['output']>;
  carrier_id: Maybe<Scalars['String']['output']>;
  cleanedVehicleAllocationHistory: Array<TourVehicleAllocation>;
  complete_time: Maybe<Scalars['DateTime']['output']>;
  containerNumber: Maybe<Scalars['String']['output']>;
  containerType: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<CompanyProfile>;
  created_at: Scalars['DateTime']['output'];
  currentVehicle: Maybe<VehicleAllocation>;
  customFields: Array<CustomField>;
  customerPackageCount: Maybe<Scalars['Float']['output']>;
  customerPackageWeight: Maybe<Scalars['Float']['output']>;
  deliveries: Array<TourDelivery>;
  deliveryTree: Array<TourDeliveryTree>;
  documents: Array<Document>;
  drivenDistance: Maybe<TourDrivenDistance>;
  emissionsCalculationErrors: Array<CalculationError>;
  end_time: Maybe<Scalars['DateTime']['output']>;
  events: Array<CombinedEvent>;
  exceptions: Array<TourException>;
  externalCarrier: Maybe<ExternalCarrier>;
  externalDeliveryEvents: Array<ExternalTourDeliveryEvent>;
  externalDeliveryEventsVisibleToCustomers: Array<ExternalTourDeliveryEvent>;
  externalEvents: Array<ExternalTourEvent>;
  externalEventsVisibleToCustomers: Array<ExternalTourEvent>;
  externalStopEvents: Array<ExternalTourStopEvent>;
  externalStopEventsVisibleToCustomers: Array<ExternalTourStopEvent>;
  failureReason: Maybe<TourFailureReason>;
  forwardingChain: Array<TourForwardingLink>;
  fuelBasedEmissions: Maybe<FuelBasedEmissions>;
  hasTemperatureData: Maybe<Scalars['Boolean']['output']>;
  hasTransportNotifications: Scalars['Boolean']['output'];
  impactDays: Maybe<Scalars['Int']['output']>;
  incoterm: Maybe<TourIncoterms>;
  isGlobalAllocation: Scalars['Boolean']['output'];
  isGloballyAllocatedToViewerCompany: Scalars['Boolean']['output'];
  isSendEtaToPlatformEnabled: Scalars['Boolean']['output'];
  isSubcontractingEnabled: Scalars['Boolean']['output'];
  isTourAccessedByCreator: Scalars['Boolean']['output'];
  isTrackingDisabled: Scalars['Boolean']['output'];
  latestSignal: Maybe<TourSignal>;
  loadType: Maybe<LoadType>;
  mainMode: Maybe<MainMode>;
  mainVisibilityIssue: Maybe<VisibilityIssue>;
  metadata: Maybe<Scalars['GraphQLJSON']['output']>;
  milestones: Maybe<LocationsAndMilestones>;
  notes: Array<Note>;
  oceanDemurrage: Maybe<OceanDemurrage>;
  packageCount: Maybe<Scalars['String']['output']>;
  parent_tour_id: Maybe<Scalars['String']['output']>;
  plannedEmissions: Maybe<TourCalculatedEmissions>;
  platform: Maybe<Platform>;
  platformBranchId: Maybe<Scalars['String']['output']>;
  platformCallbacks: Array<PlatformCallbackMessage>;
  platformTransportURL: Maybe<Scalars['String']['output']>;
  platform_carrier_id: Maybe<Scalars['String']['output']>;
  platform_shipper_id: Maybe<Scalars['String']['output']>;
  platform_transport_number: Maybe<Scalars['String']['output']>;
  previousTransportsIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  rawExternalEvents: Maybe<RawExternalEvents>;
  recalculate_time: Maybe<Scalars['DateTime']['output']>;
  relatedTransports: Array<Tour>;
  relatedTransportsForListView: Array<RelatedTransportForListView>;
  route: Maybe<Route>;
  sharingToken: Maybe<Scalars['String']['output']>;
  shipmentType: Maybe<ShipmentType>;
  shipper: Maybe<CompanyProfile>;
  shipper_id: Maybe<Scalars['String']['output']>;
  shipper_transport_number: Maybe<Scalars['String']['output']>;
  site: Maybe<SiteProfile>;
  start_time: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<Scalars['String']['output']>;
  status_events: Array<TourEvent>;
  stops: Array<TourStop>;
  subcontractor: Maybe<CompanyProfile>;
  suggestedVehicles: Array<VehicleProfile>;
  tags: Array<TourTag>;
  telemetryEmissions: Maybe<TourCalculatedEmissions>;
  tourAssignment: Maybe<TourAssignment>;
  tourDistance: Maybe<TourDistance>;
  tourEmissionsReceived: Maybe<TourEmissionsReceived>;
  tourPositionHistory: Maybe<Array<VehiclePosition>>;
  tourRoute: Maybe<Route>;
  tourTrafficEntries: TourTrafficEntryConnection;
  tour_area: Maybe<Geofence>;
  tour_id: Scalars['Int']['output'];
  tour_type: Maybe<TourType>;
  trackingAbandonTime: Maybe<Scalars['DateTime']['output']>;
  trackingEndTime: Maybe<Scalars['DateTime']['output']>;
  trackingSegments: Array<TrackingSegment>;
  trackingStartTime: Maybe<Scalars['DateTime']['output']>;
  transitTime: Maybe<Scalars['Int']['output']>;
  transportAlerts: Array<TransportAlert>;
  transportAlertsCount: Scalars['Int']['output'];
  transportAlertsHighestSeverity: Maybe<TransportAlertSeverity>;
  transportGroupId: Maybe<Scalars['String']['output']>;
  transportIdentifiers: Maybe<Array<Scalars['String']['output']>>;
  transportMode: Maybe<TransportMode>;
  transportNotifications: Array<FilterBasedTransportNotification>;
  transport_id: Maybe<Scalars['String']['output']>;
  tripDeliveryItems: Maybe<Array<TripDeliveryItem>>;
  tripLocations: Maybe<Array<TripLocation>>;
  tripSegments: Maybe<Array<TripSegment>>;
  tripsVehicleHistory: Maybe<Array<VehicleHistoryPolyline>>;
  tripsVehicleStatus: Maybe<VehicleStatus>;
  userGroupsOnPlatform: Array<UserGroupOnPlatform>;
  vehicleAllocationHistory: Array<TourVehicleAllocation>;
  vehicleAllocations: Array<TourVehicleAllocation>;
  vehicleBreakHistory: Maybe<Array<VehicleBreakHistoryEntry>>;
  vehicleHistory: Maybe<Array<VehicleHistoryPolyline>>;
  vehicleHistoryList: Maybe<Array<VehicleHistoryEntry>>;
  vehicleStatuses: Array<VehicleStatus>;
  vehicleTemperatures: Maybe<Array<VehicleTemperature>>;
  vehicle_status: Maybe<VehicleStatus>;
  vehicles: Array<VehicleProfile>;
  vesselNames: Maybe<TripVesselNames>;
  visibilityIndex: Maybe<Scalars['Float']['output']>;
  visibilityIssues: Maybe<Array<Maybe<VisibilityIssue>>>;
  visibilitySegments: Array<VisibilitySegment>;
  visibilityServices: Array<VisibilityService>;
  visibilitySources: Array<TourVisibilitySource>;
  visibility_end_time: Maybe<Scalars['DateTime']['output']>;
  visibility_start_time: Maybe<Scalars['DateTime']['output']>;
  visibleTrackingStartTime: Maybe<Scalars['DateTime']['output']>;
  visibleVehicleBreakHistory: Maybe<Array<VehicleBreakHistoryEntry>>;
  volume: Maybe<TourVolume>;
  warnings: Array<TourWarning>;
  working_stop_id: Maybe<Scalars['Int']['output']>;
};

export type TourAllocatableVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensePlateSearch: Scalars['String']['input'];
};

export type TourCanAllocateToTrimbleMobileAppArgs = {
  companyId: Scalars['String']['input'];
};

export type TourCustomFieldsArgs = {
  includeDeliveries?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TourHasTransportNotificationsArgs = {
  companyId: Scalars['String']['input'];
};

export type TourOceanDemurrageArgs = {
  type?: InputMaybe<DemurrageType>;
};

export type TourRouteArgs = {
  limit_to_remaining?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TourTourAssignmentArgs = {
  company_id?: InputMaybe<Scalars['String']['input']>;
  tour_id?: InputMaybe<Scalars['Int']['input']>;
};

export type TourTourTrafficEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  apiName: TrafficApiName;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};

export type TourTransportNotificationsArgs = {
  companyId: Scalars['String']['input'];
};

export type TourTripsVehicleHistoryArgs = {
  simplificationTolerance?: InputMaybe<Scalars['Float']['input']>;
};

export type TourVehicleBreakHistoryArgs = {
  end_time: Scalars['String']['input'];
  start_time: Scalars['String']['input'];
};

export type TourVehicleHistoryArgs = {
  simplificationTolerance?: InputMaybe<Scalars['Float']['input']>;
};

export type TourVehicleHistoryListArgs = {
  end_time: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  simplification_tolerance?: InputMaybe<Scalars['Float']['input']>;
  start_time: Scalars['String']['input'];
};

export enum TourAllocationDeviceType {
  AIR = 'AIR',
  CONTAINER = 'CONTAINER',
  IOT_DEVICE = 'IOT_DEVICE',
  MOBILE = 'MOBILE',
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  TRAIN = 'TRAIN',
  VESSEL = 'VESSEL',
}

export type TourAllocationInput = {
  allocatedByCompanyId?: InputMaybe<Scalars['String']['input']>;
  allocationType: TourVehicleAllocationType;
  startTrackingAt?: InputMaybe<Scalars['DateTime']['input']>;
  stopAllocations?: InputMaybe<Array<StopAllocationInput>>;
  tourId: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export enum TourAllocationStatus {
  Allocated = 'Allocated',
  Unallocated = 'Unallocated',
}

export enum TourAllocationSwitchType {
  AUTO_SWITCH = 'AUTO_SWITCH',
}

export type TourAssignment = {
  __typename: 'TourAssignment';
  assignedToUser: Maybe<UserProfile>;
};

export type TourCalculatedEmissions = {
  __typename: 'TourCalculatedEmissions';
  cargo: Cargo;
  co2TankToWheelTonnes: Scalars['Float']['output'];
  co2WellToTankTonnes: Scalars['Float']['output'];
  co2WellToWheelTonnes: Scalars['Float']['output'];
  emissionsIntensityGramPerTonneKms: Maybe<Scalars['Float']['output']>;
  legs: Array<Leg>;
  routePolyline: Maybe<Scalars['String']['output']>;
  transportActivityTonneKms: Maybe<Scalars['Float']['output']>;
};

export enum TourCompanyRole {
  CARRIER = 'CARRIER',
  SHIPPER = 'SHIPPER',
}

export type TourConnection = {
  __typename: 'TourConnection';
  edges: Maybe<Array<Maybe<TourEdge>>>;
  pageInfo: PageInfo;
};

export type TourCountByFailureReason = {
  __typename: 'TourCountByFailureReason';
  count: Scalars['Int']['output'];
  failureReason: Maybe<TourFailureReasonType>;
};

export type TourCreationInput = {
  companyId: Scalars['String']['input'];
  creator_transport_number: Scalars['String']['input'];
  delivery: TourDeliveryInput;
  shipperId?: InputMaybe<Scalars['String']['input']>;
};

export type TourCustomFieldSearch = {
  fieldName: Scalars['String']['input'];
  value: TourCustomFieldSearchValue;
};

export type TourCustomFieldSearchType = {
  __typename: 'TourCustomFieldSearchType';
  fieldName: Scalars['String']['output'];
  value: TourCustomFieldSearchValueType;
};

export type TourCustomFieldSearchValue = {
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TourCustomFieldSearchValueType = {
  __typename: 'TourCustomFieldSearchValueType';
  /** @deprecated Use values instead */
  value: Maybe<Scalars['String']['output']>;
  values: Maybe<Array<Scalars['String']['output']>>;
};

export enum TourDelayStatusFilter {
  /** @deprecated Use DELAYED_ON_ANY instead */
  DELAYED = 'DELAYED',
  DELAYED_DELIVERY = 'DELAYED_DELIVERY',
  DELAYED_DELIVERY_AND_PICKUP = 'DELAYED_DELIVERY_AND_PICKUP',
  DELAYED_ON_ALL = 'DELAYED_ON_ALL',
  DELAYED_ON_ANY = 'DELAYED_ON_ANY',
  DELAYED_PICKUP = 'DELAYED_PICKUP',
  ON_TIME = 'ON_TIME',
  UNKNOWN = 'UNKNOWN',
}

export type TourDeletionInput = {
  companyId: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export type TourDelivery = {
  __typename: 'TourDelivery';
  customFields: Array<CustomField>;
  deliveryId: Scalars['String']['output'];
  deliveryNumber: Maybe<Scalars['String']['output']>;
  deliveryTourId: Maybe<Scalars['String']['output']>;
  incoterms: Maybe<TourIncoterms>;
  loadingLength: Maybe<TourLoadingLength>;
  mainMode: Maybe<MainMode>;
  orderDate: Maybe<Scalars['DateTime']['output']>;
  orderItems: Maybe<Array<OrderItem>>;
  orderNumber: Maybe<Scalars['String']['output']>;
  purchaseOrderNumber: Maybe<Scalars['String']['output']>;
  sequenceNumber: Maybe<Scalars['Int']['output']>;
  storagePositions: Maybe<Scalars['Int']['output']>;
  transportUnit: Maybe<TourTransportUnit>;
  volume: Maybe<TourVolume>;
};

export type TourDeliveryInput = {
  deliveryNumber?: InputMaybe<Scalars['String']['input']>;
  loading: TourStopInput;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  unloading: TourStopInput;
};

export type TourDeliveryTree = {
  __typename: 'TourDeliveryTree';
  affectedTourId: Scalars['String']['output'];
  customFields: Scalars['GraphQLJSON']['output'];
  deliveryNumber: Maybe<Scalars['String']['output']>;
  deliveryTreeId: Scalars['String']['output'];
  loadingStop: Maybe<DeliveryStop>;
  orderNumber: Maybe<Scalars['String']['output']>;
  parentDeliveryTreeId: Maybe<Scalars['String']['output']>;
  platformDeliveryId: Scalars['String']['output'];
  platformId: Scalars['String']['output'];
  platformPreviousDeliveryId: Maybe<Scalars['String']['output']>;
  platformSequenceNumber: Maybe<Scalars['Int']['output']>;
  purchaseOrderNumber: Maybe<Scalars['String']['output']>;
  rootDeliveryTreeId: Scalars['String']['output'];
  rootPlatformDeliveryId: Scalars['String']['output'];
  sequenceNumber: Scalars['Int']['output'];
  sourceTourId: Maybe<Scalars['String']['output']>;
  unloadingStop: Maybe<DeliveryStop>;
};

export type TourDemurrageDetentionLog = {
  __typename: 'TourDemurrageDetentionLog';
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  demurrageDetentionLogId: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
};

export type TourDistance = {
  __typename: 'TourDistance';
  totalDistanceInMeters: Scalars['Float']['output'];
};

export type TourDrivenDistance = {
  __typename: 'TourDrivenDistance';
  distanceInKilometers: Maybe<Scalars['Float']['output']>;
  source: Maybe<Scalars['String']['output']>;
};

export type TourEdge = {
  __typename: 'TourEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Tour>;
};

export type TourEmissionsReceived = {
  __typename: 'TourEmissionsReceived';
  epKgCo2: Maybe<Scalars['Float']['output']>;
};

export type TourEvent = {
  __typename: 'TourEvent';
  created_at: Scalars['DateTime']['output'];
  event_id: Scalars['Int']['output'];
  event_index: Scalars['Int']['output'];
  event_name: TourEventName;
  event_time: Maybe<Scalars['DateTime']['output']>;
  metadata: Maybe<TourEventMetadata>;
  tour_id: Scalars['Int']['output'];
  vehicle_id: Maybe<Scalars['String']['output']>;
  vehicle_lat: Maybe<Scalars['Float']['output']>;
  vehicle_license_plate_number: Maybe<Scalars['String']['output']>;
  vehicle_lng: Maybe<Scalars['Float']['output']>;
  visibilitySource: Maybe<TourVisibilitySource>;
  warning: Maybe<TourWarning>;
};

export type TourEventMetadata = {
  __typename: 'TourEventMetadata';
  algorithm: Maybe<Scalars['String']['output']>;
  parameters: Maybe<Scalars['GraphQLJSON']['output']>;
};

export enum TourEventName {
  ABANDON = 'ABANDON',
  ABORT = 'ABORT',
  /** @deprecated No longer supported */
  AT_STOP = 'AT_STOP',
  COMPLETE = 'COMPLETE',
  /** @deprecated No longer supported */
  COMPLETED = 'COMPLETED',
  DEALLOCATION = 'DEALLOCATION',
  DELETE = 'DELETE',
  /** @deprecated No longer supported */
  EN_ROUTE = 'EN_ROUTE',
  EVENT_STREAM_STARTED = 'EVENT_STREAM_STARTED',
  FAILED = 'FAILED',
  SHIPPER_VISIBLE = 'SHIPPER_VISIBLE',
  TRACKING_START = 'TRACKING_START',
  TRUCK_ALLOCATION = 'TRUCK_ALLOCATION',
  VISIBLE_TRACKING_START = 'VISIBLE_TRACKING_START',
  WARNING_ENDED = 'WARNING_ENDED',
  WARNING_TRIGGERED = 'WARNING_TRIGGERED',
}

export type TourException = {
  __typename: 'TourException';
  createdAt: Scalars['DateTime']['output'];
  customExceptionType: Maybe<Scalars['String']['output']>;
  customExceptionTypeDescription: Maybe<Scalars['String']['output']>;
  deliveryIds: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  exceptionFiles: Array<ExceptionFile>;
  exceptionId: Scalars['String']['output'];
  exceptionType: Maybe<ExceptionType>;
  stopId: Maybe<Scalars['String']['output']>;
};

export type TourExternalTracking = {
  __typename: 'TourExternalTracking';
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  status: Maybe<Scalars['String']['output']>;
  statusReason: Maybe<Scalars['String']['output']>;
  tourId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TourExternalTrackingLog = {
  __typename: 'TourExternalTrackingLog';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
};

export type TourExternalTrackingRequest = {
  __typename: 'TourExternalTrackingRequest';
  createdAt: Scalars['String']['output'];
  requestPayload: Scalars['String']['output'];
  responsePayload: Maybe<Scalars['String']['output']>;
  responseStatus: Maybe<Scalars['String']['output']>;
  tourId: Scalars['String']['output'];
  trackingRequestId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type TourFailureReason = {
  __typename: 'TourFailureReason';
  metadata: Maybe<TourFailureReasonMetadata>;
  type: TourFailureReasonType;
};

export type TourFailureReasonMetadata = {
  __typename: 'TourFailureReasonMetadata';
  allocated_license_plates: Maybe<Scalars['String']['output']>;
  nr_of_stops_visited: Maybe<Scalars['String']['output']>;
};

export enum TourFailureReasonType {
  ABORTED = 'ABORTED',
  /** @deprecated No longer produced */
  BAD_TELEMETRY = 'BAD_TELEMETRY',
  /** @deprecated No longer produced */
  BAD_TELEMETRY_BAD_DATA = 'BAD_TELEMETRY_BAD_DATA',
  BAD_TELEMETRY_NO_MOVEMENT = 'BAD_TELEMETRY_NO_MOVEMENT',
  /** @deprecated No longer produced */
  BAD_TELEMETRY_SHORT_VISIBILITY = 'BAD_TELEMETRY_SHORT_VISIBILITY',
  /** @deprecated No longer produced */
  BAD_TELEMETRY_SMALL_MOVEMENT = 'BAD_TELEMETRY_SMALL_MOVEMENT',
  BAD_TOUR_DATA = 'BAD_TOUR_DATA',
  /** @deprecated No longer produced */
  BAD_TOUR_DATA_SHORT_DISTANCE = 'BAD_TOUR_DATA_SHORT_DISTANCE',
  /** @deprecated No longer produced */
  CARRIER_NOT_INTEGRATED = 'CARRIER_NOT_INTEGRATED',
  CARRIER_NOT_REGISTERED = 'CARRIER_NOT_REGISTERED',
  /** @deprecated No longer produced */
  CARRIER_NOT_SPECIFIED = 'CARRIER_NOT_SPECIFIED',
  DATA_SHARING_ISSUE = 'DATA_SHARING_ISSUE',
  /** @deprecated No longer produced */
  GEOCODING_SERVICE_FAILED = 'GEOCODING_SERVICE_FAILED',
  INSUFFICIENT_ALLOCATION = 'INSUFFICIENT_ALLOCATION',
  /** @deprecated No longer produced */
  MISSING_START_TELEMETRY = 'MISSING_START_TELEMETRY',
  MISSING_STOP_GEOCODING = 'MISSING_STOP_GEOCODING',
  MISSING_STOP_TIMESLOT = 'MISSING_STOP_TIMESLOT',
  MISSING_TELEMETRY = 'MISSING_TELEMETRY',
  NO_VEHICLE_ALLOCATED = 'NO_VEHICLE_ALLOCATED',
  /** @deprecated No longer produced */
  SIXFOLD_INCIDENT = 'SIXFOLD_INCIDENT',
  TRACTOR_SWITCH_NOT_DETECTED = 'TRACTOR_SWITCH_NOT_DETECTED',
  /** @deprecated No longer produced */
  UNSPECIFIED = 'UNSPECIFIED',
  /** @deprecated No longer produced */
  VEHICLE_NOT_INTEGRATED = 'VEHICLE_NOT_INTEGRATED',
  VISIBILITY_THRESHOLD_EXCEEDED = 'VISIBILITY_THRESHOLD_EXCEEDED',
  /** @deprecated No longer produced */
  WRONG_PLATFORM_COORDINATES = 'WRONG_PLATFORM_COORDINATES',
}

export type TourFilter = {
  __typename: 'TourFilter';
  airWayBillNumbers: Maybe<Array<Scalars['String']['output']>>;
  allocationStatus: Maybe<TourAllocationStatus>;
  assignedToUserIds: Maybe<Array<Scalars['String']['output']>>;
  billOfLadingNumbers: Maybe<Array<Scalars['String']['output']>>;
  bookingNumbers: Maybe<Array<Scalars['String']['output']>>;
  carrierCodes: Maybe<Array<Scalars['String']['output']>>;
  carrierIds: Maybe<Array<Scalars['String']['output']>>;
  carrierNames: Maybe<Array<Scalars['String']['output']>>;
  containerNumbers: Maybe<Array<Scalars['String']['output']>>;
  createdAtEnd: Maybe<Scalars['DateTime']['output']>;
  createdAtStart: Maybe<Scalars['DateTime']['output']>;
  customFields: Maybe<Array<TourCustomFieldSearchType>>;
  delayStatus: Maybe<TourDelayStatusFilter>;
  delayTime: Maybe<TimeRange>;
  demurrage: Maybe<OceanDemurrageFilterOutput>;
  documentTypes: Maybe<Array<DocumentType>>;
  endTimeEnd: Maybe<Scalars['DateTime']['output']>;
  endTimeStart: Maybe<Scalars['DateTime']['output']>;
  endTimeSugarTimezone: Maybe<Scalars['IANATimezone']['output']>;
  exceptionTypes: Maybe<Array<Scalars['String']['output']>>;
  failureReasons: Maybe<Array<TourFailureReasonType>>;
  flightNumbers: Maybe<Array<Scalars['String']['output']>>;
  hasNotes: Maybe<Scalars['Boolean']['output']>;
  impactDays: Maybe<ImpactDaysOutput>;
  lastSignalTimestamp: Maybe<LastSignalTimestampFilter>;
  locations: Maybe<Array<TransportLocationOutput>>;
  mainCarrierIds: Maybe<Array<Scalars['String']['output']>>;
  mainVisibilityIssues: Maybe<Array<VisibilityIssueType>>;
  orderItemCustomFields: Maybe<OrderItemCustomFieldOutput>;
  orderItemGroups: Maybe<OrderItemGroupOutput>;
  orderItemNames: Maybe<Array<Scalars['String']['output']>>;
  placeIds: Maybe<Array<Scalars['String']['output']>>;
  platformId: Maybe<Scalars['String']['output']>;
  shipmentType: Maybe<Array<ShipmentTypeFilter>>;
  shipperIds: Maybe<Array<Scalars['String']['output']>>;
  siteIds: Maybe<Array<Scalars['String']['output']>>;
  sortOrder: Maybe<TourSortOrderType>;
  startTimeEnd: Maybe<Scalars['DateTime']['output']>;
  startTimeStart: Maybe<Scalars['DateTime']['output']>;
  startTimeSugarTimezone: Maybe<Scalars['IANATimezone']['output']>;
  status: Maybe<TourStatusFilter>;
  statuses: Maybe<Array<TourStatusFilter>>;
  stopBookingLocationNames: Maybe<Array<Scalars['String']['output']>>;
  stopCompanyNames: Maybe<Array<Scalars['String']['output']>>;
  stopCountries: Maybe<Array<Scalars['String']['output']>>;
  stopFilters: Maybe<Array<StopFilterOutput>>;
  stopStates: Maybe<Array<CompanyStopStates>>;
  textSearch: Maybe<TourTextSearchType>;
  timeSpentAtStop: Maybe<TimeRange>;
  transportAlerts: Maybe<TransportAlertsFilterOutput>;
  transportGroupIds: Maybe<Array<Scalars['String']['output']>>;
  transportMode: Maybe<Array<TourFilteringTransportMode>>;
  vehicleId: Maybe<Scalars['String']['output']>;
  vesselName: Maybe<VesselNameFilterOutput>;
  voyageIds: Maybe<Array<Scalars['String']['output']>>;
  warnings: Maybe<Array<TourWarningType>>;
  workingStopStatus: Maybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd: Maybe<Scalars['DateTime']['output']>;
};

export type TourFilterInput = {
  airWayBillNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  billOfLadingNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  bookingNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierNames?: InputMaybe<Array<Scalars['String']['input']>>;
  containerNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  delayTime?: InputMaybe<TimeRangeInput>;
  demurrage?: InputMaybe<OceanDemurrageFilter>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  filterV2?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  flightNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  hasNotes?: InputMaybe<Scalars['Boolean']['input']>;
  impactDays?: InputMaybe<ImpactDaysFilter>;
  lastSignalTimestamp?: InputMaybe<LastSignalTimestampFilterInput>;
  locations?: InputMaybe<Array<TransportLocation>>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  orderItemGroups?: InputMaybe<OrderItemGroupsInput>;
  orderItemNames?: InputMaybe<Array<Scalars['String']['input']>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  platformBranchId?: InputMaybe<Scalars['String']['input']>;
  platformId?: InputMaybe<Scalars['String']['input']>;
  shipmentType?: InputMaybe<Array<ShipmentTypeFilter>>;
  shipperId?: InputMaybe<Scalars['String']['input']>;
  shipperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']['input']>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
  textSearch?: InputMaybe<TourTextSearch>;
  timeSpentAtStop?: InputMaybe<TimeRangeInput>;
  transportAlerts?: InputMaybe<TransportAlertsFilterInput>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  tripsSearch?: InputMaybe<TripsTextSearch>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  voyageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  warnings?: InputMaybe<Array<TourWarningType>>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum TourFilteringTransportMode {
  Air = 'Air',
  Ocean = 'Ocean',
  Road = 'Road',
}

export type TourForwardingLink = {
  __typename: 'TourForwardingLink';
  forwardedAt: Scalars['DateTime']['output'];
  forwardedBy: Maybe<UserProfile>;
  from: Maybe<CompanyProfile>;
  to: CompanyProfile;
  triggeredByAllocationId: Maybe<Scalars['String']['output']>;
};

export type TourIncoterms = {
  __typename: 'TourIncoterms';
  city: Maybe<Scalars['String']['output']>;
  term: Maybe<Scalars['String']['output']>;
};

export type TourLoadingLength = {
  __typename: 'TourLoadingLength';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type TourLocation = {
  __typename: 'TourLocation';
  address: Maybe<Address>;
  bookingLocationName: Maybe<Scalars['String']['output']>;
  customerProvidedAddress: Maybe<Address>;
  gate: Maybe<Scalars['String']['output']>;
  gate_id: Maybe<Scalars['String']['output']>;
  location_id: Maybe<Scalars['String']['output']>;
  location_reference_id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  place: Maybe<CompanyPlace>;
  position: Maybe<GeoPosition>;
};

export enum TourRemoteAllocationSource {
  PLATFORM_CARRIER_INTERFACE = 'PLATFORM_CARRIER_INTERFACE',
  PLATFORM_UI = 'PLATFORM_UI',
}

export type TourSignal = {
  __typename: 'TourSignal';
  source: TourSignalSource;
  timestamp: Scalars['DateTime']['output'];
};

export enum TourSignalSource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
  TTC_MOBILE = 'TTC_MOBILE',
}

export enum TourSortKey {
  ARRIVAL_TO_POD = 'ARRIVAL_TO_POD',
  CARRIER_NAME = 'CARRIER_NAME',
  DEPARTURE_FROM_POL = 'DEPARTURE_FROM_POL',
  SHIPPER_NAME = 'SHIPPER_NAME',
  START_TIME = 'START_TIME',
  VEHICLE_LICENSE_PLATE_NUMBER = 'VEHICLE_LICENSE_PLATE_NUMBER',
  WORKING_STOP_TIMESLOT_BEGIN = 'WORKING_STOP_TIMESLOT_BEGIN',
}

export type TourSortOrder = {
  direction: SortDirection;
  key: TourSortKey;
};

export type TourSortOrderType = {
  __typename: 'TourSortOrderType';
  direction: SortDirection;
  key: TourSortKey;
};

export enum TourStatusFilter {
  ABANDONED = 'ABANDONED',
  ABORTED = 'ABORTED',
  ALL = 'ALL',
  ALLOCATED = 'ALLOCATED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  UNALLOCATED = 'UNALLOCATED',
  UNFINISHED = 'UNFINISHED',
  UPCOMING = 'UPCOMING',
  WAITING = 'WAITING',
  WORKING = 'WORKING',
}

export type TourStop = {
  __typename: 'TourStop';
  actual_sequence_number: Maybe<Scalars['Int']['output']>;
  arrival_delay_in_seconds: Maybe<Scalars['Int']['output']>;
  arrival_time: Maybe<Scalars['DateTime']['output']>;
  booking_id: Maybe<Scalars['String']['output']>;
  deadline: Maybe<Scalars['DateTime']['output']>;
  deliveries: Array<TourDelivery>;
  delivery_ids: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  departure_time: Maybe<Scalars['DateTime']['output']>;
  display_sequence_number: Scalars['Int']['output'];
  documents: Array<Document>;
  estimated_arrival: Maybe<Scalars['DateTime']['output']>;
  estimated_arrival_accuracy: Maybe<Scalars['String']['output']>;
  estimated_arrival_delay_in_seconds: Maybe<Scalars['Int']['output']>;
  estimated_arrival_distance_in_meters: Maybe<Scalars['Int']['output']>;
  estimated_arrival_regulated: Maybe<Scalars['DateTime']['output']>;
  estimated_arrival_status: Maybe<Scalars['String']['output']>;
  estimated_arrival_unregulated: Maybe<Scalars['DateTime']['output']>;
  etaType: Maybe<EtaType>;
  etas: Maybe<Array<SingleTourStopEstimate>>;
  exceptions: Array<TourException>;
  expected_duration: Maybe<Scalars['String']['output']>;
  externalEtaSource: Maybe<ExternalEtaSource>;
  geofenceZones: Array<GeofenceZone>;
  location: Maybe<TourLocation>;
  notes: Array<Note>;
  place_reference_id: Maybe<Scalars['String']['output']>;
  platformSequenceNumber: Maybe<Scalars['Int']['output']>;
  sequence_number: Maybe<Scalars['Int']['output']>;
  sharingToken: Maybe<Scalars['String']['output']>;
  sharingTokenV2: Maybe<SharingToken>;
  status: Maybe<Scalars['String']['output']>;
  status_events: Array<StopEvent>;
  stop_id: Scalars['Int']['output'];
  stop_reference_id: Maybe<Scalars['String']['output']>;
  timeslot: Maybe<Timeslot>;
  tour_id: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
  vehicleAllocation: Maybe<VehicleAllocation>;
};

export type TourStopSharingTokenArgs = {
  company_id: Scalars['String']['input'];
};

export type TourStopSharingTokenV2Args = {
  company_id: Scalars['String']['input'];
};

export type TourStopInput = {
  location: StopLocationInput;
  timeslot: StopTimeslotInput;
  type: StopType;
};

export type TourTag = {
  __typename: 'TourTag';
  name: Scalars['String']['output'];
};

export type TourTextSearch = {
  field: TourTextSearchField;
  query: Scalars['String']['input'];
};

export enum TourTextSearchField {
  ALL = 'ALL',
  ALL_EXTENDED = 'ALL_EXTENDED',
  BILL_OF_LADING = 'BILL_OF_LADING',
  DELIVERY_NUMBER = 'DELIVERY_NUMBER',
  DELIVERY_ORDER_NUMBER = 'DELIVERY_ORDER_NUMBER',
  DELIVERY_PURCHASE_ORDER_NUMBER = 'DELIVERY_PURCHASE_ORDER_NUMBER',
  PLATFORM_TRANSPORT_NUMBER = 'PLATFORM_TRANSPORT_NUMBER',
  SHIPPER_TRANSPORT_NUMBER = 'SHIPPER_TRANSPORT_NUMBER',
  STOP_ADDRESS = 'STOP_ADDRESS',
  STOP_NAME = 'STOP_NAME',
  TOUR_ID = 'TOUR_ID',
  VEHICLE_LICENSE_PLATE_NUMBER = 'VEHICLE_LICENSE_PLATE_NUMBER',
}

export type TourTextSearchType = {
  __typename: 'TourTextSearchType';
  field: TourTextSearchField;
  query: Scalars['String']['output'];
};

export type TourTrafficEntry = {
  __typename: 'TourTrafficEntry';
  entry: Maybe<Scalars['GraphQLJSON']['output']>;
  timestamp: Maybe<Scalars['DateTime']['output']>;
};

export type TourTrafficEntryConnection = {
  __typename: 'TourTrafficEntryConnection';
  edges: Maybe<Array<Maybe<TourTrafficEntryEdge>>>;
  pageInfo: PageInfo;
};

export type TourTrafficEntryEdge = {
  __typename: 'TourTrafficEntryEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<TourTrafficEntry>;
};

export type TourTransportUnit = {
  __typename: 'TourTransportUnit';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export enum TourType {
  DELIVERY_TOUR = 'DELIVERY_TOUR',
  PARENT_TOUR = 'PARENT_TOUR',
}

export type TourUpdateInput = {
  companyId: Scalars['String']['input'];
  creator_transport_number: Scalars['String']['input'];
  delivery: TourDeliveryInput;
  shipperId?: InputMaybe<Scalars['String']['input']>;
  tourId: Scalars['String']['input'];
};

export type TourVehicleAllocation = {
  __typename: 'TourVehicleAllocation';
  allocatedAt: Maybe<Scalars['DateTime']['output']>;
  allocatedByCompany: Maybe<CompanyProfile>;
  allocationDeviceType: Maybe<TourAllocationDeviceType>;
  allocationId: Maybe<Scalars['String']['output']>;
  allocationSwitchType: Maybe<TourAllocationSwitchType>;
  allocationType: Maybe<TourVehicleAllocationType>;
  clearAllocation: Maybe<Scalars['Boolean']['output']>;
  disableVehicleMatching: Maybe<Scalars['Boolean']['output']>;
  remoteAllocationSource: Maybe<TourRemoteAllocationSource>;
  remoteTrackerId: Maybe<Scalars['String']['output']>;
  source: TourVehicleAllocationSource;
  startTrackingAt: Maybe<Scalars['DateTime']['output']>;
  stopAllocations: Maybe<Array<StopAllocation>>;
  vehicleId: Maybe<Scalars['String']['output']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']['output']>;
  vehicleTrackerTypes: Maybe<Array<VehicleTrackerType>>;
};

export enum TourVehicleAllocationSource {
  CARRIER_API = 'CARRIER_API',
  PLATFORM = 'PLATFORM',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  PLATFORM_VERSIONED = 'PLATFORM_VERSIONED',
  SIXFOLD = 'SIXFOLD',
  TOUR_BASED_STREAM = 'TOUR_BASED_STREAM',
  WEB_UI = 'WEB_UI',
}

export enum TourVehicleAllocationType {
  STOP_ALLOCATION = 'STOP_ALLOCATION',
  TOUR_ALLOCATION = 'TOUR_ALLOCATION',
}

export type TourVehicleAllocationsInput = {
  allocatedByCompanyId?: InputMaybe<Scalars['String']['input']>;
  tourId: Scalars['String']['input'];
  vehicleAllocations: Array<VehicleAllocationInput>;
};

export enum TourVisibilityPrecisionType {
  EVENT = 'EVENT',
  GPS = 'GPS',
}

export type TourVisibilitySource = {
  __typename: 'TourVisibilitySource';
  eventOrigin: Maybe<Scalars['String']['output']>;
  primaryEventSource: TourVisibilitySourceType;
  sourceCompany: Maybe<CompanyProfile>;
  sourcePlatform: Maybe<PlatformProfile>;
  streamPrecision: Maybe<TourVisibilityPrecisionType>;
};

export enum TourVisibilitySourceType {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  IOT_TRACKER = 'IOT_TRACKER',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
  TTC_MOBILE = 'TTC_MOBILE',
}

export type TourVolume = {
  __typename: 'TourVolume';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type TourWarning = {
  __typename: 'TourWarning';
  metadata: Maybe<Scalars['GraphQLJSON']['output']>;
  severity: Maybe<TourWarningSeverity>;
  type: TourWarningType;
};

export enum TourWarningSeverity {
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum TourWarningType {
  ETA_AND_STATUSES_ONLY = 'ETA_AND_STATUSES_ONLY',
  ETA_EXPECT_BORDER_RELATED_DELAYS = 'ETA_EXPECT_BORDER_RELATED_DELAYS',
  ETA_PENDING_TRACTOR_SWITCH = 'ETA_PENDING_TRACTOR_SWITCH',
  ETA_PREREQUISITE_DELIVERY_DELAYED = 'ETA_PREREQUISITE_DELIVERY_DELAYED',
  ETA_STALE_VEHICLE_STATUS = 'ETA_STALE_VEHICLE_STATUS',
  LAST_MILESTONE_IS_NOT_COMPLETED = 'LAST_MILESTONE_IS_NOT_COMPLETED',
  STATUS_BASED_ON_PLATFORM_EVENTS = 'STATUS_BASED_ON_PLATFORM_EVENTS',
  STATUS_BREAK_OUTSIDE_OF_SECURED_PARKING_STOPS = 'STATUS_BREAK_OUTSIDE_OF_SECURED_PARKING_STOPS',
  STATUS_EARLY_LOADING = 'STATUS_EARLY_LOADING',
  STATUS_INVALID_TIMESLOT_DATA = 'STATUS_INVALID_TIMESLOT_DATA',
  STATUS_NO_DEPARTURE_TIME = 'STATUS_NO_DEPARTURE_TIME',
  STATUS_NO_ETA_TRANSPORT_MODE = 'STATUS_NO_ETA_TRANSPORT_MODE',
  STATUS_POSSIBLE_WRONG_VEHICLE = 'STATUS_POSSIBLE_WRONG_VEHICLE',
  STATUS_PREREQUISITE_DELIVERY_NOT_COMPLETED = 'STATUS_PREREQUISITE_DELIVERY_NOT_COMPLETED',
  STATUS_STOPS_OUT_OF_SEQUENCE = 'STATUS_STOPS_OUT_OF_SEQUENCE',
  STATUS_STOP_MISSING_GEOCODING = 'STATUS_STOP_MISSING_GEOCODING',
  STATUS_STOP_MISSING_TIMESLOT = 'STATUS_STOP_MISSING_TIMESLOT',
  STATUS_TELEMETRY_CONTAINS_LONG_JUMPS = 'STATUS_TELEMETRY_CONTAINS_LONG_JUMPS',
  STATUS_TRANSPORT_EVENT_TELEMETRY_MISMATCH = 'STATUS_TRANSPORT_EVENT_TELEMETRY_MISMATCH',
  STATUS_UNABLE_TO_REACH_SECURED_PARKING_STOPS_WITHIN_DEADLINE = 'STATUS_UNABLE_TO_REACH_SECURED_PARKING_STOPS_WITHIN_DEADLINE',
  STATUS_UNUSED_ALLOCATION = 'STATUS_UNUSED_ALLOCATION',
  STATUS_VEHICLE_DOING_ANOTHER_TOUR = 'STATUS_VEHICLE_DOING_ANOTHER_TOUR',
  STATUS_VEHICLE_NOT_INTEGRATED = 'STATUS_VEHICLE_NOT_INTEGRATED',
  TELEMETRY_MISSING_AT_STOP = 'TELEMETRY_MISSING_AT_STOP',
  UNSTABLE_ETA_HIDDEN = 'UNSTABLE_ETA_HIDDEN',
}

export type ToursCountByDelayStatus = {
  __typename: 'ToursCountByDelayStatus';
  count: Scalars['Int']['output'];
  delayStatus: Maybe<TourDelayStatusFilter>;
};

export type ToursCountByStatus = {
  __typename: 'ToursCountByStatus';
  countByFailureReason: Array<TourCountByFailureReason>;
  countByTourDelayStatus: Array<ToursCountByDelayStatus>;
  totalCount: Scalars['Int']['output'];
};

export type TrackingSegment = {
  __typename: 'TrackingSegment';
  trackingEndDate: Scalars['DateTime']['output'];
  trackingStartDate: Scalars['DateTime']['output'];
  vehicleId: Scalars['String']['output'];
};

export enum TrafficApiName {
  CREATE_OR_UPDATE_TRANSPORT = 'CREATE_OR_UPDATE_TRANSPORT',
  CREATE_OR_UPDATE_VEHICLE_ALLOCATION = 'CREATE_OR_UPDATE_VEHICLE_ALLOCATION',
  DELETE_TRANSPORT = 'DELETE_TRANSPORT',
  FORWARD_TRANSPORT = 'FORWARD_TRANSPORT',
  STATUS_UPDATE_TRANSPORT = 'STATUS_UPDATE_TRANSPORT',
}

export type TransportAlert = {
  __typename: 'TransportAlert';
  alertId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  severity: TransportAlertSeverity;
  type: TransportAlertType;
};

export enum TransportAlertSeverity {
  BLOCKER = 'BLOCKER',
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum TransportAlertType {
  CARRIER_DATA_FETCHED_USING_CONTAINER_NUMBER = 'CARRIER_DATA_FETCHED_USING_CONTAINER_NUMBER',
  CARRIER_NOT_FOUND = 'CARRIER_NOT_FOUND',
  CARRIER_NO_DATA = 'CARRIER_NO_DATA',
  CARRIER_REFERENCE_NOT_ACCEPTED = 'CARRIER_REFERENCE_NOT_ACCEPTED',
  CARRIER_UNSUPPORTED = 'CARRIER_UNSUPPORTED',
  CHANGE_IN_POD = 'CHANGE_IN_POD',
  CHANGE_IN_POL = 'CHANGE_IN_POL',
  CONTAINER_DELETED = 'CONTAINER_DELETED',
  CONTAINER_ROLLOVER_AT_POL = 'CONTAINER_ROLLOVER_AT_POL',
  CONTAINER_ROLLOVER_AT_TSP = 'CONTAINER_ROLLOVER_AT_TSP',
  DELAYED_ARRIVAL_AT_DESTINATION = 'DELAYED_ARRIVAL_AT_DESTINATION',
  DELAYED_ARRIVAL_AT_POD = 'DELAYED_ARRIVAL_AT_POD',
  DELAYED_DEPARTURE_FROM_POL = 'DELAYED_DEPARTURE_FROM_POL',
  DEVIATION_LOCATION_GROUP = 'DEVIATION_LOCATION_GROUP',
  DEVIATION_ROUTE = 'DEVIATION_ROUTE',
  INVALID_SHIPMENT_REFERENCE_BN = 'INVALID_SHIPMENT_REFERENCE_BN',
  INVALID_SHIPMENT_REFERENCE_BOL = 'INVALID_SHIPMENT_REFERENCE_BOL',
  PACKAGE_COUNT_MISMATCH = 'PACKAGE_COUNT_MISMATCH',
  POST_CARRIAGE_IN_DIFFERENT_CONTINENTS = 'POST_CARRIAGE_IN_DIFFERENT_CONTINENTS',
  POTENTIALLY_INCORRECT_ROUTE = 'POTENTIALLY_INCORRECT_ROUTE',
  PRE_CARRIAGE_IN_DIFFERENT_CONTINENTS = 'PRE_CARRIAGE_IN_DIFFERENT_CONTINENTS',
  REFERENCE_UPDATE = 'REFERENCE_UPDATE',
  UNKNOWN = 'UNKNOWN',
}

export type TransportAlertsFilterInput = {
  severities?: InputMaybe<Array<TransportAlertSeverity>>;
  types?: InputMaybe<Array<TransportAlertType>>;
};

export type TransportAlertsFilterOutput = {
  __typename: 'TransportAlertsFilterOutput';
  severities: Maybe<Array<TransportAlertSeverity>>;
  types: Maybe<Array<TransportAlertType>>;
};

export enum TransportIdentifier {
  BILL_OF_LADING = 'BILL_OF_LADING',
  DELIVERY_NUMBER = 'DELIVERY_NUMBER',
  ORDER_NUMBER = 'ORDER_NUMBER',
  PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
  TRANSPORT_NUMBER = 'TRANSPORT_NUMBER',
}

export enum TransportIdentifierType {
  CONTAINER = 'CONTAINER',
  DELIVERY = 'DELIVERY',
  IMO = 'IMO',
  LICENSE_PLATE = 'LICENSE_PLATE',
  MMSI = 'MMSI',
}

export type TransportLocation = {
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iatas?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  milestones?: InputMaybe<Array<InputMaybe<LocationMilestone>>>;
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TransportLocationOutput = {
  __typename: 'TransportLocationOutput';
  countries: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  iatas: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  locodes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  milestones: Maybe<Array<Maybe<LocationMilestoneOutput>>>;
  names: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  types: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TransportMethod = {
  __typename: 'TransportMethod';
  identifiers: Maybe<Array<TripTransportIdentifier>>;
  mode: TripTransportMode;
  name: Maybe<Scalars['String']['output']>;
};

export enum TransportMode {
  AIR = 'AIR',
  MULTIMODAL = 'MULTIMODAL',
  RAIL = 'RAIL',
  RIVER = 'RIVER',
  ROAD = 'ROAD',
  SEA = 'SEA',
  UNKNOWN = 'UNKNOWN',
}

export type TransportNetworkCustomFieldSearchType = {
  __typename: 'TransportNetworkCustomFieldSearchType';
  fieldName: Scalars['String']['output'];
  value: TransportNetworkCustomFieldSearchValueType;
};

export type TransportNetworkCustomFieldSearchValueType = {
  __typename: 'TransportNetworkCustomFieldSearchValueType';
  /** @deprecated Use values instead */
  value: Maybe<Scalars['String']['output']>;
  values: Maybe<Array<Scalars['String']['output']>>;
};

export type TransportNetworkFilter = {
  __typename: 'TransportNetworkFilter';
  carrierIds: Maybe<Array<Scalars['String']['output']>>;
  customFields: Maybe<Array<TransportNetworkCustomFieldSearchType>>;
  endTimeEnd: Maybe<Scalars['DateTime']['output']>;
  endTimeStart: Maybe<Scalars['DateTime']['output']>;
  mainVisibilityIssues: Maybe<Array<VisibilityIssueType>>;
  onboardingStatus: Maybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  placeIds: Maybe<Array<Scalars['String']['output']>>;
  relationIdentifierIds: Maybe<Array<Scalars['String']['output']>>;
  shipperIds: Maybe<Array<Scalars['String']['output']>>;
  siteIds: Maybe<Array<Scalars['String']['output']>>;
  startTimeEnd: Scalars['DateTime']['output'];
  startTimeStart: Scalars['DateTime']['output'];
  stopBookingLocationNames: Maybe<Array<Scalars['String']['output']>>;
  stopCountries: Maybe<Array<Scalars['String']['output']>>;
  stopFilters: Maybe<Array<StopFilterOutput>>;
  stopStates: Maybe<Array<CompanyStopStates>>;
};

export type TripDeliveryItem = {
  __typename: 'TripDeliveryItem';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: TransportIdentifierType;
};

export type TripLocation = {
  __typename: 'TripLocation';
  country: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identifiers: Maybe<Array<LocationIdentifier>>;
  name: Scalars['String']['output'];
  position: Maybe<Position>;
  type: Maybe<TripLocationType>;
};

export enum TripLocationType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  DESTINATION = 'DESTINATION',
  EMPTY_CONTAINER_DELIVERY = 'EMPTY_CONTAINER_DELIVERY',
  EMPTY_CONTAINER_GATE_IN = 'EMPTY_CONTAINER_GATE_IN',
  EMPTY_CONTAINER_GATE_OUT = 'EMPTY_CONTAINER_GATE_OUT',
  EMPTY_CONTAINER_PICKUP = 'EMPTY_CONTAINER_PICKUP',
  EMPTY_POD = 'EMPTY_POD',
  EMPTY_POL = 'EMPTY_POL',
  FULL_CONTAINER_GATE_IN = 'FULL_CONTAINER_GATE_IN',
  FULL_CONTAINER_GATE_OUT = 'FULL_CONTAINER_GATE_OUT',
  GATEWAY = 'GATEWAY',
  IN_TRANSIT_DESTINATION = 'IN_TRANSIT_DESTINATION',
  IN_TRANSIT_ORIGIN = 'IN_TRANSIT_ORIGIN',
  LAYOVER = 'LAYOVER',
  LOADING = 'LOADING',
  ORIGIN = 'ORIGIN',
  OTHER = 'OTHER',
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
  TRANSSHIPMENT = 'TRANSSHIPMENT',
  TRANSSHIPMENT_PORT = 'TRANSSHIPMENT_PORT',
  UNLOADING = 'UNLOADING',
}

export type TripLocationWithMilestones = {
  __typename: 'TripLocationWithMilestones';
  location: Maybe<TripLocation>;
  milestones: Array<TripMilestone>;
};

export type TripMilestone = {
  __typename: 'TripMilestone';
  actualTime: Maybe<DateTimeWithTimezone>;
  deliveryItemIds: Maybe<Array<Scalars['String']['output']>>;
  estimatedTime: Maybe<DateTimeWithTimezone>;
  name: Scalars['String']['output'];
  qualifier: Scalars['String']['output'];
  scheduledTime: Maybe<DateTimeWithTimezone>;
};

export type TripSegment = {
  __typename: 'TripSegment';
  id: Scalars['String']['output'];
  locations: Maybe<Array<TripLocationWithMilestones>>;
  polylines: Maybe<Array<TripSegmentPolyline>>;
  previousTripIds: Maybe<Array<Scalars['String']['output']>>;
  transportMethod: Maybe<TransportMethod>;
};

export type TripSegmentPolyline = {
  __typename: 'TripSegmentPolyline';
  endLocationId: Scalars['String']['output'];
  polyline: Polyline;
  startLocationId: Scalars['String']['output'];
};

export type TripTransportIdentifier = {
  __typename: 'TripTransportIdentifier';
  type: TransportIdentifierType;
  value: Scalars['String']['output'];
};

export enum TripTransportMode {
  BARGE = 'BARGE',
  PLANE = 'PLANE',
  RAIL = 'RAIL',
  ROAD = 'ROAD',
  UNKNOWN = 'UNKNOWN',
  VESSEL = 'VESSEL',
}

export type TripVesselNames = {
  __typename: 'TripVesselNames';
  pod: Maybe<Scalars['String']['output']>;
  podVoyageId: Maybe<Scalars['String']['output']>;
  pol: Maybe<Scalars['String']['output']>;
  polVoyageId: Maybe<Scalars['String']['output']>;
  voyageId: Maybe<Scalars['String']['output']>;
};

export enum TripsIdentifierType {
  air_way_bill = 'air_way_bill',
  bill_of_lading = 'bill_of_lading',
  booking_number = 'booking_number',
  carrier_code = 'carrier_code',
  carrier_name = 'carrier_name',
  container_number = 'container_number',
  flight_number = 'flight_number',
  ocean_demurrage_currency = 'ocean_demurrage_currency',
  package_count = 'package_count',
  vessel_name = 'vessel_name',
  voyage_id = 'voyage_id',
}

export type TripsOption = {
  __typename: 'TripsOption';
  value: Scalars['String']['output'];
};

export type TripsOptionConnection = {
  __typename: 'TripsOptionConnection';
  edges: Maybe<Array<Maybe<TripsOptionEdge>>>;
  pageInfo: PageInfo;
};

export type TripsOptionEdge = {
  __typename: 'TripsOptionEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<TripsOption>;
};

export type TripsTextSearch = {
  field: TripsTextSearchField;
  query: Scalars['String']['input'];
};

export enum TripsTextSearchField {
  AIR_WAY_BILL_NUMBER = 'AIR_WAY_BILL_NUMBER',
  ALL = 'ALL',
  BILL_OF_LADING_NUMBER = 'BILL_OF_LADING_NUMBER',
  BOOKING_NUMBER = 'BOOKING_NUMBER',
  EQUIPMENT_NUMBER = 'EQUIPMENT_NUMBER',
}

export type TripsTourIdentifier = {
  __typename: 'TripsTourIdentifier';
  companyId: Scalars['String']['output'];
  identifierType: TripsIdentifierType;
  identifierValue: Scalars['String']['output'];
};

export type TripsTourIdentifierConnection = {
  __typename: 'TripsTourIdentifierConnection';
  edges: Maybe<Array<Maybe<TripsTourIdentifierEdge>>>;
  pageInfo: PageInfo;
};

export type TripsTourIdentifierEdge = {
  __typename: 'TripsTourIdentifierEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<TripsTourIdentifier>;
};

export enum TripsTransportMode {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
}

export type UnforwardTourInput = {
  tourId: Scalars['String']['input'];
  unforwardByCompanyId: Scalars['String']['input'];
  unforwardFromCompanyId: Scalars['String']['input'];
};

export type UnifiedCompanyOnboardingEvent = {
  __typename: 'UnifiedCompanyOnboardingEvent';
  eventTime: Scalars['DateTime']['output'];
  stuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanyOnboardingEventType;
  visibilitySource: Maybe<TourVisibilitySourceType>;
};

export enum UnifiedCompanyOnboardingEventType {
  COMPANY_REGISTERED = 'COMPANY_REGISTERED',
  FIRST_INTEGRATION_REMINDER_SENT = 'FIRST_INTEGRATION_REMINDER_SENT',
  FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  FIRST_QUESTIONNAIRE_REMINDER_SENT = 'FIRST_QUESTIONNAIRE_REMINDER_SENT',
  INTEGRATION_HELP_REQUESTED = 'INTEGRATION_HELP_REQUESTED',
  MANUALLY_ONBOARDED = 'MANUALLY_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  ONBOARDING_STUCK_AUTOMATIC = 'ONBOARDING_STUCK_AUTOMATIC',
  SECOND_INTEGRATION_REMINDER_SENT = 'SECOND_INTEGRATION_REMINDER_SENT',
  SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  SECOND_QUESTIONNAIRE_REMINDER_SENT = 'SECOND_QUESTIONNAIRE_REMINDER_SENT',
  SIGNUP_PAGE_OPENED = 'SIGNUP_PAGE_OPENED',
  SURVEY_SUBMITTED = 'SURVEY_SUBMITTED',
  TELEMATICS_INTEGRATION_ADDED = 'TELEMATICS_INTEGRATION_ADDED',
  UNSUPPORTED_TELEMATICS_SUBMITTED = 'UNSUPPORTED_TELEMATICS_SUBMITTED',
  VISIBILITY_SOURCE_ACTIVATED = 'VISIBILITY_SOURCE_ACTIVATED',
  VISIBILITY_SOURCE_IDENTIFIED = 'VISIBILITY_SOURCE_IDENTIFIED',
}

export enum UnifiedCompanyOnboardingStatus {
  NOT_REGISTERED = 'NOT_REGISTERED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  READY_FOR_ONBOARDING = 'READY_FOR_ONBOARDING',
  REGISTERED = 'REGISTERED',
}

export type UnifiedCompanyOnboardingStatusUpdateInput = {
  stuckReason?: InputMaybe<UnifiedCompanyOnboardingStuckReason>;
  update: UnifiedCompanyOnboardingStatusUpdateType;
};

export enum UnifiedCompanyOnboardingStatusUpdateType {
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export enum UnifiedCompanyOnboardingStuckReason {
  CARRIER_API_WORK_IN_PROGRESS = 'CARRIER_API_WORK_IN_PROGRESS',
  COMPANY_UNRESPONSIVE = 'COMPANY_UNRESPONSIVE',
  FMS_CREDENTIALS_NOT_PROVIDED = 'FMS_CREDENTIALS_NOT_PROVIDED',
  FMS_PROVIDER_UNRESPONSIVE = 'FMS_PROVIDER_UNRESPONSIVE',
  NO_VISIBILITY_PROVIDED = 'NO_VISIBILITY_PROVIDED',
  NO_VISIBILITY_SOURCE_IDENTIFIED = 'NO_VISIBILITY_SOURCE_IDENTIFIED',
  REFUSES_COOPERATION = 'REFUSES_COOPERATION',
  SUBCARRIER_ONBOARDING_BLOCKED = 'SUBCARRIER_ONBOARDING_BLOCKED',
}

export type UnifiedCompanyRelationshipOnboardingEvent = {
  __typename: 'UnifiedCompanyRelationshipOnboardingEvent';
  eventTime: Scalars['DateTime']['output'];
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanyRelationshipOnboardingEventType;
};

export enum UnifiedCompanyRelationshipOnboardingEventType {
  INVITE_REMINDER_SENT = 'INVITE_REMINDER_SENT',
  INVITE_SENT = 'INVITE_SENT',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_OUT_OF_SCOPE = 'ONBOARDING_OUT_OF_SCOPE',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  ONBOARDING_STUCK_AUTOMATIC = 'ONBOARDING_STUCK_AUTOMATIC',
}

export enum UnifiedCompanyRelationshipOnboardingStatus {
  INVITED = 'INVITED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  PLATFORM_REGISTERED = 'PLATFORM_REGISTERED',
  READY_FOR_ONBOARDING = 'READY_FOR_ONBOARDING',
  REGISTERED = 'REGISTERED',
}

export enum UnifiedCompanyRelationshipOnboardingStatusUpdateType {
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export type UnifiedCompanyRelationshipSignupEvent = {
  __typename: 'UnifiedCompanyRelationshipSignupEvent';
  eventTime: Scalars['DateTime']['output'];
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanyRelationshipSignupEventType;
};

export enum UnifiedCompanyRelationshipSignupEventType {
  INVITE_REMINDER_SENT = 'INVITE_REMINDER_SENT',
  INVITE_SENT = 'INVITE_SENT',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_OUT_OF_SCOPE = 'ONBOARDING_OUT_OF_SCOPE',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  PLATFORM_SIGNUP_ENDED = 'PLATFORM_SIGNUP_ENDED',
  PLATFORM_SIGNUP_STARTED = 'PLATFORM_SIGNUP_STARTED',
}

export enum UnifiedCompanyRelationshipSignupStatus {
  INVITED = 'INVITED',
  NO_VISIBILITY_SOURCE = 'NO_VISIBILITY_SOURCE',
  ONBOARDED = 'ONBOARDED',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  REGISTERED = 'REGISTERED',
}

export enum UnifiedCompanyRelationshipSignupStatusUpdateType {
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export type UnifiedCompanySignupEvent = {
  __typename: 'UnifiedCompanySignupEvent';
  eventTime: Scalars['DateTime']['output'];
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanySignupEventType;
  visibilitySource: Maybe<TourVisibilitySourceType>;
};

export enum UnifiedCompanySignupEventType {
  COMPANY_REGISTERED = 'COMPANY_REGISTERED',
  FIRST_INTEGRATION_REMINDER_SENT = 'FIRST_INTEGRATION_REMINDER_SENT',
  FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  MANUALLY_ONBOARDED = 'MANUALLY_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  PLATFORM_ACTIVATED = 'PLATFORM_ACTIVATED',
  SECOND_INTEGRATION_REMINDER_SENT = 'SECOND_INTEGRATION_REMINDER_SENT',
  SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  SIGNUP_PAGE_OPENED = 'SIGNUP_PAGE_OPENED',
  TELEMATICS_INTEGRATION_ADDED = 'TELEMATICS_INTEGRATION_ADDED',
  UNSUPPORTED_TELEMATICS_SUBMITTED = 'UNSUPPORTED_TELEMATICS_SUBMITTED',
  VISIBILITY_SOURCE_ACTIVATED = 'VISIBILITY_SOURCE_ACTIVATED',
  VISIBILITY_SOURCE_IDENTIFIED = 'VISIBILITY_SOURCE_IDENTIFIED',
}

export enum UnifiedCompanySignupStatus {
  INVITED = 'INVITED',
  NO_VISIBILITY_SOURCE = 'NO_VISIBILITY_SOURCE',
  ONBOARDED = 'ONBOARDED',
  REGISTERED = 'REGISTERED',
}

export type UnifiedCompanySignupStatusUpdateInput = {
  update: UnifiedCompanySignupStatusUpdateType;
};

export enum UnifiedCompanySignupStatusUpdateType {
  ONBOARDED = 'ONBOARDED',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export type UpdateBulkInvitationSetInput = {
  bulkInvitationSetId: Scalars['String']['input'];
  itemIdsToDelete: Array<Scalars['String']['input']>;
  itemsToCreate: Array<CreateBulkInvitationSetItemInput>;
  itemsToUpdate: Array<UpdateBulkInvitationSetItemInput>;
};

export type UpdateBulkInvitationSetItemInput = {
  bulkInvitationSetItemId: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  identifierValues?: InputMaybe<Array<Scalars['String']['input']>>;
  recipients: Array<CompanyBulkInvitationRecipient>;
};

export type UpdateBulkInvitationSetResult = {
  __typename: 'UpdateBulkInvitationSetResult';
  invalidIdentifiers: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateCompanyConfigurationInput = {
  companyId: Scalars['String']['input'];
  configuration: ConfigurationUpdateInput;
};

export type UpdateCompanyInput = {
  address?: InputMaybe<CompanyAddressInput>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  consigneeBackground?: InputMaybe<NullableUpload>;
  consigneeLogo?: InputMaybe<NullableUpload>;
  feature_flags?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  is_carrier?: InputMaybe<Scalars['Boolean']['input']>;
  is_paying_customer?: InputMaybe<Scalars['Boolean']['input']>;
  is_pilot?: InputMaybe<Scalars['Boolean']['input']>;
  is_shipper?: InputMaybe<Scalars['Boolean']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCompanyPlaceInput = {
  companyId: Scalars['String']['input'];
  geodata?: InputMaybe<Array<PlaceGeodataInput>>;
  location?: InputMaybe<PointLocationInput>;
  name: Scalars['String']['input'];
  placeId: Scalars['String']['input'];
  referenceId?: InputMaybe<Scalars['String']['input']>;
  stopAddressIds: Array<Scalars['String']['input']>;
};

export type UpdateCompanyRelationshipOnboardingStatusInput = {
  companyRelationshipId: Scalars['String']['input'];
  stuckReason?: InputMaybe<UnifiedCompanyOnboardingStuckReason>;
  update: UnifiedCompanyRelationshipOnboardingStatusUpdateType;
};

export type UpdateCompanyRelationshipSignupStatusInput = {
  companyRelationshipId: Scalars['String']['input'];
  update: UnifiedCompanyRelationshipSignupStatusUpdateType;
};

export type UpdateCompanySettingsInput = {
  companyId: Scalars['String']['input'];
  settings: CompanySettingsInput;
};

export type UpdateCompanyVehicleGroupInput = {
  companyId: Scalars['String']['input'];
  companyVehicleGroupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDashboardInput = {
  dashboardId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isExample?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  sharedWithUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: DashboardSharingType;
  widgetIdsOrder?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateInviteCompanyNameInput = {
  companyId: Scalars['String']['input'];
  companyInviteId: Scalars['String']['input'];
  newCompanyName: Scalars['String']['input'];
};

export type UpdateTelematicsIntegrationConnectionParametersInput = {
  connectionData?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  telematicsIntegrationId: Scalars['String']['input'];
};

export type UpdateTelematicsIntegrationInput = {
  company_integration_id?: InputMaybe<Scalars['String']['input']>;
  connection_data?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  diagnosticComment?: InputMaybe<Scalars['String']['input']>;
  diagnosticStatus?: InputMaybe<TelematicsIntegrationDiagnosticStatus>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  telematics_integration_id: Scalars['String']['input'];
};

export type UpdateTelematicsProviderInput = {
  contact_info?: InputMaybe<TelematicsProviderContactInfoInput>;
  telematics_provider_id: Scalars['String']['input'];
};

export type UpdateUserInCompanyConfigurationInput = {
  companyId: Scalars['String']['input'];
  configuration: UserInCompanyConfigurationInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserNotificationConfigInput = {
  action: Action;
  company_id: Scalars['String']['input'];
  types: Array<EmailType>;
  user_id: Scalars['String']['input'];
};

export type UpdateUserNotificationConfigResponse = {
  __typename: 'UpdateUserNotificationConfigResponse';
  success: Scalars['Boolean']['output'];
};

export type UpdateViewerProfileInput = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWidgetInput = {
  dashboardId: Scalars['String']['input'];
  filters?: InputMaybe<Array<WidgetFilterInput>>;
  filtersV2?: InputMaybe<Array<WidgetFilterInputV2>>;
  name: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};

export type User = {
  __typename: 'User';
  avatar_url: Maybe<Scalars['String']['output']>;
  backoffice_access_enabled: Maybe<Scalars['Boolean']['output']>;
  backoffice_role: Maybe<BackofficeRole>;
  backoffice_scopes: Maybe<Array<Scalars['String']['output']>>;
  companies: Array<Company>;
  company: Maybe<Company>;
  companyIdAndVisibilityServicesForTour: Maybe<CompanyIdAndVisibilityServices>;
  created_at: Maybe<Scalars['DateTime']['output']>;
  credentials: Array<Credentials>;
  deleted_at: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  first_name: Maybe<Scalars['String']['output']>;
  last_name: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['DateTime']['output']>;
  user_id: Scalars['String']['output'];
};

export type UserCompanyArgs = {
  company_id: Scalars['String']['input'];
};

export type UserCompanyIdAndVisibilityServicesForTourArgs = {
  tourId: Scalars['String']['input'];
};

export type UserAttributesInput = {
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  backoffice_role?: InputMaybe<BackofficeRole>;
  backoffice_scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type UserConnection = {
  __typename: 'UserConnection';
  edges: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename: 'UserEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<User>;
};

export type UserFilter = {
  __typename: 'UserFilter';
  filter: Maybe<TourFilter>;
  filterV2: Maybe<Scalars['GraphQLJSON']['output']>;
  name: Scalars['String']['output'];
  tourCount: Scalars['Int']['output'];
  tours: Array<Tour>;
  userFilterId: Scalars['String']['output'];
};

export type UserFilterToursArgs = {
  included_filter_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type UserFilterConnection = {
  __typename: 'UserFilterConnection';
  edges: Maybe<Array<Maybe<UserFilterEdge>>>;
  pageInfo: PageInfo;
};

export type UserFilterEdge = {
  __typename: 'UserFilterEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<UserFilter>;
};

export type UserGroupOnPlatform = {
  __typename: 'UserGroupOnPlatform';
  company: Maybe<CompanyProfile>;
  groupId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  platform: PlatformProfile;
  users: UserProfileConnection;
};

export type UserGroupOnPlatformUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserGroupOnPlatformConnection = {
  __typename: 'UserGroupOnPlatformConnection';
  edges: Maybe<Array<Maybe<UserGroupOnPlatformEdge>>>;
  pageInfo: PageInfo;
};

export type UserGroupOnPlatformEdge = {
  __typename: 'UserGroupOnPlatformEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<UserGroupOnPlatform>;
};

export type UserInCompany = {
  __typename: 'UserInCompany';
  configuration: UserInCompanyConfiguration;
  dashboard: Maybe<Dashboard>;
  dashboardsConnection: CountedDashboardsConnection;
  invitedByUser: Maybe<UserProfile>;
  metadata: Maybe<UserMetadataInCompany>;
  profile: UserProfile;
  role: UserRoleInCompany;
  transporeonPlatform: Maybe<UserOnPlatform>;
  user: User;
  userFiltersConnection: CountedUserFilterConnection;
};

export type UserInCompanyDashboardArgs = {
  dashboardId: Scalars['String']['input'];
};

export type UserInCompanyDashboardsConnectionArgs = {
  queryType?: InputMaybe<DashboardQueryType>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserInCompanyUserFiltersConnectionArgs = {
  filterType?: InputMaybe<FilterType>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};

export type UserInCompanyConfiguration = {
  __typename: 'UserInCompanyConfiguration';
  isUserSessionInvalidationOnUiInactivityEnabled: Scalars['Boolean']['output'];
  vehicleDwellTimeThresholdMinutes: Scalars['Int']['output'];
};

export type UserInCompanyConfigurationInput = {
  vehicleDwellTimeThresholdMinutes: Scalars['Int']['input'];
};

export type UserInCompanyProfile = {
  __typename: 'UserInCompanyProfile';
  configuration: UserInCompanyConfiguration;
  invitedByUser: Maybe<UserProfile>;
  metadata: Maybe<UserMetadataInCompany>;
  profile: UserProfile;
  role: UserRoleInCompany;
  user: User;
  userOnPlatforms: Array<UserOnPlatformProfile>;
};

export type UserInCompanyProfileConnection = {
  __typename: 'UserInCompanyProfileConnection';
  edges: Maybe<Array<Maybe<UserInCompanyProfileEdge>>>;
  pageInfo: PageInfo;
};

export type UserInCompanyProfileEdge = {
  __typename: 'UserInCompanyProfileEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<UserInCompanyProfile>;
};

export type UserMetadataInCompany = {
  __typename: 'UserMetadataInCompany';
  persona: Maybe<UserPersonaInCompany>;
};

export type UserMetadataInCompanyInput = {
  persona?: InputMaybe<UserPersonaInCompany>;
};

export type UserNotificationConfig = {
  __typename: 'UserNotificationConfig';
  company_id: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  disabled_types: Array<EmailType>;
  updated_at: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  user_notification_config_id: Scalars['String']['output'];
};

export type UserOnPlatform = {
  __typename: 'UserOnPlatform';
  userIdOnPlatform: Scalars['String']['output'];
};

export type UserOnPlatformProfile = {
  __typename: 'UserOnPlatformProfile';
  company: CompanyProfile;
  platform: PlatformProfile;
  user: UserProfile;
  userIdOnPlatform: Scalars['String']['output'];
};

export enum UserPersonaInCompany {
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  DEVELOPER = 'DEVELOPER',
  TRANSPORT_PLANNER = 'TRANSPORT_PLANNER',
  WAREHOUSE_MANAGER = 'WAREHOUSE_MANAGER',
}

export type UserProfile = {
  __typename: 'UserProfile';
  avatar_url: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  first_name: Maybe<Scalars['String']['output']>;
  last_name: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  user_id: Scalars['String']['output'];
};

export type UserProfileConnection = {
  __typename: 'UserProfileConnection';
  edges: Maybe<Array<Maybe<UserProfileEdge>>>;
  pageInfo: PageInfo;
};

export type UserProfileEdge = {
  __typename: 'UserProfileEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<UserProfile>;
};

export enum UserRoleInCompany {
  ADMIN = 'ADMIN',
  REGULAR_USER = 'REGULAR_USER',
}

export type ValidateTelematicsIntegrationConnectionParametersUpdateInput = {
  connectionData?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  telematicsIntegrationId: Scalars['String']['input'];
};

export type ValidateTelematicsIntegrationInput = {
  connection_data?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  telematics_integration_id?: InputMaybe<Scalars['String']['input']>;
  telematics_provider_id: Scalars['String']['input'];
};

export type Vehicle = {
  __typename: 'Vehicle';
  boundingBox: Maybe<Array<PointLocation>>;
  breakHistory: Maybe<Array<VehicleBreakHistory>>;
  breakStatus: Maybe<VehicleBreakStatus>;
  company: CompanyProfile;
  created_at: Maybe<Scalars['DateTime']['output']>;
  history: Maybe<Array<VehicleHistory>>;
  inGlobalPool: Scalars['Boolean']['output'];
  isConstantPositionAccessAllowed: Scalars['Boolean']['output'];
  latestVehicleTemperatures: Array<LatestVehicleTemperature>;
  license_plate_number: Maybe<Scalars['String']['output']>;
  mapTilesTemplateURI: Maybe<Scalars['String']['output']>;
  receivedGrant: Maybe<VehicleGrant>;
  sentGrants: Array<VehicleGrant>;
  sharedBy: Maybe<CompanyProfile>;
  sharedTo: Array<CompanyProfile>;
  status: Maybe<RestrictedVehicleStatus>;
  telematicsIntegrations: Array<TelematicsIntegration>;
  tours: Maybe<Array<Tour>>;
  updated_at: Maybe<Scalars['DateTime']['output']>;
  vehicleMapState: Maybe<VehicleMapState>;
  vehicleTrackers: Array<VehicleTracker>;
  vehicle_id: Scalars['String']['output'];
};

export type VehicleBreakHistoryArgs = {
  endTime: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type VehicleHistoryArgs = {
  end_time: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  simplification_tolerance?: InputMaybe<Scalars['Float']['input']>;
  start_time: Scalars['String']['input'];
};

export type VehicleMapTilesTemplateUriArgs = {
  dwellTime?: InputMaybe<Scalars['Int']['input']>;
  returnTimeBasedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  returnVehicleState?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehicleToursArgs = {
  fromTime: Scalars['DateTime']['input'];
  status?: InputMaybe<Array<TourStatusFilter>>;
  toTime: Scalars['DateTime']['input'];
};

export type VehicleVehicleMapStateArgs = {
  dwellTimeThresholdMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleVehicleTrackersArgs = {
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type VehicleAllocation = {
  __typename: 'VehicleAllocation';
  startTrackingAt: Maybe<Scalars['DateTime']['output']>;
  vehicleId: Maybe<Scalars['String']['output']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']['output']>;
};

export type VehicleAllocationInput = {
  allocationId?: InputMaybe<Scalars['String']['input']>;
  allocationSwitchType?: InputMaybe<TourAllocationSwitchType>;
  startTrackingAt?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleBreakHistory = {
  __typename: 'VehicleBreakHistory';
  breakId: Scalars['String']['output'];
  center: BreakCenterPosition;
  configurationId: Scalars['String']['output'];
  from: Scalars['DateTime']['output'];
  to: Maybe<Scalars['DateTime']['output']>;
};

export type VehicleBreakHistoryEntry = {
  __typename: 'VehicleBreakHistoryEntry';
  breaks: Array<VehicleBreakHistory>;
  vehicle_id: Scalars['String']['output'];
};

export type VehicleBreakStatus = {
  __typename: 'VehicleBreakStatus';
  from: Scalars['DateTime']['output'];
  to: Maybe<Scalars['DateTime']['output']>;
  vehicleBreakStatusId: Scalars['String']['output'];
};

export type VehicleConnection = {
  __typename: 'VehicleConnection';
  edges: Maybe<Array<Maybe<VehicleEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleCountByStatus = {
  __typename: 'VehicleCountByStatus';
  active: Scalars['Int']['output'];
  inActive: Scalars['Int']['output'];
};

export type VehicleData = {
  __typename: 'VehicleData';
  flightNumber: Maybe<Scalars['String']['output']>;
  identificator: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  type: TripTransportMode;
  vehicleId: Scalars['String']['output'];
  voyageId: Maybe<Scalars['String']['output']>;
};

export type VehicleEdge = {
  __typename: 'VehicleEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Vehicle>;
};

export enum VehicleFilterSortKey {
  GRANTED_BY_COMPANY = 'GRANTED_BY_COMPANY',
  LAST_SIGNAL = 'LAST_SIGNAL',
  LICENSE_PLATE = 'LICENSE_PLATE',
  STATUS = 'STATUS',
}

export type VehicleFilterSortOrderInput = {
  direction: SortDirection;
  key: VehicleFilterSortKey;
};

export type VehicleGrant = {
  __typename: 'VehicleGrant';
  grantId: Scalars['String']['output'];
  grantedAt: Scalars['DateTime']['output'];
  grantedByCompany: CompanyProfile;
  grantedByUser: Maybe<UserProfile>;
  grantedToCompany: CompanyProfile;
  permissions: VehiclePermissions;
  vehicle: VehicleProfile;
};

export type VehicleGrantConnection = {
  __typename: 'VehicleGrantConnection';
  edges: Maybe<Array<Maybe<VehicleGrantEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleGrantEdge = {
  __typename: 'VehicleGrantEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<VehicleGrant>;
};

export type VehicleGrantPartner = {
  __typename: 'VehicleGrantPartner';
  company: CompanyProfile;
  grantCount: Scalars['Int']['output'];
};

export type VehicleGrantPartnerGrantCountArgs = {
  permissions?: InputMaybe<VehiclePermissionsInput>;
};

export type VehicleGrantPartnerConnection = {
  __typename: 'VehicleGrantPartnerConnection';
  edges: Maybe<Array<Maybe<VehicleGrantPartnerEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleGrantPartnerEdge = {
  __typename: 'VehicleGrantPartnerEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<VehicleGrantPartner>;
};

export type VehicleGrantVisibilityInput = {
  grantedByCompanyId: Scalars['String']['input'];
  grantedToCompanyId: Scalars['String']['input'];
  isConstantPositionAccessAllowed: Scalars['Boolean']['input'];
  vehicleId: Scalars['String']['input'];
};

export type VehicleHistory = {
  __typename: 'VehicleHistory';
  company: Maybe<CompanyProfile>;
  created_at: Maybe<Scalars['String']['output']>;
  hdg: Maybe<Scalars['Float']['output']>;
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  modeChangesLeft: Maybe<Scalars['Int']['output']>;
  received_at: Maybe<Scalars['String']['output']>;
  source: Maybe<HistorySource>;
  timestamp: Scalars['DateTime']['output'];
  type: Maybe<HistoryType>;
  vehicleTracker: Maybe<VehicleTracker>;
  vehicleType: Maybe<Scalars['String']['output']>;
};

export type VehicleHistoryEntry = {
  __typename: 'VehicleHistoryEntry';
  history: Array<VehicleHistory>;
  vehicle_id: Scalars['String']['output'];
};

export type VehicleHistoryPolyline = {
  __typename: 'VehicleHistoryPolyline';
  metadata: VehicleHistoryPolylineMetadata;
  polyline: Polyline;
};

export type VehicleHistoryPolylineMetadata = {
  __typename: 'VehicleHistoryPolylineMetadata';
  endTime: Scalars['DateTime']['output'];
  isTroubleshootingRange: Scalars['Boolean']['output'];
  startTime: Scalars['DateTime']['output'];
  type: VehicleHistoryPolylineType;
  vehicleId: Scalars['String']['output'];
};

export enum VehicleHistoryPolylineType {
  TELEMETRY = 'TELEMETRY',
  TELEMETRY_GAP = 'TELEMETRY_GAP',
}

export enum VehicleMapState {
  ALLOCATED_BREAK = 'ALLOCATED_BREAK',
  ALLOCATED_MOVING = 'ALLOCATED_MOVING',
  LONG_BREAK = 'LONG_BREAK',
  STALE_GPS = 'STALE_GPS',
  UNALLOCATED_BREAK = 'UNALLOCATED_BREAK',
  UNALLOCATED_MOVING = 'UNALLOCATED_MOVING',
}

export type VehiclePermissions = {
  __typename: 'VehiclePermissions';
  withConstantPositionAccess: Scalars['Boolean']['output'];
};

export type VehiclePermissionsInput = {
  withConstantPositionAccess: Scalars['Boolean']['input'];
};

export type VehiclePosition = {
  __typename: 'VehiclePosition';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  receivedAt: Maybe<Scalars['DateTime']['output']>;
  vehicleId: Scalars['String']['output'];
};

export type VehicleProfile = {
  __typename: 'VehicleProfile';
  isOutsideOfTransportPool: Maybe<Scalars['Boolean']['output']>;
  license_plate_number: Maybe<Scalars['String']['output']>;
  timeBasedStatus: Maybe<TimeBasedStatus>;
  vehicle_id: Scalars['String']['output'];
};

export type VehicleProfileConnection = {
  __typename: 'VehicleProfileConnection';
  edges: Maybe<Array<Maybe<VehicleProfileEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleProfileEdge = {
  __typename: 'VehicleProfileEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<VehicleProfile>;
};

export enum VehicleSignalSource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
  TTC_MOBILE = 'TTC_MOBILE',
}

export enum VehicleState {
  ALLOCATED = 'ALLOCATED',
  ON_BREAK = 'ON_BREAK',
  UNALLOCATED = 'UNALLOCATED',
}

export type VehicleStateFilterInput = {
  breakDurationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  vehicleState: VehicleState;
};

export type VehicleStatus = {
  __typename: 'VehicleStatus';
  accuracyInMeters: Maybe<Scalars['Float']['output']>;
  heading: Maybe<Scalars['Float']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  timestamp: Maybe<Scalars['DateTime']['output']>;
  vehicle_id: Scalars['String']['output'];
};

export type VehicleTemperature = {
  __typename: 'VehicleTemperature';
  compartmentName: Scalars['String']['output'];
  sensors: Array<TemperatureSensor>;
};

export enum VehicleTemperatureEventLevel {
  TOUR = 'TOUR',
  VEHICLE = 'VEHICLE',
}

export type VehicleTemperatureSensorReading = {
  __typename: 'VehicleTemperatureSensorReading';
  compartmentDoorOpen: Maybe<Scalars['Boolean']['output']>;
  compartmentId: Scalars['String']['output'];
  sensorId: Scalars['String']['output'];
  temperatureCelsius: Scalars['Float']['output'];
};

export enum VehicleTemperatureSource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  IOT_TRACKER = 'IOT_TRACKER',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
}

export type VehicleTemperatureVisibilitySource = {
  __typename: 'VehicleTemperatureVisibilitySource';
  eventLevel: VehicleTemperatureEventLevel;
  eventOrigin: Maybe<Scalars['String']['output']>;
  platformId: Maybe<Scalars['String']['output']>;
  primaryEventSource: VehicleTemperatureSource;
  sourceCompanyId: Maybe<Scalars['String']['output']>;
};

export type VehicleTracker = FmsVehicleTracker | MobileVehicleTracker;

export type VehicleTrackerSearchResult = {
  __typename: 'VehicleTrackerSearchResult';
  licensePlate: Scalars['String']['output'];
  remoteId: Scalars['String']['output'];
  telematicsIntegrationId: Scalars['String']['output'];
  vehicleId: Scalars['String']['output'];
  vehicleTrackerId: Scalars['String']['output'];
};

export type VehicleTrackerTour = {
  __typename: 'VehicleTrackerTour';
  shipper: Maybe<CompanyProfile>;
  shipper_transport_number: Maybe<Scalars['String']['output']>;
  startTime: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<Scalars['String']['output']>;
  stops: Array<VehicleTrackerTourStop>;
  tour_id: Scalars['Int']['output'];
};

export type VehicleTrackerTourConnection = {
  __typename: 'VehicleTrackerTourConnection';
  edges: Maybe<Array<Maybe<VehicleTrackerTourEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleTrackerTourEdge = {
  __typename: 'VehicleTrackerTourEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<VehicleTrackerTour>;
};

export type VehicleTrackerTourStop = {
  __typename: 'VehicleTrackerTourStop';
  actual_arrival_time: Maybe<Scalars['DateTime']['output']>;
  arrival_delay_in_seconds: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use actual_arrival_time instead */
  arrival_time: Maybe<Scalars['DateTime']['output']>;
  departure_time: Maybe<Scalars['DateTime']['output']>;
  estimated_arrival_time: Maybe<Scalars['DateTime']['output']>;
  location: Maybe<TourLocation>;
  sequence_number: Scalars['Int']['output'];
  status: Maybe<Scalars['String']['output']>;
  stop_id: Scalars['Int']['output'];
  timeslot: Maybe<Timeslot>;
  type: Maybe<Scalars['String']['output']>;
};

export enum VehicleTrackerType {
  FMS_DEVICE = 'FMS_DEVICE',
  MOBILE_APP = 'MOBILE_APP',
}

export enum VehicleType {
  BARGE = 'BARGE',
  PLANE = 'PLANE',
  RAIL = 'RAIL',
  TERMINAL = 'TERMINAL',
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  UNKNOWN = 'UNKNOWN',
  VESSEL = 'VESSEL',
}

export type VesselNameFilterInput = {
  current?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromPol?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toPod?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VesselNameFilterOutput = {
  __typename: 'VesselNameFilterOutput';
  current: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromPol: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toPod: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type VisibilityIssue = {
  __typename: 'VisibilityIssue';
  index: Scalars['Float']['output'];
  type: VisibilityIssueType;
};

export enum VisibilityIssueType {
  EARLY_LOADING_VISIT = 'EARLY_LOADING_VISIT',
  INFREQUENT_TELEMETRY = 'INFREQUENT_TELEMETRY',
  LATE_ALLOCATION = 'LATE_ALLOCATION',
  LATE_TELEMETRY = 'LATE_TELEMETRY',
  MISSING_TELEMETRY = 'MISSING_TELEMETRY',
  NO_VEHICLE_ALLOCATED = 'NO_VEHICLE_ALLOCATED',
  UNVISITED_STOPS = 'UNVISITED_STOPS',
  VEHICLE_NOT_INTEGRATED = 'VEHICLE_NOT_INTEGRATED',
}

export type VisibilitySegment = {
  __typename: 'VisibilitySegment';
  vehicleId: Scalars['String']['output'];
  visibilityEndDate: Scalars['DateTime']['output'];
  visibilityStartDate: Scalars['DateTime']['output'];
};

export enum VisibilityService {
  VISIBILITY_AIR = 'VISIBILITY_AIR',
  VISIBILITY_CARBON = 'VISIBILITY_CARBON',
  VISIBILITY_OCEAN = 'VISIBILITY_OCEAN',
  VISIBILITY_RAIL = 'VISIBILITY_RAIL',
  VISIBILITY_ROAD = 'VISIBILITY_ROAD',
}

export enum VolumeUnit {
  CUBIC_FEET = 'CUBIC_FEET',
  CUBIC_METERS = 'CUBIC_METERS',
  GALLONS = 'GALLONS',
  LITERS = 'LITERS',
  OUNCES = 'OUNCES',
}

export enum WeightUnit {
  GRAMS = 'GRAMS',
  KILOGRAMS = 'KILOGRAMS',
  METRIC_TONS = 'METRIC_TONS',
  OUNCES = 'OUNCES',
  POUNDS = 'POUNDS',
  SHORT_TONS = 'SHORT_TONS',
}

export type Widget = {
  __typename: 'Widget';
  dashboardId: Scalars['String']['output'];
  filters: Array<WidgetFilter>;
  filtersV2: Array<WidgetFilterV2>;
  name: Maybe<Scalars['String']['output']>;
  type: WidgetType;
  widgetId: Scalars['String']['output'];
  widgetResult: Maybe<Scalars['String']['output']>;
};

export type WidgetConnection = {
  __typename: 'WidgetConnection';
  edges: Maybe<Array<Maybe<WidgetEdge>>>;
  pageInfo: PageInfo;
};

export type WidgetEdge = {
  __typename: 'WidgetEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Widget>;
};

export type WidgetFilter = {
  __typename: 'WidgetFilter';
  filter: TourFilter;
  tourCount: Scalars['Int']['output'];
};

export type WidgetFilterInput = {
  filter: TourFilterInput;
};

export type WidgetFilterInputV2 = {
  filter?: InputMaybe<Scalars['GraphQLJSON']['input']>;
};

export type WidgetFilterV2 = {
  __typename: 'WidgetFilterV2';
  filter: Scalars['GraphQLJSON']['output'];
  tourCount: Scalars['Int']['output'];
};

export type WidgetPreview = {
  __typename: 'WidgetPreview';
  filters: Array<WidgetFilter>;
  filtersV2: Array<WidgetFilterV2>;
  widgetResult: Maybe<Scalars['String']['output']>;
};

export enum WidgetType {
  COUNT = 'COUNT',
  RATIO = 'RATIO',
}

export type WithdrawCompanyInvitationInput = {
  companyInvitationId: Scalars['String']['input'];
};

export type WithdrawCompanyInvitationResult = {
  __typename: 'WithdrawCompanyInvitationResult';
  success: Scalars['Boolean']['output'];
};

export enum WorkingStopStatusFilter {
  AT_LOADING = 'AT_LOADING',
  AT_UNLOADING = 'AT_UNLOADING',
  TOWARDS_LOADING = 'TOWARDS_LOADING',
  TOWARDS_UNLOADING = 'TOWARDS_UNLOADING',
}

export type AddCompanyTagMutationVariables = Exact<{
  input: CompanyTagInput;
}>;

export type AddCompanyTagMutation = { addCompanyTag: boolean } & { __typename: 'Mutation' };

export type RemoveCompanyTagMutationVariables = Exact<{
  input: CompanyTagInput;
}>;

export type RemoveCompanyTagMutation = { removeCompanyTag: boolean } & { __typename: 'Mutation' };

export type CompanyMetadataFragment = {
  company_name: string | null;
  feature_flags: { [key: string]: string | number | boolean | object } | null;
  is_shipper: boolean | null;
  is_carrier: boolean | null;
  is_pilot: boolean | null;
  is_paying_customer: boolean | null;
  is_test: boolean | null;
  fms_integrated_at: string | null;
  isPendingSignup: boolean | null;
  signupCompletedAt: string | null;
  vatNumbers: Array<{ vatNumber: string } & { __typename: 'CompanyVatNumber' }>;
  fieldDataSources:
    | ({
        companyName: CompanyDataSource | null;
        isShipper: CompanyDataSource | null;
        isCarrier: CompanyDataSource | null;
        isTest: CompanyDataSource | null;
      } & { __typename: 'CompanyFieldDataSources' })
    | null;
  companyIdentifiers: Array<
    {
      companyIdentifierId: string;
      discriminatorCountry: string | null;
      identifierType: CompanyIdentifierType;
      identifierValue: string;
      source: string;
      companyId: string;
    } & { __typename: 'CompanyIdentifier' }
  >;
  tags: Array<{ name: string } & { __typename: 'CompanyTag' }>;
} & { __typename: 'Company' };

export type CompanyListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CompanyTypeInput>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  tagName?: InputMaybe<Scalars['String']['input']>;
  signupStatus?: InputMaybe<UnifiedCompanySignupStatus>;
}>;

export type CompanyListQuery = {
  availableCompanyTags: Array<{ name: string } & { __typename: 'CompanyTag' }>;
  companies:
    | ({
        edges: Array<
          | ({
              node:
                | ({
                    company_id: string;
                    shipperToursCount: number;
                    carrierToursCount: number;
                    totalVehiclesCount: number;
                    unifiedSignupStatus: UnifiedCompanySignupStatus | null;
                    platforms: Array<
                      { platform_id: string; platform_name: string; company_id_on_platform: string } & {
                        __typename: 'CompanyPlatform';
                      }
                    >;
                    companyIdentifiers: Array<
                      {
                        companyIdentifierId: string;
                        identifierType: CompanyIdentifierType;
                        identifierValue: string;
                      } & { __typename: 'CompanyIdentifier' }
                    >;
                  } & CompanyMetadataFragment & { __typename: 'Company' })
                | null;
            } & { __typename: 'CompanyEdge' })
          | null
        > | null;
        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
      } & { __typename: 'CompanyConnection' })
    | null;
} & { __typename: 'Query' };

export type ApiEndpointUsageLogsQueryVariables = Exact<{
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ApiEndpointUsageLogsQuery = {
  apiEndpointUsageLogs: Array<
    {
      apiImplementedAt: string | null;
      companyId: string;
      companyName: string | null;
      endpointId: string;
      integrationIdentifier: string;
      firstCallAt: string;
      lastCallAt: string;
      counters: Array<
        { logDate: string; statusCode: number | null; noOfCalls: number } & { __typename: 'EndpointUsageCounterByDate' }
      >;
    } & { __typename: 'ApiEndpointUsageLog' }
  >;
} & { __typename: 'Query' };

export type CompanyDetailsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyDetailsQuery = {
  availableCompanyTags: Array<{ name: string } & { __typename: 'CompanyTag' }>;
  company: ({ company_id: string } & CompanyMetadataFragment & { __typename: 'Company' }) | null;
} & { __typename: 'Query' };

export type CompanyWithFeatureFlagMetadataQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  applies_to: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CompanyWithFeatureFlagMetadataQuery = {
  company:
    | ({
        company_id: string;
        company_name: string | null;
        feature_flags: { [key: string]: string | number | boolean | object } | null;
        featureFlagAuditLogConnection: {
          edges: Array<
            | ({
                node:
                  | ({
                      featureFlagAuditLogId: string;
                      featureFlagId: string | null;
                      createdAt: string;
                      user:
                        | ({ user_id: string; first_name: string | null; last_name: string | null } & {
                            __typename: 'UserProfile';
                          })
                        | null;
                      metadata: Array<
                        | ({ action: string; value: boolean } & { __typename: 'FlagCreatedMetadata' })
                        | ({ action: string; oldValue: boolean | null; newValue: boolean } & {
                            __typename: 'FlagUpdatedMetadata';
                          })
                        | null
                      >;
                    } & { __typename: 'FeatureFlagAuditLog' })
                  | null;
              } & { __typename: 'FeatureFlagAuditLogEdge' })
            | null
          > | null;
          pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
        } & { __typename: 'FeatureFlagAuditLogConnection' };
      } & { __typename: 'Company' })
    | null;
  featureFlagMetadata: Array<
    {
      feature_flag_id: string;
      display_name: string;
      description: string;
      usage: string | null;
      domains: Array<string | null>;
    } & { __typename: 'FeatureFlagMetadata' }
  >;
} & { __typename: 'Query' };

export type UpdateCompanyMutationVariables = Exact<{
  company_id: Scalars['String']['input'];
  input?: InputMaybe<UpdateCompanyInput>;
}>;

export type UpdateCompanyMutation = {
  updateCompany:
    | ({
        company_id: string;
        assets: {
          consigneeLogo: ({ url: string } & { __typename: 'CompanyAsset' }) | null;
          consigneeBackground: ({ url: string } & { __typename: 'CompanyAsset' }) | null;
        } & { __typename: 'CompanyAssets' };
      } & CompanyMetadataFragment & { __typename: 'Company' })
    | null;
} & { __typename: 'Mutation' };

export type CreateCompanyIdentifierMutationVariables = Exact<{
  input: CreateCompanyIdentifierInput;
}>;

export type CreateCompanyIdentifierMutation = {
  createCompanyIdentifier: ({ success: boolean } & { __typename: 'CreateCompanyIdentifierResult' }) | null;
} & { __typename: 'Mutation' };

export type DeleteCompanyIdentifierMutationVariables = Exact<{
  input: DeleteCompanyIdentifierInput;
}>;

export type DeleteCompanyIdentifierMutation = {
  deleteCompanyIdentifier: ({ success: boolean } & { __typename: 'DeleteCompanyIdentifierResult' }) | null;
} & { __typename: 'Mutation' };

export type CreateVehiclesMutationVariables = Exact<{
  input: CreateVehiclesInput;
}>;

export type CreateVehiclesMutation = { createVehicles: boolean } & { __typename: 'Mutation' };

export type CreateCompanyMutationVariables = Exact<{
  input?: InputMaybe<CreateCompanyInput>;
}>;

export type CreateCompanyMutation = {
  createCompany:
    | ({
        company_id: string;
        shipperToursCount: number;
        carrierToursCount: number;
        totalVehiclesCount: number;
        unifiedOnboardingStatus: UnifiedCompanyOnboardingStatus | null;
        unifiedOnboardingStuckReason: UnifiedCompanyOnboardingStuckReason | null;
        platforms: Array<
          { platform_id: string; platform_name: string; company_id_on_platform: string } & {
            __typename: 'CompanyPlatform';
          }
        >;
      } & CompanyMetadataFragment & { __typename: 'Company' })
    | null;
} & { __typename: 'Mutation' };

export type CompanyShipperToursByCarriersMetricsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CompanyShipperToursByCarriersMetricsQuery = {
  company:
    | ({
        company_id: string;
        tourReportsByCarrier: {
          edges: Array<
            | ({
                node:
                  | ({
                      toursCount: number;
                      visibilityIndex: number | null;
                      allocatedToursCount: number;
                      carrier:
                        | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                        | null;
                    } & { __typename: 'CarrierReport' })
                  | null;
              } & { __typename: 'CarrierReportEdge' })
            | null
          > | null;
          pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
        } & { __typename: 'CarrierReportConnection' };
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyCarrierToursByShippersMetricsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CompanyCarrierToursByShippersMetricsQuery = {
  company:
    | ({
        company_id: string;
        tourReportsByShipper: {
          edges: Array<
            | ({
                node:
                  | ({
                      toursCount: number;
                      visibilityIndex: number | null;
                      allocatedToursCount: number;
                      shipper:
                        | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                        | null;
                    } & { __typename: 'ShipperReport' })
                  | null;
              } & { __typename: 'ShipperReportEdge' })
            | null
          > | null;
          pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
        } & { __typename: 'ShipperReportConnection' };
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyIntegrationsListQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyIntegrationsListQuery = {
  company:
    | ({
        company_id: string;
        company_name: string | null;
        shipperToursCount: number;
        carrierToursCount: number;
        apiEndpointUsageLogs: Array<
          {
            apiImplementedAt: string | null;
            endpointId: string;
            integrationIdentifier: string;
            firstCallAt: string;
            lastCallAt: string;
            counters: Array<
              { logDate: string; statusCode: number | null; noOfCalls: number } & {
                __typename: 'EndpointUsageCounterByDate';
              }
            >;
          } & { __typename: 'ApiEndpointUsageLog' }
        >;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyAssetsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyAssetsQuery = {
  company:
    | ({
        company_id: string;
        assets: {
          consigneeLogo: ({ url: string } & { __typename: 'CompanyAsset' }) | null;
          consigneeBackground: ({ url: string } & { __typename: 'CompanyAsset' }) | null;
        } & { __typename: 'CompanyAssets' };
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyOnboardingQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyOnboardingQuery = {
  company:
    | ({
        company_id: string;
        unifiedOnboardingStatus: UnifiedCompanyOnboardingStatus | null;
        unifiedOnboardingStuckReason: UnifiedCompanyOnboardingStuckReason | null;
        unifiedSignupStatus: UnifiedCompanySignupStatus | null;
        unifiedOnboardingEvents: Array<
          {
            type: UnifiedCompanyOnboardingEventType;
            eventTime: string;
            stuckReason: UnifiedCompanyOnboardingStuckReason | null;
            visibilitySource: TourVisibilitySourceType | null;
            triggeredByUser:
              | ({ user_id: string; first_name: string | null; last_name: string | null } & {
                  __typename: 'UserProfile';
                })
              | null;
          } & { __typename: 'UnifiedCompanyOnboardingEvent' }
        > | null;
        unifiedSignupEvents: Array<
          {
            type: UnifiedCompanySignupEventType;
            eventTime: string;
            visibilitySource: TourVisibilitySourceType | null;
            triggeredByUser:
              | ({ user_id: string; first_name: string | null; last_name: string | null } & {
                  __typename: 'UserProfile';
                })
              | null;
          } & { __typename: 'UnifiedCompanySignupEvent' }
        > | null;
        visibilitySources: Array<
          { status: CompanyVisibilitySourceStatus; type: TourVisibilitySourceType } & {
            __typename: 'CompanyVisibilitySource';
          }
        > | null;
        visibilitySourcesViaSubcarriers: Array<
          { status: CompanyVisibilitySourceStatus; type: TourVisibilitySourceType } & {
            __typename: 'CompanyVisibilitySource';
          }
        > | null;
        signupVisibilitySources: Array<
          { status: CompanyVisibilitySourceStatus; type: TourVisibilitySourceType } & {
            __typename: 'CompanyVisibilitySource';
          }
        > | null;
        signupVisibilitySourcesViaSubcarriers: Array<
          { status: CompanyVisibilitySourceStatus; type: TourVisibilitySourceType } & {
            __typename: 'CompanyVisibilitySource';
          }
        > | null;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyInvitationsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyInvitationsQuery = {
  companyInvitations: Array<
    {
      companyInviteId: string;
      invitedAt: string;
      companyName: string | null;
      status: CompanyInviteStatus;
      inviteMethod: CompanyInviteMethod | null;
      invitedByCompany: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
      invitedByUser: ({ first_name: string | null; last_name: string | null } & { __typename: 'UserProfile' }) | null;
      recipients: Array<{ email: string; locale: string } & { __typename: 'CompanyInvitationRecipient' }>;
    } & { __typename: 'CompanyInvite' }
  >;
} & { __typename: 'Query' };

export type UpdateCompanyOnboardingStatusMutationVariables = Exact<{
  company_id: Scalars['String']['input'];
  input: UnifiedCompanyOnboardingStatusUpdateInput;
}>;

export type UpdateCompanyOnboardingStatusMutation = { updateUnifiedCompanyOnboardingStatus: boolean | null } & {
  __typename: 'Mutation';
};

export type UpdateCompanySignupStatusMutationVariables = Exact<{
  company_id: Scalars['String']['input'];
  input: UnifiedCompanySignupStatusUpdateInput;
}>;

export type UpdateCompanySignupStatusMutation = { updateUnifiedCompanySignupStatus: boolean | null } & {
  __typename: 'Mutation';
};

export type CompanyDropdownQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;

export type CompanyDropdownQuery = {
  company: ({ company_id: string } & CompanyMetadataFragment & { __typename: 'Company' }) | null;
} & { __typename: 'Query' };

export type CompaniesDropdownListQueryVariables = Exact<{
  textSearch?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CompanyTypeInput>;
}>;

export type CompaniesDropdownListQuery = {
  companies:
    | ({
        edges: Array<
          | ({ node: ({ company_id: string } & CompanyMetadataFragment & { __typename: 'Company' }) | null } & {
              __typename: 'CompanyEdge';
            })
          | null
        > | null;
      } & { __typename: 'CompanyConnection' })
    | null;
} & { __typename: 'Query' };

export type CompanyConfigurationQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyConfigurationQuery = {
  company:
    | ({
        company_id: string;
        configuration:
          | ({
              configurations: {
                customerPortal:
                  | ({
                      primaryTransportIdentifier: TransportIdentifier;
                      detailViewTransportIdentifiers: Array<TransportIdentifier>;
                    } & { __typename: 'CustomerPortalConfiguration' })
                  | null;
                sharedTourView:
                  | ({ visibleTransportIdentifiers: Array<TransportIdentifier> } & {
                      __typename: 'SharedTourViewConfiguration';
                    })
                  | null;
                sharedStopView:
                  | ({ visibleTransportIdentifiers: Array<TransportIdentifier> } & {
                      __typename: 'SharedStopViewConfiguration';
                    })
                  | null;
              } & { __typename: 'Configuration' };
            } & { __typename: 'CompanyConfiguration' })
          | null;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type UpdateCompanyConfigurationMutationVariables = Exact<{
  input: UpdateCompanyConfigurationInput;
}>;

export type UpdateCompanyConfigurationMutation = {
  updateCompanyConfiguration:
    | ({
        company_id: string;
        configuration:
          | ({
              configurations: {
                customerPortal:
                  | ({
                      primaryTransportIdentifier: TransportIdentifier;
                      detailViewTransportIdentifiers: Array<TransportIdentifier>;
                    } & { __typename: 'CustomerPortalConfiguration' })
                  | null;
                sharedTourView:
                  | ({ visibleTransportIdentifiers: Array<TransportIdentifier> } & {
                      __typename: 'SharedTourViewConfiguration';
                    })
                  | null;
                sharedStopView:
                  | ({ visibleTransportIdentifiers: Array<TransportIdentifier> } & {
                      __typename: 'SharedStopViewConfiguration';
                    })
                  | null;
              } & { __typename: 'Configuration' };
            } & { __typename: 'CompanyConfiguration' })
          | null;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Mutation' };

export type SensitiveConnectionDataQueryVariables = Exact<{
  telematics_integration_id: Scalars['String']['input'];
  captcha_token: Scalars['String']['input'];
}>;

export type SensitiveConnectionDataQuery = {
  sensitiveConnectionData: { [key: string]: string | number | boolean | object } | null;
} & { __typename: 'Query' };

export type SensitiveDataQueryVariables = Exact<{
  telematics_integration_id: Scalars['String']['input'];
  captcha_token: Scalars['String']['input'];
}>;

export type SensitiveDataQuery = {
  sensitiveData:
    | ({
        connectionData: { [key: string]: string | number | boolean | object } | null;
        syncStateData: string | null;
      } & { __typename: 'SensitiveData' })
    | null;
} & { __typename: 'Query' };

export type CompanyDashboardsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyDashboardsQuery = {
  company:
    | ({
        company_id: string;
        lightdashEmbeddings: { allocationSourceAndSuccessUrl: string } & { __typename: 'LightdashEmbeddings' };
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type BigQueryMessagesQueryVariables = Exact<{
  keys: Array<Scalars['String']['input']> | Scalars['String']['input'];
  messageTypes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  topics: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type BigQueryMessagesQuery = {
  bigQueryMessages: Array<
    { produced_at: string; message: string; message_type: string } & { __typename: 'BigQueryMessageEntry' }
  >;
} & { __typename: 'Query' };

export type TourTrafficEntriesQueryVariables = Exact<{
  tour_id: Scalars['Int']['input'];
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  apiName: TrafficApiName;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type TourTrafficEntriesQuery = {
  tour:
    | ({
        tour_id: number;
        tourTrafficEntries: {
          edges: Array<
            | ({
                node:
                  | ({
                      timestamp: string | null;
                      entry: { [key: string]: string | number | boolean | object } | null;
                    } & { __typename: 'TourTrafficEntry' })
                  | null;
              } & { __typename: 'TourTrafficEntryEdge' })
            | null
          > | null;
          pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
        } & { __typename: 'TourTrafficEntryConnection' };
      } & { __typename: 'Tour' })
    | null;
} & { __typename: 'Query' };

export type ViewerBackofficeRoleQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerBackofficeRoleQuery = {
  viewer:
    | ({ backoffice_role: BackofficeRole | null; backoffice_scopes: Array<string> | null } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type GenerateIntercomTokenUrlQueryVariables = Exact<{
  baseUrl: Scalars['String']['input'];
}>;

export type GenerateIntercomTokenUrlQuery = { intercomTokenUrl: string } & { __typename: 'Query' };

export type CompanyPlatformsWithPlatformsQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyPlatformsWithPlatformsQuery = {
  platforms:
    | ({
        edges: Array<
          | ({ node: ({ platform_id: string; platform_name: string } & { __typename: 'Platform' }) | null } & {
              __typename: 'PlatformEdge';
            })
          | null
        > | null;
      } & { __typename: 'PlatformConnection' })
    | null;
  company:
    | ({
        company_id: string;
        platforms: Array<{ platform_id: string; platform_name: string } & { __typename: 'CompanyPlatform' }>;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyPlatformsListQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
}>;

export type CompanyPlatformsListQuery = {
  company:
    | ({
        company_id: string;
        platforms: Array<
          {
            platform_id: string;
            platform_name: string;
            company_platform_id: string;
            company_id_on_platform: string;
          } & { __typename: 'CompanyPlatform' }
        >;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyPlatformQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  platform_id: Scalars['String']['input'];
}>;

export type CompanyPlatformQuery = {
  companyPlatform:
    | ({
        platform_name: string;
        platform_id: string;
        company_id: string;
        company_platform_id: string;
        company_id_on_platform: string;
      } & { __typename: 'CompanyPlatform' })
    | null;
} & { __typename: 'Query' };

export type DeleteCompanyPlatformMutationVariables = Exact<{
  company_id: Scalars['String']['input'];
  input?: InputMaybe<DeleteCompanyPlatformInput>;
}>;

export type DeleteCompanyPlatformMutation = { deleteCompanyPlatform: boolean } & { __typename: 'Mutation' };

export type CreateCompanyPlatformMutationVariables = Exact<{
  company_id: Scalars['String']['input'];
  input?: InputMaybe<CompanyPlatformInput>;
}>;

export type CreateCompanyPlatformMutation = {
  createCompanyPlatform:
    | ({
        platform_name: string;
        platform_id: string;
        company_id: string;
        company_platform_id: string;
        company_id_on_platform: string;
      } & { __typename: 'CompanyPlatform' })
    | null;
} & { __typename: 'Mutation' };

export type CreateCompanyRelationshipMutationVariables = Exact<{
  input: CreateCompanyRelationshipInput;
}>;

export type CreateCompanyRelationshipMutation = {
  createCompanyRelationship:
    | ({
        companyRelationshipId: string;
        relationshipType: CompanyRelationshipType;
        sourceCompany: { company_id: string; company_name: string | null } & { __typename: 'Company' };
        targetCompany: { company_id: string; company_name: string | null } & { __typename: 'Company' };
      } & { __typename: 'CompanyRelationship' })
    | null;
} & { __typename: 'Mutation' };

export type DeleteCompanyRelationshipMutationVariables = Exact<{
  input: DeleteCompanyRelationshipInput;
}>;

export type DeleteCompanyRelationshipMutation = { deleteCompanyRelationship: boolean | null } & {
  __typename: 'Mutation';
};

export type UpdateCompanyRelationshipOnboardingStatusMutationVariables = Exact<{
  input: UpdateCompanyRelationshipOnboardingStatusInput;
}>;

export type UpdateCompanyRelationshipOnboardingStatusMutation = {
  updateCompanyRelationshipOnboardingStatus: boolean | null;
} & { __typename: 'Mutation' };

export type UpdateCompanyRelationshipSignupStatusMutationVariables = Exact<{
  input: UpdateCompanyRelationshipSignupStatusInput;
}>;

export type UpdateCompanyRelationshipSignupStatusMutation = {
  updateCompanyRelationshipSignupStatus: boolean | null;
} & { __typename: 'Mutation' };

export type CompanyRelationshipsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
}>;

export type CompanyRelationshipsListQuery = {
  companyRelationships:
    | ({
        edges: Array<
          | ({
              node:
                | ({
                    companyRelationshipId: string;
                    relationshipType: CompanyRelationshipType;
                    unifiedOnboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null;
                    unifiedOnboardingStuckReason: UnifiedCompanyOnboardingStuckReason | null;
                    unifiedSignupStatus: UnifiedCompanyRelationshipSignupStatus | null;
                    relationIdentifiers: Array<
                      { companyIdentifierId: string; identifierValue: string; source: string } & {
                        __typename: 'CompanyIdentifier';
                      }
                    >;
                    sourceCompany: { company_name: string | null; company_id: string } & { __typename: 'Company' };
                    targetCompany: { company_name: string | null; company_id: string } & { __typename: 'Company' };
                    unifiedOnboardingEvents: Array<
                      {
                        type: UnifiedCompanyRelationshipOnboardingEventType;
                        eventTime: string;
                        triggeredByUser:
                          | ({ first_name: string | null; last_name: string | null; user_id: string } & {
                              __typename: 'UserProfile';
                            })
                          | null;
                      } & { __typename: 'UnifiedCompanyRelationshipOnboardingEvent' }
                    > | null;
                    unifiedSignupEvents: Array<
                      {
                        type: UnifiedCompanyRelationshipSignupEventType;
                        eventTime: string;
                        triggeredByUser:
                          | ({ first_name: string | null; last_name: string | null; user_id: string } & {
                              __typename: 'UserProfile';
                            })
                          | null;
                      } & { __typename: 'UnifiedCompanyRelationshipSignupEvent' }
                    > | null;
                    dataSharingConsent:
                      | ({
                          tourStatusScope: ConsentState;
                          history: Array<
                            {
                              consentSource: ConsentSource;
                              tourStatusScope: ConsentState;
                              updatedAt: string;
                              updatedByUser:
                                | ({ first_name: string | null; last_name: string | null; user_id: string } & {
                                    __typename: 'UserProfile';
                                  })
                                | null;
                            } & { __typename: 'DataSharingConsentHistory' }
                          > | null;
                        } & { __typename: 'DataSharingConsent' })
                      | null;
                  } & { __typename: 'CompanyRelationship' })
                | null;
            } & { __typename: 'CompanyRelationshipEdge' })
          | null
        > | null;
        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
      } & { __typename: 'CompanyRelationshipConnection' })
    | null;
} & { __typename: 'Query' };

export type DeleteVehicleTrackerMutationVariables = Exact<{
  input: DeleteVehicleTrackerInput;
}>;

export type DeleteVehicleTrackerMutation = { deleteVehicleTracker: boolean } & { __typename: 'Mutation' };

export type ValidateTelematicsIntegrationMutationVariables = Exact<{
  input: ValidateTelematicsIntegrationInput;
}>;

export type ValidateTelematicsIntegrationMutation = {
  validateTelematicsIntegration: ({ areValidCredentials: boolean } & { __typename: 'TelematicsValidation' }) | null;
} & { __typename: 'Mutation' };

export type IntegrationInfoFragment = {
  telematics_integration_id: string;
  telematics_provider_id: string;
  company_integration_id: string;
  company_id: string;
  connection_data: { [key: string]: string | number | boolean | object } | null;
  enabled: boolean;
  diagnosticStatus: TelematicsIntegrationDiagnosticStatus | null;
  diagnosticComment: string | null;
  createdAt: string;
  updatedAt: string;
  isImmutable: boolean;
  isDeletable: boolean;
  integrationApiDebugUrl: string;
  telematicsProvider: {
    telematicsProviderId: string;
    telematicsProviderName: string;
    isVehicleManagementEnabled: boolean | null;
    isDeprecated: boolean | null;
    connectionParameters: Array<
      {
        fieldName: string;
        fieldType: ConnectionParameterFieldType;
        isInternal: boolean;
        isOptional: boolean;
        isSensitive: boolean;
        fieldDisplayName: string | null;
        enumFieldValues: Array<
          { value: string; display_name: string | null; is_default: boolean } & {
            __typename: 'ConnectionParameterEnumValue';
          }
        >;
      } & { __typename: 'ConnectionParameter' }
    >;
  } & { __typename: 'TelematicsProvider' };
  company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
  createdBy:
    | ({ user_id: string; first_name: string | null; last_name: string | null } & { __typename: 'UserProfile' })
    | null;
  updatedBy:
    | ({ user_id: string; first_name: string | null; last_name: string | null } & { __typename: 'UserProfile' })
    | null;
  integrationSyncHistory: Array<
    {
      startTimeAt: string;
      endTimeAt: string;
      vehicleCount: number | null;
      statusCount: number | null;
      temperatureCount: number | null;
      distinctVehicleStatusFutureCount: number | null;
      distinctVehicleTemperatureFutureCount: number | null;
      distinctVehicleNullIslandCount: number | null;
      vehicleAmbiguityCount: number | null;
      latestStatusTimestamp: string | null;
      latestTemperatureTimestamp: string | null;
      errorDescription: string | null;
      errorType: IntegrationSyncResultErrorType | null;
      resultType: IntegrationSyncResultResultType | null;
    } & { __typename: 'IntegrationSyncResult' }
  > | null;
} & { __typename: 'TelematicsIntegration' };

export type TelematicsProvidersListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  isIntegrationImplemented?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationInstructionsURL?: InputMaybe<Scalars['Boolean']['input']>;
  isInternalUseOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isExternalConfigurationNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobalAllocationForbidden?: InputMaybe<Scalars['Boolean']['input']>;
  isVehicleManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isStandardTelematicsPushProvider?: InputMaybe<Scalars['Boolean']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<TelematicsProvidersSortInput>;
}>;

export type TelematicsProvidersListQuery = {
  telematicsProvidersV2: {
    count: number;
    providers: {
      edges: Array<
        | ({
            node:
              | ({
                  telematicsProviderId: string;
                  telematicsProviderName: string;
                  totalTrucksCount: number;
                  activeTrucksCount: number;
                  integrationInstructionsURL: string | null;
                  isInternalUseOnly: boolean;
                  isIntegrationImplemented: boolean;
                  isExternalConfigurationNeeded: boolean;
                  isGlobalAllocationForbidden: boolean | null;
                  isVehicleManagementEnabled: boolean | null;
                  isStandardTelematicsPushProvider: boolean | null;
                  isDeprecated: boolean | null;
                  telematicsIntegrations: { count: number } & { __typename: 'CountedTelematicsIntegrationConnection' };
                  enabledIntegrations: { count: number } & { __typename: 'CountedTelematicsIntegrationConnection' };
                } & { __typename: 'TelematicsProvider' })
              | null;
          } & { __typename: 'TelematicsProviderEdge' })
        | null
      > | null;
      pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
    } & { __typename: 'TelematicsProviderConnection' };
  } & { __typename: 'CountedTelematicsProviderConnection' };
} & { __typename: 'Query' };

export type TelematicsProvidersSimpleListQueryVariables = Exact<{ [key: string]: never }>;

export type TelematicsProvidersSimpleListQuery = {
  telematicsProvidersV2: {
    providers: {
      edges: Array<
        | ({
            node:
              | ({
                  telematicsProviderId: string;
                  telematicsProviderName: string;
                  isIntegrationImplemented: boolean;
                  isDeprecated: boolean | null;
                  connectionParameters: Array<
                    {
                      fieldName: string;
                      fieldType: ConnectionParameterFieldType;
                      isInternal: boolean;
                      isOptional: boolean;
                      isSensitive: boolean;
                      fieldDisplayName: string | null;
                      enumFieldValues: Array<
                        { value: string; display_name: string | null; is_default: boolean } & {
                          __typename: 'ConnectionParameterEnumValue';
                        }
                      >;
                    } & { __typename: 'ConnectionParameter' }
                  >;
                } & { __typename: 'TelematicsProvider' })
              | null;
          } & { __typename: 'TelematicsProviderEdge' })
        | null
      > | null;
    } & { __typename: 'TelematicsProviderConnection' };
  } & { __typename: 'CountedTelematicsProviderConnection' };
} & { __typename: 'Query' };

export type TelematicsProviderQueryVariables = Exact<{
  telematics_provider_id: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TelematicsIntegrationsSortInput>;
}>;

export type TelematicsProviderQuery = {
  telematicsProvider:
    | ({
        telematicsProviderId: string;
        telematicsProviderName: string;
        integrationInstructionsURL: string | null;
        isInternalUseOnly: boolean;
        isIntegrationImplemented: boolean;
        isExternalConfigurationNeeded: boolean;
        isVehicleManagementEnabled: boolean | null;
        isStandardTelematicsPushProvider: boolean | null;
        isGlobalAllocationForbidden: boolean | null;
        isDeprecated: boolean | null;
        contact_info:
          | ({ email_address: string | null; comment: string | null; updated_at: string | null } & {
              __typename: 'TelematicsProviderContactInfo';
            })
          | null;
        connectionParameters: Array<
          {
            fieldName: string;
            fieldType: ConnectionParameterFieldType;
            isInternal: boolean;
            isOptional: boolean;
            isSensitive: boolean;
            fieldDisplayName: string | null;
            enumFieldValues: Array<
              { value: string; display_name: string | null; is_default: boolean } & {
                __typename: 'ConnectionParameterEnumValue';
              }
            >;
          } & { __typename: 'ConnectionParameter' }
        >;
        telematicsIntegrations: {
          integrations: {
            edges: Array<
              | ({ node: (IntegrationInfoFragment & { __typename: 'TelematicsIntegration' }) | null } & {
                  __typename: 'TelematicsIntegrationEdge';
                })
              | null
            > | null;
            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
          } & { __typename: 'TelematicsIntegrationConnection' };
        } & { __typename: 'CountedTelematicsIntegrationConnection' };
      } & { __typename: 'TelematicsProvider' })
    | null;
} & { __typename: 'Query' };

export type TelematicsIntegrationQueryVariables = Exact<{
  telematics_integration_id: Scalars['String']['input'];
}>;

export type TelematicsIntegrationQuery = {
  telematicsIntegration: (IntegrationInfoFragment & { __typename: 'TelematicsIntegration' }) | null;
} & { __typename: 'Query' };

export type UpdateTelematicsProviderMutationVariables = Exact<{
  input: UpdateTelematicsProviderInput;
}>;

export type UpdateTelematicsProviderMutation = {
  updateTelematicsProvider:
    | ({
        contact_info:
          | ({ email_address: string | null; comment: string | null; updated_at: string | null } & {
              __typename: 'TelematicsProviderContactInfo';
            })
          | null;
      } & { __typename: 'TelematicsProvider' })
    | null;
} & { __typename: 'Mutation' };

export type UpdateTelematicsIntegrationMutationVariables = Exact<{
  input: UpdateTelematicsIntegrationInput;
}>;

export type UpdateTelematicsIntegrationMutation = {
  updateTelematicsIntegration:
    | ({
        company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
      } & IntegrationInfoFragment & { __typename: 'TelematicsIntegration' })
    | null;
} & { __typename: 'Mutation' };

export type CreateTelematicsIntegrationMutationVariables = Exact<{
  input: CreateTelematicsIntegrationInput;
}>;

export type CreateTelematicsIntegrationMutation = {
  createTelematicsIntegration:
    | ({
        company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
      } & IntegrationInfoFragment & { __typename: 'TelematicsIntegration' })
    | null;
} & { __typename: 'Mutation' };

export type DeleteTelematicsIntegrationMutationVariables = Exact<{
  telematics_integration_id: Scalars['String']['input'];
}>;

export type DeleteTelematicsIntegrationMutation = { deleteTelematicsIntegration: boolean } & { __typename: 'Mutation' };

export type DeleteTelematicsIntegrationSyncStateMutationVariables = Exact<{
  telematics_integration_id: Scalars['String']['input'];
}>;

export type DeleteTelematicsIntegrationSyncStateMutation = { deleteTelematicsIntegrationSyncState: boolean } & {
  __typename: 'Mutation';
};

export type TelematicsIntegrationsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<TelematicsIntegrationsSortInput>;
}>;

export type TelematicsIntegrationsListQuery = {
  telematicsIntegrations:
    | ({
        count: number;
        integrations: {
          edges: Array<
            | ({
                node:
                  | ({ company_id: string } & IntegrationInfoFragment & { __typename: 'TelematicsIntegration' })
                  | null;
              } & { __typename: 'TelematicsIntegrationEdge' })
            | null
          > | null;
          pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
        } & { __typename: 'TelematicsIntegrationConnection' };
      } & { __typename: 'CountedTelematicsIntegrationConnection' })
    | null;
} & { __typename: 'Query' };

export type TelematicsCountsQueryVariables = Exact<{ [key: string]: never }>;

export type TelematicsCountsQuery = {
  telematicsProvidersV2: { count: number } & { __typename: 'CountedTelematicsProviderConnection' };
  telematicsIntegrations: ({ count: number } & { __typename: 'CountedTelematicsIntegrationConnection' }) | null;
} & { __typename: 'Query' };

export type VehicleTrackerByTelematicsIntegrationAndVehicleDetailsQueryVariables = Exact<{
  telematicsIntegrationId: Scalars['String']['input'];
  remoteId?: InputMaybe<Scalars['String']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
}>;

export type VehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery = {
  vehicleTrackerByTelematicsIntegrationAndVehicleDetails:
    | ({ vehicleTrackerId: string } & { __typename: 'VehicleTrackerSearchResult' })
    | null;
} & { __typename: 'Query' };

export type AddTourTagMutationVariables = Exact<{
  tour_id: Scalars['String']['input'];
  tag_name: Scalars['String']['input'];
}>;

export type AddTourTagMutation = { addTourTag: boolean } & { __typename: 'Mutation' };

export type RemoveTourTagMutationVariables = Exact<{
  tour_id: Scalars['String']['input'];
  tag_name: Scalars['String']['input'];
}>;

export type RemoveTourTagMutation = { removeTourTag: boolean } & { __typename: 'Mutation' };

export type TourQueryVariables = Exact<{
  tour_id: Scalars['Int']['input'];
}>;

export type TourQuery = {
  availableTourTags: Array<{ name: string } & { __typename: 'TourTag' }>;
  tour:
    | ({
        tour_id: number;
        tour_type: TourType | null;
        parent_tour_id: string | null;
        transport_id: string | null;
        bill_of_lading: string | null;
        platform_shipper_id: string | null;
        platform_carrier_id: string | null;
        shipper_transport_number: string | null;
        platform_transport_number: string | null;
        platformBranchId: string | null;
        start_time: string | null;
        complete_time: string | null;
        abort_time: string | null;
        working_stop_id: number | null;
        transportMode: TransportMode | null;
        visibilityServices: Array<VisibilityService>;
        isSendEtaToPlatformEnabled: boolean;
        created_at: string;
        status: string | null;
        isTrackingDisabled: boolean;
        trackingStartTime: string | null;
        trackingEndTime: string | null;
        visibility_start_time: string | null;
        visibility_end_time: string | null;
        visibilityIndex: number | null;
        mainMode: MainMode | null;
        loadType: LoadType | null;
        shipmentType: ShipmentType | null;
        metadata: { [key: string]: string | number | boolean | object } | null;
        tourDistance: ({ totalDistanceInMeters: number } & { __typename: 'TourDistance' }) | null;
        warnings: Array<
          { type: TourWarningType; metadata: { [key: string]: string | number | boolean | object } | null } & {
            __typename: 'TourWarning';
          }
        >;
        failureReason: ({ type: TourFailureReasonType } & { __typename: 'TourFailureReason' }) | null;
        platformCallbacks: Array<
          {
            subjectType: string;
            subjectId: string;
            payload: { [key: string]: string | number | boolean | object };
            createdAt: string;
            platform: ({ platform_id: string; platform_name: string } & { __typename: 'Platform' }) | null;
          } & { __typename: 'PlatformCallbackMessage' }
        >;
        customFields: Array<
          { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
            __typename: 'CustomField';
          }
        >;
        aborted_by_user:
          | ({ user_id: string; first_name: string | null; last_name: string | null } & { __typename: 'UserProfile' })
          | null;
        site: ({ site_id: string; name: string } & { __typename: 'SiteProfile' }) | null;
        visibilitySegments: Array<
          { vehicleId: string; visibilityStartDate: string; visibilityEndDate: string } & {
            __typename: 'VisibilitySegment';
          }
        >;
        trackingSegments: Array<
          { vehicleId: string; trackingStartDate: string; trackingEndDate: string } & { __typename: 'TrackingSegment' }
        >;
        tags: Array<{ name: string } & { __typename: 'TourTag' }>;
        currentVehicle:
          | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & {
              __typename: 'VehicleAllocation';
            })
          | null;
        activeVehicleAllocation:
          | ({
              vehicleId: string | null;
              allocationId: string | null;
              vehicleLicensePlateNumber: string | null;
              allocationType: TourVehicleAllocationType | null;
              allocatedAt: string | null;
              startTrackingAt: string | null;
              source: TourVehicleAllocationSource;
              remoteTrackerId: string | null;
              remoteAllocationSource: TourRemoteAllocationSource | null;
              allocationDeviceType: TourAllocationDeviceType | null;
              allocationSwitchType: TourAllocationSwitchType | null;
              disableVehicleMatching: boolean | null;
              clearAllocation: boolean | null;
              stopAllocations: Array<
                {
                  stopId: string;
                  vehicleId: string | null;
                  vehicleLicensePlateNumber: string | null;
                  startTrackingAt: string | null;
                } & { __typename: 'StopAllocation' }
              > | null;
            } & { __typename: 'TourVehicleAllocation' })
          | null;
        vehicleAllocations: Array<
          {
            vehicleId: string | null;
            vehicleLicensePlateNumber: string | null;
            allocationType: TourVehicleAllocationType | null;
            allocatedAt: string | null;
            startTrackingAt: string | null;
            source: TourVehicleAllocationSource;
            remoteTrackerId: string | null;
            remoteAllocationSource: TourRemoteAllocationSource | null;
            allocationDeviceType: TourAllocationDeviceType | null;
            allocationSwitchType: TourAllocationSwitchType | null;
            disableVehicleMatching: boolean | null;
            clearAllocation: boolean | null;
            allocatedByCompany:
              | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
              | null;
            stopAllocations: Array<
              {
                stopId: string;
                vehicleId: string | null;
                vehicleLicensePlateNumber: string | null;
                startTrackingAt: string | null;
              } & { __typename: 'StopAllocation' }
            > | null;
          } & { __typename: 'TourVehicleAllocation' }
        >;
        vehicleAllocationHistory: Array<
          {
            vehicleId: string | null;
            allocationId: string | null;
            vehicleLicensePlateNumber: string | null;
            allocationType: TourVehicleAllocationType | null;
            allocatedAt: string | null;
            startTrackingAt: string | null;
            source: TourVehicleAllocationSource;
            remoteTrackerId: string | null;
            remoteAllocationSource: TourRemoteAllocationSource | null;
            allocationDeviceType: TourAllocationDeviceType | null;
            allocationSwitchType: TourAllocationSwitchType | null;
            disableVehicleMatching: boolean | null;
            clearAllocation: boolean | null;
            allocatedByCompany:
              | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
              | null;
            stopAllocations: Array<
              {
                stopId: string;
                vehicleId: string | null;
                vehicleLicensePlateNumber: string | null;
                startTrackingAt: string | null;
              } & { __typename: 'StopAllocation' }
            > | null;
          } & { __typename: 'TourVehicleAllocation' }
        >;
        forwardingChain: Array<
          {
            forwardedAt: string;
            triggeredByAllocationId: string | null;
            from: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
            to: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
            forwardedBy:
              | ({ user_id: string; first_name: string | null; last_name: string | null } & {
                  __typename: 'UserProfile';
                })
              | null;
          } & { __typename: 'TourForwardingLink' }
        >;
        visibilitySources: Array<
          {
            primaryEventSource: TourVisibilitySourceType;
            streamPrecision: TourVisibilityPrecisionType | null;
            eventOrigin: string | null;
            sourceCompany:
              | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
              | null;
            sourcePlatform: ({ platform_name: string } & { __typename: 'PlatformProfile' }) | null;
          } & { __typename: 'TourVisibilitySource' }
        >;
        mainVisibilityIssue: ({ type: VisibilityIssueType; index: number } & { __typename: 'VisibilityIssue' }) | null;
        visibilityIssues: Array<
          ({ type: VisibilityIssueType; index: number } & { __typename: 'VisibilityIssue' }) | null
        > | null;
        events: Array<
          | ({
              stop_id: number;
              tour_id: number;
              event_id: number;
              vehicle_id: string | null;
              vehicle_lat: number | null;
              vehicle_lng: number | null;
              event_index: number;
              event_time: string | null;
              created_at: string;
              stop_event_name: StopEventName;
              visibilitySource:
                | ({
                    primaryEventSource: TourVisibilitySourceType;
                    streamPrecision: TourVisibilityPrecisionType | null;
                    eventOrigin: string | null;
                    sourceCompany:
                      | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                      | null;
                    sourcePlatform: ({ platform_name: string } & { __typename: 'PlatformProfile' }) | null;
                  } & { __typename: 'TourVisibilitySource' })
                | null;
              metadata:
                | ({
                    algorithm: string | null;
                    parameters: { [key: string]: string | number | boolean | object } | null;
                  } & { __typename: 'StopEventMetadata' })
                | null;
            } & { __typename: 'StopEvent' })
          | ({
              tour_id: number;
              event_id: number;
              vehicle_id: string | null;
              vehicle_lat: number | null;
              vehicle_lng: number | null;
              event_index: number;
              event_time: string | null;
              created_at: string;
              tour_event_name: TourEventName;
              warning: ({ type: TourWarningType } & { __typename: 'TourWarning' }) | null;
              visibilitySource:
                | ({
                    primaryEventSource: TourVisibilitySourceType;
                    streamPrecision: TourVisibilityPrecisionType | null;
                    eventOrigin: string | null;
                    sourceCompany:
                      | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                      | null;
                    sourcePlatform: ({ platform_name: string } & { __typename: 'PlatformProfile' }) | null;
                  } & { __typename: 'TourVisibilitySource' })
                | null;
              metadata:
                | ({
                    algorithm: string | null;
                    parameters: { [key: string]: string | number | boolean | object } | null;
                  } & { __typename: 'TourEventMetadata' })
                | null;
            } & { __typename: 'TourEvent' })
        >;
        externalEvents: Array<
          {
            eventId: string;
            eventSource: ExternalEventSource;
            qualifier: ExternalTourEventQualifier;
            sentByCompanyId: string | null;
            timestamp: string;
            externalEventId: string | null;
            externalEventSource: string | null;
            externalEventQualifier: string;
            latitude: number | null;
            longitude: number | null;
            licensePlateNumber: string | null;
            remoteId: string | null;
            vehicleType: VehicleType | null;
            locationName: string | null;
            createdAt: string;
          } & { __typename: 'ExternalTourEvent' }
        >;
        externalStopEvents: Array<
          {
            eventId: string;
            eventSource: ExternalEventSource;
            qualifier: ExternalTourStopEventQualifier;
            sentByCompanyId: string | null;
            externalEventId: string | null;
            externalEventSource: string | null;
            externalEventQualifier: string;
            stopId: string;
            timestamp: string;
            latitude: number | null;
            longitude: number | null;
            createdAt: string;
          } & { __typename: 'ExternalTourStopEvent' }
        >;
        externalDeliveryEvents: Array<
          {
            eventId: string;
            deliveryId: string;
            eventSource: ExternalEventSource;
            externalEventId: string | null;
            externalEventSource: string | null;
            externalEventQualifier: string;
            sentByCompanyId: string | null;
            qualifier: ExternalTourDeliveryEventQualifier;
            timestamp: string;
            latitude: number | null;
            longitude: number | null;
            createdAt: string;
          } & { __typename: 'ExternalTourDeliveryEvent' }
        >;
        deliveries: Array<
          {
            deliveryId: string;
            deliveryTourId: string | null;
            deliveryNumber: string | null;
            purchaseOrderNumber: string | null;
            orderNumber: string | null;
            sequenceNumber: number | null;
            mainMode: MainMode | null;
            storagePositions: number | null;
            customFields: Array<
              { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
                __typename: 'CustomField';
              }
            >;
            transportUnit: ({ value: number; unit: string } & { __typename: 'TourTransportUnit' }) | null;
            volume: ({ value: number; unit: string } & { __typename: 'TourVolume' }) | null;
            loadingLength: ({ value: number; unit: string } & { __typename: 'TourLoadingLength' }) | null;
            incoterms: ({ term: string | null; city: string | null } & { __typename: 'TourIncoterms' }) | null;
          } & { __typename: 'TourDelivery' }
        >;
        deliveryTree: Array<
          {
            deliveryTreeId: string;
            affectedTourId: string;
            platformDeliveryId: string;
            platformId: string;
            sequenceNumber: number;
            sourceTourId: string | null;
            parentDeliveryTreeId: string | null;
            rootDeliveryTreeId: string;
            rootPlatformDeliveryId: string;
            platformPreviousDeliveryId: string | null;
            deliveryNumber: string | null;
            purchaseOrderNumber: string | null;
            orderNumber: string | null;
            platformSequenceNumber: number | null;
            customFields: { [key: string]: string | number | boolean | object };
            loadingStop: ({ stopId: string } & { __typename: 'DeliveryStop' }) | null;
            unloadingStop: ({ stopId: string } & { __typename: 'DeliveryStop' }) | null;
          } & { __typename: 'TourDeliveryTree' }
        >;
        tour_area:
          | ({
              type: GeofenceType;
              radiusInMeters: number;
              origin: { lat: number; lng: number } & { __typename: 'Position' };
            } & { __typename: 'GeofenceCircle' })
          | ({ type: GeofenceType; points: Array<{ lat: number; lng: number } & { __typename: 'Position' }> } & {
              __typename: 'GeofencePolygon';
            })
          | null;
        stops: Array<
          {
            stop_id: number;
            sequence_number: number | null;
            actual_sequence_number: number | null;
            platformSequenceNumber: number | null;
            type: string | null;
            delivery_ids: Array<string | null> | null;
            booking_id: string | null;
            stop_reference_id: string | null;
            place_reference_id: string | null;
            expected_duration: string | null;
            status: string | null;
            deadline: string | null;
            etaType: EtaType | null;
            externalEtaSource: ExternalEtaSource | null;
            estimated_arrival: string | null;
            estimated_arrival_regulated: string | null;
            estimated_arrival_unregulated: string | null;
            estimated_arrival_status: string | null;
            estimated_arrival_accuracy: string | null;
            estimated_arrival_distance_in_meters: number | null;
            departure_time: string | null;
            arrival_time: string | null;
            arrival_delay_in_seconds: number | null;
            etas: Array<
              {
                stop_id: string | null;
                etaType: EtaType | null;
                externalEtaSource: ExternalEtaSource | null;
                estimated_arrival: string | null;
                estimated_arrival_regulated: string | null;
                estimated_arrival_unregulated: string | null;
                estimated_arrival_status: string | null;
                estimated_arrival_accuracy: string | null;
                estimated_arrival_distance_in_meters: number | null;
              } & { __typename: 'SingleTourStopEstimate' }
            > | null;
            vehicleAllocation:
              | ({
                  vehicleId: string | null;
                  vehicleLicensePlateNumber: string | null;
                  startTrackingAt: string | null;
                } & { __typename: 'VehicleAllocation' })
              | null;
            timeslot:
              | ({ begin: string | null; end: string | null; timezone: string | null } & { __typename: 'Timeslot' })
              | null;
            location:
              | ({
                  location_reference_id: string | null;
                  gate_id: string | null;
                  gate: string | null;
                  name: string | null;
                  bookingLocationName: string | null;
                  address:
                    | ({
                        full_address: string | null;
                        street_address: string | null;
                        city: string | null;
                        state: string | null;
                        country: string | null;
                        zipcode: string | null;
                        shortAreaCode: string | null;
                      } & { __typename: 'Address' })
                    | null;
                  customerProvidedAddress:
                    | ({
                        full_address: string | null;
                        street_address: string | null;
                        city: string | null;
                        state: string | null;
                        country: string | null;
                        zipcode: string | null;
                      } & { __typename: 'Address' })
                    | null;
                  position:
                    | ({
                        lat: number | null;
                        lng: number | null;
                        provider: string | null;
                        geocoding_type: GeocodingType | null;
                        matchLevel: GeoPositionMatchLevel | null;
                        geocodingDirection: string | null;
                      } & { __typename: 'GeoPosition' })
                    | null;
                  place:
                    | ({ place_id: string; name: string; isSixfoldGenerated: boolean } & { __typename: 'CompanyPlace' })
                    | null;
                } & { __typename: 'TourLocation' })
              | null;
            geofenceZones: Array<
              {
                zone: GeofenceZoneType;
                geofence:
                  | ({
                      type: GeofenceType;
                      radiusInMeters: number;
                      origin: { lat: number; lng: number } & { __typename: 'Position' };
                    } & { __typename: 'GeofenceCircle' })
                  | ({
                      type: GeofenceType;
                      points: Array<{ lat: number; lng: number } & { __typename: 'Position' }>;
                    } & { __typename: 'GeofencePolygon' });
              } & { __typename: 'GeofenceZone' }
            >;
            status_events: Array<
              {
                stop_id: number;
                event_id: number;
                event_name: StopEventName;
                event_index: number;
                event_time: string | null;
                vehicle_lat: number | null;
                vehicle_lng: number | null;
                created_at: string;
                visibilitySource:
                  | ({
                      primaryEventSource: TourVisibilitySourceType;
                      streamPrecision: TourVisibilityPrecisionType | null;
                      eventOrigin: string | null;
                      sourceCompany:
                        | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                        | null;
                      sourcePlatform: ({ platform_name: string } & { __typename: 'PlatformProfile' }) | null;
                    } & { __typename: 'TourVisibilitySource' })
                  | null;
              } & { __typename: 'StopEvent' }
            >;
          } & { __typename: 'TourStop' }
        >;
        platform: ({ platform_name: string; platform_id: string } & { __typename: 'Platform' }) | null;
        shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
        carrier: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
        createdBy: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
        vehicles: Array<{ vehicle_id: string; license_plate_number: string | null } & { __typename: 'VehicleProfile' }>;
        status_events: Array<
          {
            event_id: number;
            event_name: TourEventName;
            event_index: number;
            event_time: string | null;
            vehicle_lat: number | null;
            vehicle_lng: number | null;
            created_at: string;
            warning: ({ type: TourWarningType } & { __typename: 'TourWarning' }) | null;
            visibilitySource:
              | ({
                  primaryEventSource: TourVisibilitySourceType;
                  streamPrecision: TourVisibilityPrecisionType | null;
                  eventOrigin: string | null;
                  sourceCompany:
                    | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                    | null;
                  sourcePlatform: ({ platform_name: string } & { __typename: 'PlatformProfile' }) | null;
                } & { __typename: 'TourVisibilitySource' })
              | null;
          } & { __typename: 'TourEvent' }
        >;
        route:
          | ({
              provider: string;
              legs: Array<{ polyline: { points: string } & { __typename: 'Polyline' } } & { __typename: 'RouteLeg' }>;
            } & { __typename: 'Route' })
          | null;
        userGroupsOnPlatform: Array<
          {
            groupId: string;
            name: string;
            company: ({ company_id: string } & { __typename: 'CompanyProfile' }) | null;
          } & { __typename: 'UserGroupOnPlatform' }
        >;
        plannedEmissions:
          | ({
              co2TankToWheelTonnes: number;
              co2WellToTankTonnes: number;
              co2WellToWheelTonnes: number;
              routePolyline: string | null;
              cargo: {
                containerEmptyWeight: number;
                tonsPerTeu: number;
                tonsPerFeu: number;
                weight: number;
                volumeWeight: string;
                unit: string;
              } & { __typename: 'Cargo' };
              legs: Array<
                {
                  transportMode: string;
                  distanceKM: number | null;
                  routePolyline: string | null;
                  type: string;
                  startLocation: { lat: number | null; lng: number | null } & { __typename: 'GeoPosition' };
                  endLocation: { lat: number | null; lng: number | null } & { __typename: 'GeoPosition' };
                  emissions: Array<
                    {
                      type: string;
                      emissionMode: string;
                      unit: string;
                      tankToWheel: number;
                      wellToTank: number;
                      total: number;
                    } & { __typename: 'LegEmission' }
                  >;
                  externalCosts:
                    | ({
                        currency: string;
                        totalExternalCosts: number;
                        accidentCosts: Array<
                          { transportMode: string; accidentCostValue: number } & { __typename: 'AccidentCost' }
                        >;
                        noiseCosts: Array<
                          { transportMode: string; noiseCostValue: number } & { __typename: 'NoiseCost' }
                        >;
                        emissionCosts: Array<
                          { name: string; tankToWheel: number; wellToTank: number; total: number } & {
                            __typename: 'EmissionCost';
                          }
                        >;
                      } & { __typename: 'ExternalCosts' })
                    | null;
                  carriageParameters: {
                    bioFuelShare: number;
                    carriageModeParameters: Array<
                      {
                        mode: string;
                        emissionClass: string | null;
                        emptyRunFactor: number | null;
                        ferryRouting: string | null;
                        fuelType: string | null;
                        loadFactor: number | null;
                        lorryClass: string | null;
                        driveClass: string | null;
                        aircraftType: string | null;
                        seaShipType: string | null;
                      } & { __typename: 'CarriageModeParameter' }
                    >;
                  } & { __typename: 'CarriageParameters' };
                } & { __typename: 'Leg' }
              >;
            } & { __typename: 'TourCalculatedEmissions' })
          | null;
        emissionsCalculationErrors: Array<
          { type: EmissionsCalculationErrorType; code: EmissionsCalculationErrorCode } & {
            __typename: 'CalculationError';
          }
        >;
        drivenDistance:
          | ({ source: string | null; distanceInKilometers: number | null } & { __typename: 'TourDrivenDistance' })
          | null;
        fuelBasedEmissions:
          | ({
              accumulatedFuelConsumptionLiters: number | null;
              fuelType: string | null;
              co2TankToWheelTonnes: number | null;
              co2WellToTankTonnes: number | null;
              co2WellToWheelTonnes: number | null;
            } & { __typename: 'FuelBasedEmissions' })
          | null;
        telemetryEmissions:
          | ({
              co2TankToWheelTonnes: number;
              co2WellToTankTonnes: number;
              co2WellToWheelTonnes: number;
              routePolyline: string | null;
              cargo: {
                containerEmptyWeight: number;
                tonsPerTeu: number;
                tonsPerFeu: number;
                weight: number;
                volumeWeight: string;
                unit: string;
              } & { __typename: 'Cargo' };
              legs: Array<
                {
                  transportMode: string;
                  distanceKM: number | null;
                  routePolyline: string | null;
                  type: string;
                  startLocation: { lat: number | null; lng: number | null } & { __typename: 'GeoPosition' };
                  endLocation: { lat: number | null; lng: number | null } & { __typename: 'GeoPosition' };
                  emissions: Array<
                    {
                      type: string;
                      emissionMode: string;
                      unit: string;
                      tankToWheel: number;
                      wellToTank: number;
                      total: number;
                    } & { __typename: 'LegEmission' }
                  >;
                  externalCosts:
                    | ({
                        currency: string;
                        totalExternalCosts: number;
                        accidentCosts: Array<
                          { transportMode: string; accidentCostValue: number } & { __typename: 'AccidentCost' }
                        >;
                        noiseCosts: Array<
                          { transportMode: string; noiseCostValue: number } & { __typename: 'NoiseCost' }
                        >;
                        emissionCosts: Array<
                          { name: string; tankToWheel: number; wellToTank: number; total: number } & {
                            __typename: 'EmissionCost';
                          }
                        >;
                      } & { __typename: 'ExternalCosts' })
                    | null;
                  carriageParameters: {
                    bioFuelShare: number;
                    carriageModeParameters: Array<
                      {
                        mode: string;
                        emissionClass: string | null;
                        emptyRunFactor: number | null;
                        ferryRouting: string | null;
                        fuelType: string | null;
                        loadFactor: number | null;
                        lorryClass: string | null;
                        driveClass: string | null;
                        aircraftType: string | null;
                        seaShipType: string | null;
                      } & { __typename: 'CarriageModeParameter' }
                    >;
                  } & { __typename: 'CarriageParameters' };
                } & { __typename: 'Leg' }
              >;
            } & { __typename: 'TourCalculatedEmissions' })
          | null;
        rawExternalEvents:
          | ({
              tourExternalTracking: Array<
                {
                  tourId: string;
                  provider: string;
                  createdAt: string;
                  updatedAt: string;
                  externalId: string | null;
                  status: string | null;
                  statusReason: string | null;
                } & { __typename: 'TourExternalTracking' }
              >;
              tourExternalTrackingLogs: Array<
                { content: string; tourId: string; contentType: string; provider: string; createdAt: string } & {
                  __typename: 'TourExternalTrackingLog';
                }
              >;
              tourExternalTrackingRequests: Array<
                {
                  trackingRequestId: string;
                  tourId: string;
                  url: string;
                  requestPayload: string;
                  createdAt: string;
                  responsePayload: string | null;
                  responseStatus: string | null;
                } & { __typename: 'TourExternalTrackingRequest' }
              >;
              tourDemurrageDetentionLogs: Array<
                { content: string; tourId: string; createdAt: string; demurrageDetentionLogId: string } & {
                  __typename: 'TourDemurrageDetentionLog';
                }
              >;
            } & { __typename: 'RawExternalEvents' })
          | null;
        volume: ({ unit: string; value: number } & { __typename: 'TourVolume' }) | null;
        externalCarrier:
          | ({ companyID: string | null; companyName: string | null } & { __typename: 'ExternalCarrier' })
          | null;
        incoterm: ({ term: string | null; city: string | null } & { __typename: 'TourIncoterms' }) | null;
        tripSegments: Array<
          {
            id: string;
            previousTripIds: Array<string> | null;
            transportMethod:
              | ({
                  name: string | null;
                  mode: TripTransportMode;
                  identifiers: Array<
                    { type: TransportIdentifierType; value: string } & { __typename: 'TripTransportIdentifier' }
                  > | null;
                } & { __typename: 'TransportMethod' })
              | null;
            locations: Array<
              {
                location:
                  | ({ id: string; name: string; type: TripLocationType | null; country: string | null } & {
                      __typename: 'TripLocation';
                    })
                  | null;
                milestones: Array<
                  {
                    name: string;
                    qualifier: string;
                    deliveryItemIds: Array<string> | null;
                    actualTime:
                      | ({ dateTime: string; timezone: string } & { __typename: 'DateTimeWithTimezone' })
                      | null;
                    estimatedTime:
                      | ({ dateTime: string; timezone: string } & { __typename: 'DateTimeWithTimezone' })
                      | null;
                    scheduledTime:
                      | ({ dateTime: string; timezone: string } & { __typename: 'DateTimeWithTimezone' })
                      | null;
                  } & { __typename: 'TripMilestone' }
                >;
              } & { __typename: 'TripLocationWithMilestones' }
            > | null;
          } & { __typename: 'TripSegment' }
        > | null;
        tripLocations: Array<
          { name: string; id: string; type: TripLocationType | null; country: string | null } & {
            __typename: 'TripLocation';
          }
        > | null;
        tripDeliveryItems: Array<
          { name: string; id: string; type: TransportIdentifierType } & { __typename: 'TripDeliveryItem' }
        > | null;
      } & { __typename: 'Tour' })
    | null;
} & { __typename: 'Query' };

export type TourVehicleHistoryQueryVariables = Exact<{
  tour_id: Scalars['Int']['input'];
  start_time: Scalars['String']['input'];
  end_time: Scalars['String']['input'];
  simplification_tolerance?: InputMaybe<Scalars['Float']['input']>;
}>;

export type TourVehicleHistoryQuery = {
  tour:
    | ({
        tour_id: number;
        vehicleStatuses: Array<
          {
            vehicle_id: string;
            latitude: number | null;
            longitude: number | null;
            heading: number | null;
            timestamp: string | null;
          } & { __typename: 'VehicleStatus' }
        >;
        vehicleHistoryList: Array<
          {
            vehicle_id: string;
            history: Array<
              {
                lat: number;
                lng: number;
                hdg: number | null;
                timestamp: string;
                modeChangesLeft: number | null;
                vehicleType: string | null;
                source: HistorySource | null;
                type: HistoryType | null;
                received_at: string | null;
                created_at: string | null;
                company:
                  | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                  | null;
                vehicleTracker:
                  | ({
                      vehicleTrackerId: string;
                      trackerType: VehicleTrackerType;
                      fmsRemoteId: string;
                      telematicsIntegration:
                        | ({
                            telematicsIntegrationId: string;
                            telematicsProvider: { telematicsProviderName: string; telematicsProviderId: string } & {
                              __typename: 'TelematicsProvider';
                            };
                          } & { __typename: 'CompanyTelematicsIntegration' })
                        | null;
                    } & { __typename: 'FMSVehicleTracker' })
                  | ({
                      vehicleTrackerId: string;
                      trackerType: VehicleTrackerType;
                      phoneNumber: string | null;
                      mobileRemoteId: string | null;
                    } & { __typename: 'MobileVehicleTracker' })
                  | null;
              } & { __typename: 'VehicleHistory' }
            >;
          } & { __typename: 'VehicleHistoryEntry' }
        > | null;
        vehicleBreakHistory: Array<
          {
            vehicle_id: string;
            breaks: Array<
              {
                breakId: string;
                from: string;
                to: string | null;
                center: { lat: number; lng: number } & { __typename: 'BreakCenterPosition' };
              } & { __typename: 'VehicleBreakHistory' }
            >;
          } & { __typename: 'VehicleBreakHistoryEntry' }
        > | null;
      } & { __typename: 'Tour' })
    | null;
} & { __typename: 'Query' };

export type AbortTourMutationVariables = Exact<{
  tour_id: Scalars['Int']['input'];
}>;

export type AbortTourMutation = {
  abortTour: ({ tour_id: number; abort_time: string | null } & { __typename: 'Tour' }) | null;
} & { __typename: 'Mutation' };

export type RecalculateTourMutationVariables = Exact<{
  tour_id: Scalars['Int']['input'];
}>;

export type RecalculateTourMutation = {
  recalculateTour: ({ tour_id: number; recalculate_time: string | null } & { __typename: 'Tour' }) | null;
} & { __typename: 'Mutation' };

export type TourListFilterValuesQueryVariables = Exact<{
  vehicle_id?: InputMaybe<Scalars['String']['input']>;
  hasVehicleFilter: Scalars['Boolean']['input'];
  shipperId?: InputMaybe<Scalars['String']['input']>;
  hasShipperFilter: Scalars['Boolean']['input'];
}>;

export type TourListFilterValuesQuery = {
  platforms:
    | ({
        edges: Array<
          | ({
              node:
                | ({ platform_id: string; platform_name: string; platform_branches: Array<string> } & {
                    __typename: 'Platform';
                  })
                | null;
            } & { __typename: 'PlatformEdge' })
          | null
        > | null;
      } & { __typename: 'PlatformConnection' })
    | null;
  company?:
    | ({
        company_id: string;
        placesConnection: {
          places: {
            edges: Array<
              | ({ node: ({ place_id: string; name: string } & { __typename: 'CompanyPlace' }) | null } & {
                  __typename: 'CompanyPlaceEdge';
                })
              | null
            > | null;
          } & { __typename: 'CompanyPlaceConnection' };
        } & { __typename: 'CountedCompanyPlaceConnection' };
      } & { __typename: 'Company' })
    | null;
  vehicle?: ({ vehicle_id: string; license_plate_number: string | null } & { __typename: 'Vehicle' }) | null;
} & { __typename: 'Query' };

export type TourListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TourStatusFilter>;
  shipper_ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  carrier_ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_branch_id?: InputMaybe<Scalars['String']['input']>;
  place_ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  vehicle_id?: InputMaybe<Scalars['String']['input']>;
  start_time_start?: InputMaybe<Scalars['DateTime']['input']>;
  start_time_end?: InputMaybe<Scalars['DateTime']['input']>;
  sortOrder?: InputMaybe<TourSortOrder>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  textSearch?: InputMaybe<TourTextSearch>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode> | TourFilteringTransportMode>;
}>;

export type TourListQuery = {
  tours: {
    tours: {
      edges: Array<
        | ({
            node:
              | ({
                  tour_id: number;
                  shipper_transport_number: string | null;
                  transport_id: string | null;
                  start_time: string | null;
                  status: string | null;
                  visibilityServices: Array<VisibilityService>;
                  failureReason: ({ type: TourFailureReasonType } & { __typename: 'TourFailureReason' }) | null;
                  warnings: Array<{ type: TourWarningType } & { __typename: 'TourWarning' }>;
                  mainVisibilityIssue:
                    | ({ type: VisibilityIssueType; index: number } & { __typename: 'VisibilityIssue' })
                    | null;
                  carrier:
                    | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                    | null;
                  shipper:
                    | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                    | null;
                  platform: ({ platform_id: string; platform_name: string } & { __typename: 'Platform' }) | null;
                  vehicles: Array<
                    { vehicle_id: string; license_plate_number: string | null } & { __typename: 'VehicleProfile' }
                  >;
                  stops: Array<
                    {
                      sequence_number: number | null;
                      actual_sequence_number: number | null;
                      display_sequence_number: number;
                      location:
                        | ({
                            name: string | null;
                            address: ({ country: string | null } & { __typename: 'Address' }) | null;
                          } & { __typename: 'TourLocation' })
                        | null;
                    } & { __typename: 'TourStop' }
                  >;
                  tags: Array<{ name: string } & { __typename: 'TourTag' }>;
                } & { __typename: 'Tour' })
              | null;
          } & { __typename: 'TourEdge' })
        | null
      > | null;
      pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
    } & { __typename: 'TourConnection' };
  } & { __typename: 'CountedTourConnection' };
} & { __typename: 'Query' };

export type TripsDetailsQueryVariables = Exact<{
  tour_id: Scalars['Int']['input'];
}>;

export type TripsDetailsQuery = {
  tour:
    | ({
        tour_id: number;
        relatedTransports: Array<
          { tour_id: number; status: string | null; shipper_transport_number: string | null } & { __typename: 'Tour' }
        >;
      } & { __typename: 'Tour' })
    | null;
} & { __typename: 'Query' };

export type CreateUserMutationVariables = Exact<{
  auth_service: Scalars['String']['input'];
  input?: InputMaybe<UserAttributesInput>;
}>;

export type CreateUserMutation = {
  createUser:
    | ({
        user_id: string;
        email: string | null;
        locale: string | null;
        backoffice_role: BackofficeRole | null;
        backoffice_scopes: Array<string> | null;
        first_name: string | null;
        last_name: string | null;
        avatar_url: string | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Mutation' };

export type UpdateUserMutationVariables = Exact<{
  user_id: Scalars['String']['input'];
  input?: InputMaybe<UserAttributesInput>;
}>;

export type UpdateUserMutation = {
  updateUser:
    | ({
        user_id: string;
        email: string | null;
        locale: string | null;
        backoffice_role: BackofficeRole | null;
        backoffice_scopes: Array<string> | null;
        first_name: string | null;
        last_name: string | null;
        avatar_url: string | null;
        updated_at: string | null;
        deleted_at: string | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Mutation' };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type DeleteUserMutation = { deleteUser: boolean | null } & { __typename: 'Mutation' };

export type UserListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserListQuery = {
  users:
    | ({
        edges: Array<
          | ({
              node:
                | ({
                    user_id: string;
                    first_name: string | null;
                    last_name: string | null;
                    email: string | null;
                    locale: string | null;
                    avatar_url: string | null;
                    backoffice_role: BackofficeRole | null;
                    backoffice_scopes: Array<string> | null;
                    created_at: string | null;
                    updated_at: string | null;
                    deleted_at: string | null;
                    credentials: Array<{ type: CredentialType } & { __typename: 'Credentials' }>;
                  } & { __typename: 'User' })
                | null;
            } & { __typename: 'UserEdge' })
          | null
        > | null;
        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
      } & { __typename: 'UserConnection' })
    | null;
} & { __typename: 'Query' };

export type UserQueryVariables = Exact<{
  user_id: Scalars['String']['input'];
}>;

export type UserQuery = {
  user:
    | ({
        user_id: string;
        first_name: string | null;
        last_name: string | null;
        email: string | null;
        locale: string | null;
        backoffice_role: BackofficeRole | null;
        backoffice_scopes: Array<string> | null;
        avatar_url: string | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        credentials: Array<
          { type: CredentialType; value: string; addedByUserId: string; addedAt: string } & {
            __typename: 'Credentials';
          }
        >;
        companies: Array<
          {
            company_id: string;
            company_name: string | null;
            user:
              | ({
                  userOnPlatforms: Array<
                    {
                      userIdOnPlatform: string;
                      platform: { platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' };
                    } & { __typename: 'UserOnPlatformProfile' }
                  >;
                } & { __typename: 'UserInCompanyProfile' })
              | null;
          } & { __typename: 'Company' }
        >;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type AddCredentialsToUserMutationVariables = Exact<{
  input: AddCredentialsToUserInput;
}>;

export type AddCredentialsToUserMutation = {
  addCredentialsToUser:
    | ({ type: CredentialType; value: string; addedAt: string; addedByUserId: string; userId: string } & {
        __typename: 'Credentials';
      })
    | null;
} & { __typename: 'Mutation' };

export type RemoveCredentialsFromUserMutationVariables = Exact<{
  input: RemoveCredentialsFromUserInput;
}>;

export type RemoveCredentialsFromUserMutation = { removeCredentialsFromUser: boolean | null } & {
  __typename: 'Mutation';
};

export type BackofficeScopesQueryVariables = Exact<{ [key: string]: never }>;

export type BackofficeScopesQuery = { backofficeScopes: Array<string> } & { __typename: 'Query' };

export type CompanyUserGroupsOnPlatformQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CompanyUserGroupsOnPlatformQuery = {
  company:
    | ({
        company_id: string;
        company_name: string | null;
        userGroupsOnPlatform: {
          edges: Array<
            | ({
                node:
                  | ({
                      groupId: string;
                      name: string;
                      platform: { platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' };
                      company:
                        | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                        | null;
                    } & { __typename: 'UserGroupOnPlatform' })
                  | null;
              } & { __typename: 'UserGroupOnPlatformEdge' })
            | null
          > | null;
          pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
        } & { __typename: 'UserGroupOnPlatformConnection' };
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyUserGroupOnPlatformQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  group_id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CompanyUserGroupOnPlatformQuery = {
  company:
    | ({
        company_id: string;
        company_name: string | null;
        userGroupOnPlatform:
          | ({
              groupId: string;
              name: string;
              platform: { platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' };
              company: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
              users: {
                edges: Array<
                  | ({
                      node:
                        | ({
                            user_id: string;
                            first_name: string | null;
                            last_name: string | null;
                            email: string | null;
                          } & { __typename: 'UserProfile' })
                        | null;
                    } & { __typename: 'UserProfileEdge' })
                  | null
                > | null;
                pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
              } & { __typename: 'UserProfileConnection' };
            } & { __typename: 'UserGroupOnPlatform' })
          | null;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type AddUserToCompanyMutationVariables = Exact<{
  input: AddUserToCompanyInput;
}>;

export type AddUserToCompanyMutation = {
  addUserToCompany: (UserInCompanyDetailsFragment & { __typename: 'UserInCompanyProfile' }) | null;
} & { __typename: 'Mutation' };

export type RemoveUserFromCompanyMutationVariables = Exact<{
  input: RemoveUserFromCompanyInput;
}>;

export type RemoveUserFromCompanyMutation = { removeUserFromCompany: boolean | null } & { __typename: 'Mutation' };

export type ChangeUserRoleInCompanyMutationVariables = Exact<{
  input: ChangeUserRoleInCompanyInput;
}>;

export type ChangeUserRoleInCompanyMutation = {
  changeUserRoleInCompany: (UserInCompanyDetailsFragment & { __typename: 'UserInCompanyProfile' }) | null;
} & { __typename: 'Mutation' };

export type ChangeUserMetadataInCompanyMutationVariables = Exact<{
  input: ChangeUserMetadataInCompanyInput;
}>;

export type ChangeUserMetadataInCompanyMutation = {
  changeUserMetadataInCompany: (UserInCompanyDetailsFragment & { __typename: 'UserInCompanyProfile' }) | null;
} & { __typename: 'Mutation' };

export type UserInCompanyDetailsFragment = {
  role: UserRoleInCompany;
  user: {
    user_id: string;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    avatar_url: string | null;
    deleted_at: string | null;
    credentials: Array<{ type: CredentialType } & { __typename: 'Credentials' }>;
  } & { __typename: 'User' };
  metadata: ({ persona: UserPersonaInCompany | null } & { __typename: 'UserMetadataInCompany' }) | null;
  invitedByUser:
    | ({ user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
        __typename: 'UserProfile';
      })
    | null;
} & { __typename: 'UserInCompanyProfile' };

export type CompanyUsersListQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CompanyUsersListQuery = {
  company:
    | ({
        company_id: string;
        users: {
          users: {
            edges: Array<
              | ({ node: (UserInCompanyDetailsFragment & { __typename: 'UserInCompanyProfile' }) | null } & {
                  __typename: 'UserInCompanyProfileEdge';
                })
              | null
            > | null;
            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
          } & { __typename: 'UserInCompanyProfileConnection' };
        } & { __typename: 'CountedUserInCompanyProfileConnection' };
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type CompanyUserQueryVariables = Exact<{
  company_id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type CompanyUserQuery = {
  company:
    | ({ company_id: string; user: (UserInCompanyDetailsFragment & { __typename: 'UserInCompanyProfile' }) | null } & {
        __typename: 'Company';
      })
    | null;
} & { __typename: 'Query' };

export type VehicleListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  integrationIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type VehicleListQuery = {
  vehicles:
    | ({
        edges: Array<
          | ({
              node:
                | ({
                    vehicle_id: string;
                    license_plate_number: string | null;
                    created_at: string | null;
                    status:
                      | ({
                          latitude: number | null;
                          longitude: number | null;
                          heading: number | null;
                          timestamp: string | null;
                        } & { __typename: 'RestrictedVehicleStatus' })
                      | null;
                    company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
                    telematicsIntegrations: Array<
                      { telematics_integration_id: string } & { __typename: 'TelematicsIntegration' }
                    >;
                    vehicleTrackers: Array<
                      { vehicleTrackerId: string; trackerType: VehicleTrackerType } & {
                        __typename: 'FMSVehicleTracker' | 'MobileVehicleTracker';
                      }
                    >;
                  } & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'VehicleEdge' })
          | null
        > | null;
        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
      } & { __typename: 'VehicleConnection' })
    | null;
} & { __typename: 'Query' };

export type ManagedVehicleListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  licensePlateSearch?: InputMaybe<Scalars['String']['input']>;
  integrationIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type ManagedVehicleListQuery = {
  vehicles:
    | ({
        edges: Array<
          | ({
              node:
                | ({
                    vehicle_id: string;
                    license_plate_number: string | null;
                    created_at: string | null;
                    telematicsIntegrations: Array<
                      { telematics_integration_id: string } & { __typename: 'TelematicsIntegration' }
                    >;
                    vehicleTrackers: Array<
                      | ({
                          vehicleTrackerId: string;
                          trackerType: VehicleTrackerType;
                          fmsRemoteId: string;
                          telematicsIntegration:
                            | ({ telematicsIntegrationId: string } & { __typename: 'CompanyTelematicsIntegration' })
                            | null;
                        } & { __typename: 'FMSVehicleTracker' })
                      | ({ vehicleTrackerId: string; trackerType: VehicleTrackerType } & {
                          __typename: 'MobileVehicleTracker';
                        })
                    >;
                  } & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'VehicleEdge' })
          | null
        > | null;
        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
      } & { __typename: 'VehicleConnection' })
    | null;
} & { __typename: 'Query' };

export type VehicleQueryVariables = Exact<{
  vehicle_id: Scalars['String']['input'];
}>;

export type VehicleQuery = {
  vehicle:
    | ({
        vehicle_id: string;
        license_plate_number: string | null;
        inGlobalPool: boolean;
        created_at: string | null;
        updated_at: string | null;
        company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
        status:
          | ({ latitude: number | null; longitude: number | null; heading: number | null; timestamp: string | null } & {
              __typename: 'RestrictedVehicleStatus';
            })
          | null;
        vehicleTrackers: Array<
          | ({
              vehicleTrackerId: string;
              trackerType: VehicleTrackerType;
              createdAt: string | null;
              updatedAt: string | null;
              trackerData: { [key: string]: string | number | boolean | object } | null;
              fmsRemoteId: string;
              telematicsIntegration:
                | ({
                    telematicsIntegrationId: string;
                    telematicsProvider: { telematicsProviderName: string } & { __typename: 'TelematicsProvider' };
                  } & { __typename: 'CompanyTelematicsIntegration' })
                | null;
            } & { __typename: 'FMSVehicleTracker' })
          | ({
              vehicleTrackerId: string;
              trackerType: VehicleTrackerType;
              phoneNumber: string | null;
              createdAt: string | null;
              updatedAt: string | null;
              trackerData: { [key: string]: string | number | boolean | object } | null;
              mobileRemoteId: string | null;
            } & { __typename: 'MobileVehicleTracker' })
        >;
        latestVehicleTemperatures: Array<
          {
            reeferId: string;
            timestamp: string;
            visibilitySource: {
              eventLevel: VehicleTemperatureEventLevel;
              primaryEventSource: VehicleTemperatureSource;
              eventOrigin: string | null;
            } & { __typename: 'VehicleTemperatureVisibilitySource' };
            sensorReadings: Array<
              { sensorId: string; compartmentId: string; temperatureCelsius: number } & {
                __typename: 'VehicleTemperatureSensorReading';
              }
            >;
          } & { __typename: 'LatestVehicleTemperature' }
        >;
      } & { __typename: 'Vehicle' })
    | null;
} & { __typename: 'Query' };

export type VehicleHistoryQueryVariables = Exact<{
  vehicle_id: Scalars['String']['input'];
  history_start_time: Scalars['String']['input'];
  history_end_time: Scalars['String']['input'];
  simplification_tolerance?: InputMaybe<Scalars['Float']['input']>;
}>;

export type VehicleHistoryQuery = {
  vehicle:
    | ({
        vehicle_id: string;
        history: Array<
          {
            lat: number;
            lng: number;
            hdg: number | null;
            timestamp: string;
            received_at: string | null;
            source: HistorySource | null;
          } & { __typename: 'VehicleHistory' }
        > | null;
        breakHistory: Array<
          {
            breakId: string;
            from: string;
            to: string | null;
            center: { lat: number; lng: number } & { __typename: 'BreakCenterPosition' };
          } & { __typename: 'VehicleBreakHistory' }
        > | null;
      } & { __typename: 'Vehicle' })
    | null;
} & { __typename: 'Query' };

export type DeleteVehicleMutationVariables = Exact<{
  input: DeleteVehicleInput;
}>;

export type DeleteVehicleMutation = { deleteVehicle: boolean } & { __typename: 'Mutation' };

export type DeleteVehiclesMutationVariables = Exact<{
  input: DeleteVehiclesInput;
}>;

export type DeleteVehiclesMutation = { deleteVehicles: boolean } & { __typename: 'Mutation' };

export type GrantRelationshipsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  sourceCompanyId?: InputMaybe<Scalars['String']['input']>;
  targetCompanyId?: InputMaybe<Scalars['String']['input']>;
  relationshipType?: InputMaybe<CompanyRelationshipType>;
}>;

export type GrantRelationshipsListQuery = {
  companyRelationships:
    | ({
        edges: Array<
          | ({
              node:
                | ({
                    companyRelationshipId: string;
                    sourceCompany: {
                      company_id: string;
                      vehicles:
                        | ({ all: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null } & {
                            __typename: 'CompanyVehicles';
                          })
                        | null;
                    } & { __typename: 'Company' };
                    targetCompany: {
                      company_name: string | null;
                      company_id: string;
                      vehicleGrantPartner: ({ grantCount: number } & { __typename: 'VehicleGrantPartner' }) | null;
                    } & { __typename: 'Company' };
                  } & { __typename: 'CompanyRelationship' })
                | null;
            } & { __typename: 'CompanyRelationshipEdge' })
          | null
        > | null;
        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
      } & { __typename: 'CompanyRelationshipConnection' })
    | null;
} & { __typename: 'Query' };

export type CreateAllVehicleGrantsMutationVariables = Exact<{
  input: CreateVehicleGrantsInput;
}>;

export type CreateAllVehicleGrantsMutation = { createAllVehicleGrants: boolean | null } & { __typename: 'Mutation' };

export type RevokeAllVehicleGrantsMutationVariables = Exact<{
  input: RevokeVehicleGrantsInput;
}>;

export type RevokeAllVehicleGrantsMutation = { revokeAllVehicleGrants: boolean | null } & { __typename: 'Mutation' };
